import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function FilterTypeAutoComplete({ options, selectedOption, setSelectedOption, placeholder }) {    

    const handleSelectionChange = (event, newValue) => {
        setSelectedOption(newValue);        
    };

    return (
        <Autocomplete
            id="options-filter"
            options={options}
            getOptionLabel={(option) => option.label}
            value={selectedOption}
            onChange={handleSelectionChange}
            renderInput={(params) => <TextField variant="standard" {...params} placeholder={placeholder} />}
        />
    );
}
