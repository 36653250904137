import React from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';

const CustomSnackBar = ({
    open,
    onClose,
    message,
    title,
    severity,
    variant,
    autoHideDuration,
    transition: Transition,
    vertical,
    horizontal
}) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            TransitionComponent={Transition}
            anchorOrigin={{ vertical, horizontal }}
        >
            <Alert
                onClose={onClose}
                severity={severity}
                variant={variant}
                sx={{ width: '100%' }}
            >
                {title && <AlertTitle>{title}</AlertTitle>}
                {message}
            </Alert>
        </Snackbar>
    );
};

CustomSnackBar.defaultProps = {
    title: '',
    variant: 'filled',
    autoHideDuration: 3000,
    transition: undefined, // Can be customized outside
    vertical: 'top',
    horizontal: 'right',
};

export default CustomSnackBar;
