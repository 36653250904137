import React, { useState } from 'react';
import { Avatar, Grid, Paper, Typography, Tooltip, IconButton, Box, Button, CircularProgress, Alert } from "@mui/material"
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HistoryIcon from '@mui/icons-material/History';
import { IMAGE } from '../../config/constant';
import { UpdateServicePlan } from '../../services/service_request';

export const ParticipantCard = ({ v, handleDetails }) => {
    return (
        <Paper sx={{ width: "95%", m: 0.5 }} elevation={1}>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
                <Avatar sx={{ width: 50, height: 50, boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.14)", }} alt={v?.client_detail?.first_name} src={`${IMAGE}${v?.client_detail?.first_name}`} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Grid item={true} xs={12} sm={12} md={12} lg={10}  >
                    <Typography style={{ fontSize: 12 }}>
                        {v?.client_detail?.first_name + " " + v?.client_detail?.last_name}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                        Start: {v?.visit_data && v?.visit_data[0]?.attributes?.start_date}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                        End:  {v?.visit_data && v?.visit_data[0]?.attributes?.end_date}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                        Billable:  {v?.shifts_data && v?.shifts_data[0]?.attributes?.total_shift_hours}
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip title="Services">
                        <IconButton aria-label="service" size="large" onClick={() => handleDetails(v)}>
                            <DescriptionIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Fund">
                        <IconButton aria-label="Fund" size="large">
                            <AttachMoneyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="History">
                        <IconButton aria-label="History" size="large">
                            <HistoryIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Paper>
    )
}

export const EmployeeCard = ({ v, key }) => {
    return (
        <Paper sx={{ m: 1, p: 1, width: "90%", margin: "5px auto" }} elevation={1} >
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
                <Avatar sx={{ width: 50, height: 50, boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.14)", }} alt={v?.staff_detail?.first_name} src={`${IMAGE}${v?.staff_detail?.first_name}`} />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Grid item={true} xs={12} sm={12} md={12} lg={10}  >
                    <Typography style={{ fontSize: 12 }}>
                        {v.staff_detail.first_name}
                    </Typography>
                    {/* {v?.serviceTime.map((v, i) => {
                        return (
                            <div key={i}>
                                <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                                    {v.dateFrom} / {v.dateTo}
                                </Typography>
                                <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                                    {v.startTime} To {v.endTime}
                                </Typography>
                                <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                                    Status: {v.jobStatus}
                                </Typography>
                            </div>
                        )
                    })} */}
                </Grid>
            </Grid>
        </Paper>
    )
}

export const MiniParticipantCard = ({ v, key }) => {
    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={1} >
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
                <Avatar sx={{ width: 40, height: 40 }}>
                    <PersonIcon />
                </Avatar>
            </Grid>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                <Grid item={true} xs={12} sm={12} md={12} lg={10}  >
                    <Typography style={{ fontSize: 12 }}>
                        {v.name}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                        {v.spendTime + " hrs"}
                    </Typography>
                    <Typography style={{ fontSize: 12 }}>
                        Billable  {v.Billable + " hrs"}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export const ClientInfoCard = ({ ClientInfo }) => {
    const colorCode = { //  // // color code for auto scheduling
        "pending": {
            "main": '#fff176',
        },
        "plan": {
            "main": '#4fc3f7',
        },
        "approved": {
            "main": '#e1bee7',
        },
        "acknowledged": {
            "main": '#ffb74d',
        },
        "done": {
            "main": '#a9d279',
        },
        "rejected": {
            "main": '#f8bbd0',
        },
        "waiting": {
            "main": "#F79840"
        }
    };
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                background: "#ffffff",
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                justifyContent: 'center',
                p: 1,
                borderRadius: 1
            }}
        >
            <Avatar sx={{ width: 50, height: 50 }}>
                <PersonIcon />
            </Avatar>
            <Typography color={"secondary"} style={{ fontSize: 12, fontFamily: "Roboto-Medium" }}>
                {ClientInfo.client_detail.first_name + " " + ClientInfo.client_detail.last_name}
            </Typography>
            <Typography color={"secondary"} style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                {ClientInfo?.visit_data[0]?.attributes?.start_date} {" to "}
            </Typography>
            <Typography color={"secondary"} style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                {ClientInfo?.visit_data[0]?.attributes?.end_date}
            </Typography>
        </Box>
    );
}

export const EmployeeInfoCard = ({ StaffInfo }) => {    
    // const serv = service?.find((e) => e?.service?.id === v?.attributes?.service_request_id?.data?.id)
    // const name = v?.attributes?.staff_id?.data?.attributes?.first_name + " " + v?.attributes?.staff_id?.data?.attributes?.last_name
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                background: "#ffffff",
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                justifyContent: 'center',
                p: 1,
                borderRadius: 1
            }}
        >
            <Avatar sx={{ width: 50, height: 50 }}>
                <PersonIcon />
            </Avatar>
            <Typography style={{ fontSize: 12 }}>
                {StaffInfo?.attributes?.first_name + " " + StaffInfo?.attributes?.last_name}
            </Typography>
            <Typography style={{ fontSize: 11, fontFamily: "Roboto-Regular" }}>
                Status: {StaffInfo?.attributes?.approved_status ? "Verified" : "Non Verified"}
            </Typography>
        </Box>
    )
}

export const ShiftCards = ({ matchedClient, handleDetails, staff, handleStaffsData, getPlansForView }) => {
    const [doUpdateService, loading, errors] = UpdateServicePlan();
    const [message, setMessage] = useState(null)

    const handleAdvertisedServicePlan = async (serv_id, staff_id, status) => {
        try {
            const respons = await doUpdateService({
                variables: {
                    "id": serv_id,
                    "data": {
                        "staff_id": staff_id,
                        "service_request_status": status
                    }
                }
            })
            if (respons?.data) {
                handleStaffsData()
                getPlansForView()
            }
        } catch (error) {
            let message;
            if (errors?.networkError) {
                message = "Network error occurred. Please try again later"
            }
            if (errors?.graphQLErrors) {
                message = errors.graphQLErrors[0]?.message
            }
            if (error?.message) {
                message = error?.message;
            }
            setMessage(message)
        }
    }

    return (
        <Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }} >
            <Box onClick={() => handleDetails(matchedClient)} sx={{ width: "100%", cursor: 'pointer', marginBottom: "10px" }}>
                <Typography color="primary" style={{ fontSize: 14, fontFamily: "Roboto-Bold" }}>
                    Service Type
                </Typography>
                <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular" }}>
                    {matchedClient?.service_request?.charge_band_rate_id?.service_title}
                </Typography>
                <Typography color="primary" style={{ fontSize: 14, fontFamily: "Roboto-Bold" }}>
                    Visit Day
                </Typography>
                <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular" }}>
                    {matchedClient?.visit_data[0]?.attributes?.start_date}
                </Typography>
            </Box>
            <Button
                onClick={() => handleAdvertisedServicePlan(matchedClient.plan_id, staff, "Pending")}
                variant="outlined"
                sx={{
                    fontSize: "0.8em",
                    bgcolor: "#ffffff",
                    fontFamily: "Roboto-Medium",
                    textTransform: "capitalize",
                    color: "#000000",
                    border: "2px solid #6ECA35",
                    ":hover": {
                        border: "2px solid #6ECA35",
                    }
                }}>
                Assign {loading ? <CircularProgress color="secondary" size={26} /> : null}
            </Button>
            {/* <Typography color="primary" style={{ fontSize: 14, fontFamily: "Roboto-Bold" }}>
                Recurring
            </Typography>
            <Typography style={{ fontSize: 12, fontFamily: "Roboto-Regular" }}>
                {v?.visit_data[0]?.attributes?.recurring_type.slice(7)}
            </Typography> */}
        </Box >
    )
}