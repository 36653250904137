import Grid  from "@mui/material/Grid";
import Typography  from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup"; 
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const WeekdayChecklist = ({ checkedDays, handleChange, weekdays }) => {
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item={true} xs={12} sx={{ mx: 1, }}>
                <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>Select Weekdays:</Typography>
            </Grid>
            <Grid item={true} xs={12} sx={{ mx: 1, }}>
                <FormGroup row>
                    {weekdays.map((day) => (
                        <FormControlLabel
                            key={day}
                            control={
                                <Checkbox
                                    checked={checkedDays[day]}
                                    onChange={handleChange}
                                    name={day}
                                    color="primary"
                                />
                            }
                            label={day}
                        />
                    ))}
                </FormGroup>
            </Grid>
        </Grid>
    );
};
