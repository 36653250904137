import { Avatar, Backdrop, Box, Button, Fade, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Modal, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TourIcon from '@mui/icons-material/Tour';
import { useDispatch, useSelector } from "react-redux";
import { visit_days } from "../../../middleware/reducers/service_request";
import DeleteIcon from '@mui/icons-material/Delete';
import { DaysInfo } from "./days_info";
import { ShiftsAndComments } from "./shifts_and_comments";

const randomIds = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

export const VisitDaysModal = () => {

    const [open, setOpen] = useState(false);
    const [visitDaysData, setVisitDaysData] = useState([]);
    const dispatch = useDispatch();

    const _serviceRequest = useSelector((state) => state._service_request.visitDaysList);

    const width = () => {
        if (window.screen.width > 1600) {
            return window.screen.width / 5.2
        }
        if (window.screen.width > 600 && window.screen.width < 1600) {
            return window.screen.width / 4
        } else {
            return window.screen.width / 1.4
        }
    };
    
    const [screenWidth, setScreenWidth] = useState(width);

    useEffect(() => {
        if (window.screen) {
            setScreenWidth(width);
        }
    }, [window.screen]);

    const addNewVisit = () => {
        const copy = [..._serviceRequest];
        copy.push(visitDaysData);
        dispatch(visit_days(copy));
        setOpen(false);
    }
    const deleteVisitFromState = (index) => {
        const copy = [..._serviceRequest];
        copy.splice(index, 1);
        dispatch(visit_days(copy));
    }

    return (
        <Grid container>
            {_serviceRequest?.length > 0 && (
                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <List sx={{ width: '100%', maxWidth: window.screen.width, bgcolor: 'background.paper' }}>
                        {_serviceRequest?.map((v, i) => {
                            return (
                                <ListItem component={Paper} elevation={1} key={i + randomIds()} sx={{ m: 1, }}
                                    secondaryAction={
                                        <Box sx={{ display: "flex", alignItems: "center", }}>
                                            <Box sx={{ display: "flex", mx: 2 }}>
                                                <Typography>Service Type:</Typography>
                                                <Typography sx={{
                                                    fontFamily: "Roboto-Medium"
                                                }}>{v?.recurring_type.includes("weekly") ? "Weekly" : "Selected Days"}</Typography>
                                            </Box>
                                            <Box sx={{ mx: 2 }}>
                                                <Stack direction="row" spacing={2}>
                                                    {/* <Button
                                                        variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                                        Details
                                                    </Button> */}
                                                    <IconButton onClick={() => deleteVisitFromState(i)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Typography sx={{ fontSize: 12 }}>Visit</Typography>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<Typography>Start Date: {v.start_date}</Typography>}
                                        primaryTypographyProps={{
                                            style: {
                                                textTransform: "capitalize",
                                                fontFamily: "Roboto-Medium"
                                            }
                                        }}
                                        secondary={<Typography sx={{ fontSize: 14 }}>End Date: {v.end_date}</Typography>}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
            )}
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ mx: 1 }}>
                <Button onClick={() => { setOpen(true); }} label={"visit tour"} startIcon={<TourIcon />}
                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    Add Visit
                </Button>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        maxWidth: "calc(70%)",
                        maxHeight: "calc(85%)",
                        minWidth: "calc(70%)",
                        boxShadow: 12,
                        borderRadius: 1,
                        p: 4,
                        overflowY: "auto"
                    }}>
                        <Box>
                            <Typography color={"primary"} sx={{ textTransform: "capitalize", fontFamily: "Roboto-Medium", fontSize: "1.5em" }}>
                                Add New Visit
                            </Typography>
                        </Box>
                        <Box sx={{ py: 2 }}>
                            <DaysInfo
                                screenWidth={screenWidth}
                                visitDaysData={visitDaysData}
                                setVisitDaysData={setVisitDaysData}
                            />
                            <Typography color={"secondary"} sx={{ mx: 1, textTransform: "capitalize", fontFamily: 'Roboto-Medium' }}>
                                Shift Check List
                            </Typography>
                            <ShiftsAndComments screenWidth={screenWidth} />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            p: 3
                        }}>
                            <Stack direction="row" spacing={2}>
                                <Button onClick={() => addNewVisit()}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                </Button>
                                <Button onClick={() => { setOpen(false); }}
                                    variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Cancel
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Grid>
    )
}
