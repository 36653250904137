import { Alert, AlertTitle, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, Slide, Snackbar, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePickerUI, TimePickerUI } from "../../../../../components/Inputs";
import { LocalizationProvider, MobileTimePicker, mk } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AddIcon from '@mui/icons-material/Add';
import { Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { createdId, DynamicStaffOnboard } from "../../../../../middleware/reducers/staff_onboarding_reducer";
import { WorkingDaysByFilters } from "../../../../../services/schedule_service";
import {
  CreateWorkingDay,
  UpdateWorkingDay,
  DeleteWorkingDay,
} from "../../../../../services/staff_service";
import { useNavigate } from "react-router-dom";



const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
}
const EditWorkDaysAndTimeSlots = () => {
  const _draft_data = useSelector((state) => state._staff_onboarding_module.selectedDraft);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _database_id = useSelector((state) => state._staff_onboarding_module.created_id);
  const _history = useSelector((state) => state._history.staff_edit_id);
  const [apiCreateStaffDay, loading_day, error_day] = CreateWorkingDay(); // create staff question
  const [apiUpdateWorkingDay, updateWorkingDay_loading, updateWorkingDay_error] = UpdateWorkingDay(); // create staff question
  const [apiDeleteWorkingDay, deleteWorkingDay_loading, deleteWorkingDay_error] = DeleteWorkingDay(); // create staff question
  const [WorkingDay, loading] = WorkingDaysByFilters();

  const [gotDatafromDB, setGotDatafromDB] = useState(false);

  const defaultTimeSlot = {
    date: new Date(),
    startTime: dayjs('2022-04-17T12:00'),
    endTime: dayjs('2022-04-17T12:00'),
    required: true,
    type: "timePicker"
  };

  const defaultAvailableOptions = [
    { title: 'Unavailable', selected: false },
    { title: 'Whole Day', selected: false }
  ];

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const [workDays, setWorkDays] = useState(() =>
    days.map(day => ({
      title: day,
      value: day,
      type: "checkbox",
      required: true,
      timeSlot: [{ ...defaultTimeSlot }], // Clone defaultTimeSlot if needed
      availableOption: defaultAvailableOptions.map(option => ({ ...option })) // Create a new copy of each option
    }))
  );

  const [snacks, setSnack] = useState({
    open: false,
    Transition: SlideTransition,
    vertical: 'top',
    horizontal: 'right',
  });

  const [errorSnackMessage, setErrorSnackMessage] = useState({
    title: "",
    message: "",
    severity: "",
    variant: "filled"
  });

  const changeAvailability = (workDayIndex, availableOptionIndex) => {
    const copy = [...workDays];  
    copy[workDayIndex].availableOption[availableOptionIndex].selected = !copy[workDayIndex].availableOption[availableOptionIndex].selected
    setWorkDays(copy)   
  }

  const addNewTimeSlot = (workDayIndex) => {
    const copy = [...workDays];
    if (copy[workDayIndex].timeSlot.length < 3) {
      copy[workDayIndex].timeSlot.push({
        date: new Date(),
        startTime: dayjs().startOf('day'),
        endTime: dayjs().startOf('day'),
        required: true,
        type: "timePicker",
        isNewTimeSlot: true
      })
    }
    setWorkDays(copy)
  }

  // const changeStartTimeSlot = (mKey, nKey, value) => {
  //   const copy = [...workDays];
  //   const currentDaySlots = copy[mKey].timeSlot;

  //   // Convert the new value to a dayjs object
  //   const newStartTime = dayjs(value, "HH:mm");

  //   // Check for overlap with other time slots
  //   const hasOverlap = currentDaySlots.some((slot, workDayIndex) => {      
  //     if (workDayIndex !== nKey) { // Skip the current slot being edited
  //       const slotStart = dayjs(slot.startTime, "HH:mm");
  //       const slotEnd = dayjs(slot.endTime, "HH:mm");
  //       return newStartTime.isAfter(slotStart) && newStartTime.isBefore(slotEnd);
  //     }
  //     return false;
  //   });
  //   if (hasOverlap) {
  //     SetErrorMessage("Entered Time is overlapping")
  //   }
  //   else {
  //     // Update start time if no overlap
  //     copy[mKey].timeSlot[nKey].startTime = value;
  //     setWorkDays(copy);
  //   }
  // };

  const changeStartTimeSlot = (workDayIndex, timeSlotIndex, value) => {
    const copy = [...workDays];    
    copy[workDayIndex].timeSlot[timeSlotIndex].startTime = value;
    setWorkDays(copy);

    // // Convert the new value to a dayjs object
    // const newStartTime = dayjs(value);

    // // Check for overlap with other time slots
    // const hasOverlap = currentDaySlots.some((slot, workDayIndex) => {
    //   if (workDayIndex !== nKey) { // Skip the current slot being edited
    //     const slotStart = dayjs(slot.startTime);
    //     const slotEnd = dayjs(slot.endTime);
    //     return newStartTime.isAfter(slotStart) && newStartTime.isBefore(slotEnd);
    //   }
    //   return false;
    // });

    // if (hasOverlap) {
    //   SetErrorMessage("Entered Time is overlapping");
    // } else {
    //   SetErrorMessage(null); // Reset error message if no overlap
    //   // Update start time
    //   copy[mKey].timeSlot[nKey].startTime = value;
    //   setWorkDays(copy);
    // }
  };


  const changeEndTimeSlot = (workDayIndex, timeSlotIndex, value) => {
    const copy = [...workDays];
    // End Time
    copy[workDayIndex].timeSlot[timeSlotIndex].endTime = value;
    setWorkDays(copy)
  }

  const deleteTimeSlot = (workDayIndex, timeSlotIndex) => {
    // Make a deep copy of the data to avoid mutating the original data
    let updatedData = [...workDays];

    // Ensure workDayIndex is within range
    if (workDayIndex >= 0 && workDayIndex < updatedData.length) {
      // Access the day's timeSlot array
      let timeSlots = updatedData[workDayIndex].timeSlot;

      // Ensure workDayIndex is within the range of the timeSlot array
      if (workDayIndex >= 0 && workDayIndex < timeSlots.length && timeSlots.length > 1) {
        // Remove the specific timeSlot at workDayIndex
        updatedData[workDayIndex].timeSlot = timeSlots.filter((_, i) => i !== workDayIndex);
        let deletedTimeSlot = timeSlots.filter((_, i) => i === workDayIndex);
        if (!updatedData[workDayIndex].deletedTimeSlot && deletedTimeSlot[0].table_id) {
          updatedData[workDayIndex].deletedTimeSlot = deletedTimeSlot;
        }
        else if (updatedData[workDayIndex].deletedTimeSlot && deletedTimeSlot[0].table_id) {
          updatedData[workDayIndex].deletedTimeSlot = [...updatedData[workDayIndex].deletedTimeSlot, ...deletedTimeSlot];
        }
        // else if (updatedData[workDayIndex].deletedTimeSlot && !deletedTimeSlot[0].table_id) {          
        //   updatedData[workDayIndex].timeSlots = timeSlots.filter((_, i) => i !== workDayIndex);
        // }
        // else if (!updatedData[workDayIndex].deletedTimeSlot && !deletedTimeSlot[0].table_id) {
        //   updatedData[workDayIndex].deletedTimeSlot = [...updatedData[workDayIndex].deletedTimeSlot, ...deletedTimeSlot];
        // }
      } else {
        console.error("Invalid workDayIndex:", workDayIndex);
      }
    } else {
      console.error("Invalid workDayIndex:", workDayIndex);
    }

    setWorkDays(updatedData);
  };


  useEffect(() => {
    const copy = [...workDays];
    if (_draft_data) {
      const data = _draft_data?.working_days;
      for (const key in data) {
        if (data[key].day === copy[key].value) {
          const avail = copy[key].availableOption;
          avail[0].selected = data[key].availability.unavailable;
          avail[1].selected = data[key].availability.whole_day;
          const slots = data[key].slots;
          // for (const k in slots) {
          //     copy[key].timeSlot[k].startTime.$H = 8
          //     copy[key].timeSlot[k].startTime.$m = 30
          //     // copy[key].timeSlot[k].startTime.$m = slots[k].start_time.M
          // }
        }
      }
    }
    setWorkDays(copy)
  }, [_draft_data])

  const getStaffWorkingDays = async (_history) => {
    const workingDays = await WorkingDay({
      variables: {
        "filter": {
          "staff_detail_id": {
            "id": {
              "eq": _history
            }
          }
        }
      }
    });

    let days = workingDays?.data?.workingDays?.data;
    if (days.length > 0) {

      let transformResponse = days.map(day => {
        return {
          title: day?.attributes?.select_day,
          value: day?.attributes?.select_day,
          type: "checkbox",
          required: true,
          timeSlot: [
            {
              date: new Date(),
              startTime: dayjs(day?.attributes?.day_start_time, 'HH;mm:ss.SSS'),
              endTime: dayjs(day?.attributes?.day_end_time, 'HH;mm:ss.SSS'),
              required: true,
              type: "timePicker",
              table_id: day?.id,
              isNewTimeSlot: false,
            }
          ],
          availableOption: [
            {
              title: 'Unavailable',
              selected: day?.attributes?.unavailable,
            },
            {
              title: 'Whole Day',
              selected: day?.attributes?.whole_day,
            },
          ]
        }
      })

      const reducedDays = transformResponse.reduce((acc, curr) => {
        // Check if the title already exists in the accumulator
        const existingDay = acc.find(day => day.title === curr.title);

        if (existingDay) {
          // Merge the timeSlot array
          existingDay.timeSlot = [...existingDay.timeSlot, ...curr.timeSlot];
        } else {
          // Add the current day to the accumulator
          acc.push(curr);
        }

        return acc;
      }, []);

      setGotDatafromDB(true);      
      setWorkDays(reducedDays)
    }
  }

  useEffect(() => {
    if (_history) {
      getStaffWorkingDays(_history);
    }
  }, [_history]);

  const handleCloseSnacks = () => {
    setSnack({
      ...snacks,
      open: false,
    });
  };

  const handleSnacksApper = (title, message, severity) => {
    setSnack({
      ...snacks,
      open: true,
    });
    // / // // // display message
    setErrorSnackMessage({
      ...errorSnackMessage,
      title,
      message,
      severity
    })
  }

  let createWorkingDayCount = 0;
  const createWorkingDay = async (data) => {
    try {
      const response = await apiCreateStaffDay({
        variables: data
      });
      if (response.data) {
        ++createWorkingDayCount
      }

      handleSnacksApper("Staff Onboarding", "Working days has been created", "success")

    } catch (error) {
      let message = "Something went wrong.!";
      if (error_day) {
        const errors = error_day;
        if (errors?.networkError) {
          message = "Network error occurred. Please try again later"
        }
        if (errors?.graphQLErrors) {
          message = errors.graphQLErrors[0]?.message
        }
        handleSnacksApper("Staff Onboarding", message, "error")
      }
    }
  }

  let updateWorkingDayCount = 0;
  const updateWorkingDay = async (data, totalCount) => {
    try {
      const response = await apiUpdateWorkingDay({
        variables: data
      });
      if (response.data) {
        ++updateWorkingDayCount
      }
      if (totalCount == updateWorkingDayCount) {
        handleSnacksApper("Staff Onboarding", "Working days has been created", "success")
      }
    } catch (error) {
      let message = "Something went wrong.!";
      if (error_day) {
        const errors = error_day;
        if (errors?.networkError) {
          message = "Network error occurred. Please try again later"
        }
        if (errors?.graphQLErrors) {
          message = errors.graphQLErrors[0]?.message
        }
        handleSnacksApper("Staff Onboarding", message, "error")
      }
    }
  }

  const deleteWorkingDay = async (data) => {
    try {

      let count = 0;
      for (const key in data) {
        const variables = {
          id: data[key].table_id
        }
        const response = await apiDeleteWorkingDay({ variables: variables });
        if (response.data) {
          ++count
        }
      }
      if (data.length === count) {
        handleSnacksApper("Staff Onboarding", "Time slots has been deleted", "success")
      }
    } catch (error) {
      let message = "Something went wrong.!";
      if (error_day) {
        const errors = error_day;
        if (errors?.networkError) {
          message = "Network error occurred. Please try again later"
        }
        if (errors?.graphQLErrors) {
          message = errors.graphQLErrors[0]?.message
        }
        handleSnacksApper("Staff Onboarding", message, "error")
      }
    }
  }

  const addZerro = (value) => {
    if (value <= 9) {
      return "0" + value
    } else {
      return value
    }
  }

  function debounce(func, timeout = 2000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const redirectToDashboard = debounce(() => {
    dispatch(DynamicStaffOnboard(null));
    dispatch(createdId(null));
    navigate("/staff-dashboard", {
      state: {
        activeOption: 0
      }
    });
  });

  const savingDataInToDb = async () => {
    if (gotDatafromDB) {
      for (const key in workDays) {
        const slots = workDays[key].timeSlot;
        const option = workDays[key].availableOption;

        // Process creation of new time slots sequentially
        for (const key2 in slots) {
          if (slots[key2].isNewTimeSlot) {
            const object = {
              select_day: workDays[key].title,
              day_start_time: addZerro(slots[key2]?.startTime?.$H) + ":" + addZerro(slots[key2]?.startTime?.$m) + ":00",
              day_end_time: addZerro(slots[key2]?.endTime?.$H) + ":" + addZerro(slots[key2]?.endTime?.$m) + ":00",
              unavailable: option[0].selected,
              whole_day: option[1].selected,
              staff_detail_id: _history,
            };
            await createWorkingDay(object); // Sequentially create working days
          } else {
            const variables = {
              id: slots[key2].table_id,
              data: {
                select_day: workDays[key].title,
                day_start_time: addZerro(slots[key2]?.startTime?.$H) + ":" + addZerro(slots[key2]?.startTime?.$m) + ":00",
                day_end_time: addZerro(slots[key2]?.endTime?.$H) + ":" + addZerro(slots[key2]?.endTime?.$m) + ":00",
                unavailable: option[0].selected,
                whole_day: option[1].selected,
                staff_detail_id: _history,
              },
            };
            await updateWorkingDay(variables, slots.length); // Sequentially update working days
          }
        }

        // Process deletion of time slots sequentially     

        if (workDays[key].deletedTimeSlot) {
          const { deletedTimeSlot } = workDays[key];
          await deleteWorkingDay(deletedTimeSlot);
        }
      }
    }
    else {
      const totalWorkDays = [];

      for (const key in workDays) {
        const slots = workDays[key].timeSlot;
        const option = workDays[key].availableOption;
        for (const key2 in slots) {
          const object = {
            select_day: workDays[key].title,
            day_start_time: addZerro(slots[key2]?.startTime?.$H) + ":" + addZerro(slots[key2]?.startTime?.$m) + ":00",
            day_end_time: addZerro(slots[key2]?.endTime?.$H) + ":" + addZerro(slots[key2]?.endTime?.$m) + ":00",
            unavailable: option[0].selected,
            whole_day: option[1].selected,
            staff_detail_id: _history
          };
          totalWorkDays.push(object);
        }
      }

      // Process each work day sequentially
      for (const workDay of totalWorkDays) {
        await createWorkingDay(workDay);
      }
    }
    // Redirect to dashboard after all tasks are completed
    // redirectToDashboard();
    getStaffWorkingDays(_history);
  };

  // const shouldDisableTime = (timeValue, clockType, nV, i, nI) => {
  //   // Use the passed workDayIndex (i) to get the correct day from workDays
  //   const currentDayData = workDays[i];
  //   if (!currentDayData) {
  //     return true; // Disable all if no data for the current day
  //   }

  //   // Format the time value to match the slot times (HH:mm format)
  //   const time = dayjs(timeValue).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  //   const isDisabled = currentDayData.timeSlot.every((slot) => {      
  //     const slotStart = dayjs(slot.startTime);
  //     const slotEnd = dayjs(slot.endTime);


  //     // For hours (clockType === 'hours')
  //     if (clockType === "hours") {
  //       // Disable hours outside the range of all slots
  //       return timeValue < slotStart.hour() || timeValue >= slotEnd.hour();
  //     }

  //     // For minutes (clockType === 'minutes')
  //     if (clockType === "minutes") {
  //       const currentHour = dayjs(timeValue).hour();
  //       if (currentHour >= slotStart.hour() && currentHour <= slotEnd.hour()) {
  //         // Disable minutes outside the slot range
  //         const startMinute = slotStart.minute();
  //         const endMinute = slotEnd.minute();
  //         return timeValue < startMinute || timeValue >= endMinute;
  //       }
  //     }

  //     return true; // Disable time if it does not match the slot range
  //   });

  //   return isDisabled;
  // };

  return (
    <Box sx={{ width: "100%" }}>
      {workDays.length > 0 ?
        <Box>
          <Grid container>
            <Grid item={true} xs={12} sm={1} md={1} lg={1} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontFamily: "Roboto-Bold", }}>
                Day
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontFamily: "Roboto-Bold", }}>
                Available Time slot
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={3} md={3} lg={3} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontFamily: "Roboto-Bold", }}>
                Availability Options
              </Typography>
            </Grid>
          </Grid>
          {
            workDays.map((workDay, workDayIndex) => {
              return (
                <Grid key={workDayIndex} container>
                  <Grid item={true} xs={12} sm={1} md={1} lg={1} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
                    <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                      {workDay.value}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ border: "1px solid lightgrey", display: "flex", alignItems: "center", }}>
                    {/* list of slots */}
                    <Grid item={true} xs={12} sm={11} md={11} lg={11} sx={{ p: 1, display: "flex", alignItems: "center", overflowX: "auto" }}>
                      {workDay?.timeSlot.map((timeSlotValue, timeSlotIndex) => {
                        return (
                          <Grid key={timeSlotIndex} item={true} xs={12} sm={4} md={4} lg={4} sx={{ px: 1, display: "flex", }}>
                            <Grid item={true} xs={12} sm={5} md={5} lg={5} >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* <MobileTimePicker
                            label="Start Time"
                            value={nV.startTime}
                            sx={{ width: 100 }}
                            minutesStep={60}
                            onChange={(e) => changeStartTimeSlot(i, nI, e)}
                            disabled={workDays[i].availableOption[0].selected || workDays[i].availableOption[1].selected}
                            renderInput={(params) => (
                              <TextField 
                                {...params} 
                                error={errorMessage !== null} 
                                helperText={errorMessage} 
                                fullWidth 
                              />
                            )}
                          /> */}
                                <MobileTimePicker
                                  label="Start Time"
                                  value={timeSlotValue.startTime}
                                  onChange={(e) => changeStartTimeSlot(workDayIndex, timeSlotIndex, e)}
                                  disabled={workDays[workDayIndex].availableOption[0].selected || workDays[workDayIndex].availableOption[1].selected}
                                // shouldDisableTime={(timeValue, clockType) => {
                                //   return shouldDisableTime(timeValue, clockType, nV, i, nI)
                                // }}

                                />

                              </LocalizationProvider>
                            </Grid>
                            <Grid item={true} xs={12} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                              <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                                {"To"}
                              </Typography>
                            </Grid>
                            <Grid item={true} xs={12} sm={5} md={5} lg={5}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileTimePicker
                                  label="End Time"
                                  value={timeSlotValue.endTime}
                                  sx={{ width: 100 }}
                                  onChange={(e) => changeEndTimeSlot(workDayIndex, timeSlotIndex, e)}
                                  disabled={workDays[workDayIndex].availableOption[0].selected || workDays[workDayIndex].availableOption[1].selected}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item={true} xs={12} sm={2} md={2} lg={1} sx={{ display: "flex", alignItems: 'center' }}>
                              <IconButton
                                disabled={workDays[workDayIndex].availableOption[0].selected || workDays[workDayIndex].availableOption[1].selected}
                                onClick={() => deleteTimeSlot(workDayIndex, timeSlotIndex)}>
                                <Cancel />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                    {/* create button */}
                    <Grid item={true} xs={12} sm={1} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", }}>
                      <Button
                        sx={{
                          minWidth: "auto",
                          padding: "5px 10px"
                        }}
                        variant="outlined"
                        onClick={() => addNewTimeSlot(workDayIndex)}
                        disabled={workDays[workDayIndex].availableOption[0].selected || workDays[workDayIndex].availableOption[1].selected}
                      >
                        <AddIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item={true} xs={12} sm={3} md={3} lg={3} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
                    {workDay?.availableOption.map((availableOptionValue, availableOptionIndex) => {
                      return (
                        <FormControlLabel
                          spacing={0}
                          key={availableOptionIndex}
                          control={
                            <Checkbox
                              checked={availableOptionValue.selected}
                              onChange={(e) => changeAvailability(workDayIndex, availableOptionIndex)}
                            />
                          }
                          label={availableOptionValue.title}
                        />
                      )
                    })}
                  </Grid>
                </Grid>
              )
            })
          }
        </Box> :
        <CircularProgress color="secondary" size={26} />
      }
      <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", mt: "1%" }}>
        <Stack direction={"row"} spacing={2}>
          <Tooltip title="Can Change After Save" placement="left">
            <Button color={"primary"} disabled={loading_day} onClick={() => savingDataInToDb()}
              variant="outlined"
              sx={{
                fontSize: "1.1em",
                bgcolor: "#ffffff",
                fontFamily: "Roboto-Medium",
                textTransform: "capitalize",
                color: "#000000",
                border: "2px solid #6ECA35",
                ":hover": {
                  border: "2px solid #6ECA35",
                }
              }}>
              Save
              {loading_day ? <CircularProgress color="secondary" size={26} /> : null}
            </Button>
          </Tooltip>
        </Stack>
      </Box>

      <Snackbar
        open={snacks.open}
        autoHideDuration={5000}
        onClose={handleCloseSnacks}
        TransitionComponent={snacks.Transition}
        anchorOrigin={{
          vertical: snacks.vertical,
          horizontal: snacks.horizontal
        }}>
        <Alert
          onClose={handleCloseSnacks}
          severity={errorSnackMessage.severity}
          variant={errorSnackMessage.variant}
          sx={{
            width: '100%'
          }}
        >
          <AlertTitle>{errorSnackMessage.title}</AlertTitle>
          {errorSnackMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
export default EditWorkDaysAndTimeSlots;