import Typography from '@mui/material/Typography'
import React from 'react'

const CustomErrorText = ({ errorMessage }) => {
    return (
        <Typography variant='body2' sx={{ mb: 3, color: 'red' }}>
            {errorMessage}
        </Typography>
    )
}

export default CustomErrorText
