import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import APIGQL from "./graphql";


export const StaffDetailsList = () => {
  const { data, loading } = useQuery(APIGQL.ApiGetStaffDetailsList, {
    fetchPolicy: "cache-and-network"
  })
  const staffData = data?.staffDetails?.data;
  return [staffData, loading];
}

export const GetStaffWithId = () => {
  const [fetchStaffById, { fetchStaffById_loading }] = useLazyQuery(APIGQL.ApiGetStaffWithId, {
    fetchPolicy: "cache-and-network"
  })
  return [fetchStaffById, fetchStaffById_loading];
}
export const GetStaffContactDetailsById = () => {
  const [getStaffContactDetailsById, { getStaffContactDetailsById_loading }] = useLazyQuery(APIGQL.ApiGetContactInfoById, {
    fetchPolicy: "cache-and-network"
  })
  return [getStaffContactDetailsById, getStaffContactDetailsById_loading];
}

export const GetDocumentLinks = () => {
  const [getDocumentLinksById, { getDocumentLinksById_loading }] = useLazyQuery(APIGQL.ApiGetDocumentLinks, {
    fetchPolicy: "cache-and-network"
  })
  return [getDocumentLinksById, getDocumentLinksById_loading];
}

export const GetDocumentProfessionalLists = () => {
  const [getDocumentProfessionalLists, { getDocumentProfessionalLists_loading }] = useLazyQuery(APIGQL.ApiGetDocumentProfessionalLists, {
    fetchPolicy: "cache-and-network"
  })
  return [getDocumentProfessionalLists, getDocumentProfessionalLists_loading];
}

export const GetEmergencyContactDetailsByFilter = () => {
  const [getEmergencyContactDetailsByFilter, { getEmergencyContactDetailsByFilter_loading }] = useLazyQuery(APIGQL.ApiGetEmergencyContactDetailsByFilter, {
    fetchPolicy: "cache-and-network"
  })
  return [getEmergencyContactDetailsByFilter, getEmergencyContactDetailsByFilter_loading];
}

export const GetStaffAnswerLovsReferences = () => {
  const [fetchStaffAnswerLovsReferences, { fetchStaffAnswerLovsReferences_loading }] = useLazyQuery(APIGQL.ApiGetStaffAnswerLovsReferences, {
    fetchPolicy: "cache-and-network"
  })
  return [fetchStaffAnswerLovsReferences, fetchStaffAnswerLovsReferences_loading];
}

export const GetStaffQuestionAnswerRefs = () => {
  const [fetchStaffQuestionAnswerRefs, { fetchStaffQuestionAnswerRefs_loading }] = useLazyQuery(APIGQL.ApiGetStaffQuestionAnswerRefs, {
    fetchPolicy: "cache-and-network"
  })
  return [fetchStaffQuestionAnswerRefs, fetchStaffQuestionAnswerRefs_loading];
}

export const GetStaffDetailsWithId = () => {
  const [fetchDataById, { loading }] = useLazyQuery(APIGQL.ApiGetStaffDetailsWithId, {
    fetchPolicy: "cache-and-network"
  })
  return [fetchDataById, loading];
}

export const GetStaffPaymentDetails = () => {
  const [GetStaffPaymentDetails, { GetStaffPaymentDetails_loading, GetStaffPaymentDetails_error }] = useLazyQuery(APIGQL.ApiGetStaffPaymentDetails);
  return [GetStaffPaymentDetails, GetStaffPaymentDetails_loading, GetStaffPaymentDetails_error];
}

export const StaffDetailsWithRelationalDataId = () => {
  const [fetchDataById, { loading }] = useLazyQuery(APIGQL.ApiGetStaffDetailsWithRelationalData, {
    fetchPolicy: "cache-and-network"
  })
  return [fetchDataById, loading];
}

// // // // --------------------------------------- mutation ---------------------------

export const UploadNewDocument = () => {
  const [createDocs, { loading, error }] = useMutation(APIGQL.ApiUploadNewDocument);
  return [createDocs, loading, error]
}

export const CreateNewStaff = () => {
  const [createStaff, { loading, error }] = useMutation(APIGQL.ApiCreateNewStaff);
  return [createStaff, loading, error]
}

export const UpdateStaffAnswerLovsReference = () => {
  const [updateStaffAnsLov, { updateStaffAnsLovLoading, updateStaffAnsLovError }] = useMutation(APIGQL.ApiUpdateStaffAnswerLovsReference);
  return [updateStaffAnsLov, updateStaffAnsLovLoading, updateStaffAnsLovError]
}

export const UpdateStaffQuestionAnswerRef = () => {
  const [UpdateStaffQuestionAnswerRef, { UpdateStaffQuestionAnswerRefLoading, UpdateStaffQuestionAnswerRefError }] = useMutation(APIGQL.ApiUpdateStaffQuestionAnswerRef);
  return [UpdateStaffQuestionAnswerRef, UpdateStaffQuestionAnswerRefLoading, UpdateStaffQuestionAnswerRefError]
}

export const UpdateEmergencyContactDetail = () => {
  const [updateEmergencyContactDetail, { updateEmergencyContactDetailLoading, updateEmergencyContactDetailError }] = useMutation(APIGQL.ApiUpdateEmergencyContactDetail);
  return [updateEmergencyContactDetail, updateEmergencyContactDetailLoading, updateEmergencyContactDetailError]
}

export const CreateStaffAnswerLovsReference = () => {
  const [createStaffAnsLov, { loading, error }] = useMutation(APIGQL.ApiCreateStaffAnswerLovsReference);
  return [createStaffAnsLov, loading, error]
}

export const CreateContactDetailForStaff = () => {
  const [createStaffContact, { loading, error }] = useMutation(APIGQL.ApiCreateContactDetailForStaff);
  return [createStaffContact, loading, error]
}

export const CreateEmergencyContactDetailForStaff = () => {
  const [createStaffEmergency, { loading, error }] = useMutation(APIGQL.ApiCreateEmergencyContactDetailForStaff);
  return [createStaffEmergency, loading, error]
}

export const CreateStaffQuestionSingleAnswerRef = () => {
  const [createStaffQuestion, { loading, error }] = useMutation(APIGQL.ApiCreateStaffQuestionSingleAnswerRef);
  return [createStaffQuestion, loading, error]
}

export const CreateStaffPaymentDetail = () => {
  const [createPayment, { loading, error }] = useMutation(APIGQL.ApiCreateStaffPaymentDetail);
  return [createPayment, loading, error]
}

export const UpdateStaffPaymentDetail = () => {
  const [updateStaffPaymentDetail, { updateStaffPaymentDetail_Loading, updateStaffPaymentDetail_Error }] = useMutation(APIGQL.ApiUpdateStaffPaymentDetail);
  return [updateStaffPaymentDetail, updateStaffPaymentDetail_Loading, updateStaffPaymentDetail_Error]
}

export const CreateWorkingDay = () => {
  const [createDay, { loading, error }] = useMutation(APIGQL.ApiCreateWorkingDay);
  return [createDay, loading, error]
}

export const UpdateWorkingDay = () => {
  const [updateWorkingDay, { updateWorkingDay_loading, updateWorkingDay_error }] = useMutation(APIGQL.ApiUpdateWorkingDay);
  return [updateWorkingDay, updateWorkingDay_loading, updateWorkingDay_error]
}

export const DeleteWorkingDay = () => {
  const [deleteWorkingDay, { deleteWorkingDay_loading, deleteWorkingDay_error }] = useMutation(APIGQL.ApiDeleteWorkingDay);
  return [deleteWorkingDay, deleteWorkingDay_loading, deleteWorkingDay_error]
}

export const CreateDocumentsProfessionalList = () => {
  const [createDocument, { loading, error }] = useMutation(APIGQL.ApiCreateDocumentsProfessionalList);
  return [createDocument, loading, error]
}

export const CreateDocumentLink = () => {
  const [createDocumentLink, { loading, error }] = useMutation(APIGQL.ApiCreateDocumentLink);
  return [createDocumentLink, loading, error]
}

export const CreateNewUserForLogin = () => {
  const [createNewLogin, { loading, error }] = useMutation(APIGQL.ApiCreateNewUserForLogin);
  return [createNewLogin, loading, error]
}

export const DeleteDocumentLink = () => {
  const [deleteDocumentLink, { deleteDocumentLink_loading, deleteDocumentLink_error }] = useMutation(APIGQL.ApiDeleteDocumentLink);
  return [deleteDocumentLink, deleteDocumentLink_loading, deleteDocumentLink_error]
}

export const DeleteDocumentsProfessionalList = () => {
  const [deleteDocumentsProfessionalList, { deleteDocumentsProfessionalList_loading, deleteDocumentsProfessionalList_error }] = useMutation(APIGQL.ApiDeleteDocumentsProfessionalList);
  return [deleteDocumentsProfessionalList, deleteDocumentsProfessionalList_loading, deleteDocumentsProfessionalList_error]
}