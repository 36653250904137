import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Button, Grid, Slide, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { GetStaffAdjustHour, UpdateStaffHoursStatus } from '../../../services/finance_service';
import dayjs from 'dayjs';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import CustomSnackBar from '../../../components/custom_snack_bar';
import duration from 'dayjs/plugin/duration';

const APPROVAL_STATUS = {
    PENDING: 'pending',
    REJECTED: 'rejected',
    APPROVED: 'approved'
}

export const AdjustHoursUpdate = () => {
    const [adjustHoursData, setAdjustHoursData] = React.useState(null);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState('success');
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogData, setDialogData] = React.useState({ status: '', row: null });
    const [isOvertime, setIsOverTime] = React.useState(false);

    const [getStaffAdjustHours, loading, error] = GetStaffAdjustHour();
    const [updateStaffHoursStatus] = UpdateStaffHoursStatus();

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'staff_name', headerName: 'Staff Name', width: 200 },
        { field: 'schedule_start_time', headerName: 'Schedule Start Time', width: 200 },
        { field: 'schedule_end_time', headerName: 'Schedule End Time', width: 200 },
        {
            field: 'clock_in_time',
            headerName: 'Clock In Time',
            width: 200,
            editable: true,
            renderEditCell: (params) => {
                if(params.row.status === APPROVAL_STATUS.APPROVED) {
                    return (
                        <Typography>{params.row.clock_in_time}</Typography>
                    )
                }
                return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        value={dayjs(params.value, "hh:mm A")} // Parse the current value
                        onChange={(time) => {
                            // Update the value in the DataGrid state
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: time ? time.format("hh:mm A") : "",
                            });
                        }}
                        renderInput={(props) => <input {...props} />}
                    />
                </LocalizationProvider>
                )
            },
        },
        {
            field: 'clock_out_time',
            headerName: 'Clock Out Time',
            width: 200,
            editable: true,
            renderEditCell: (params) => {
                if(params.row.status === APPROVAL_STATUS.APPROVED) {
                    return (
                        <Typography>{params.row.clock_out_time}</Typography>
                    )
                }
                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            value={dayjs(params.value, "hh:mm A")} // Parse the current value
                            onChange={(time) => {
                                // Update the value in the DataGrid state
                                params.api.setEditCellValue({
                                    id: params.id,
                                    field: params.field,
                                    value: time ? time.format("hh:mm A") : "",
                                });
                            }}
                            renderInput={(props) => <input {...props} />}
                        />
                    </LocalizationProvider>
                )
            },
        },
        { field: 'overtime', headerName: 'Overtime', width: 200 },
        {
            field: 'status',
            headerName: 'Approval Status',
            width: 150,
            renderCell: (params) => (
                <Chip
                    label={params.row.status.toUpperCase()}
                    color={params.row.status === APPROVAL_STATUS.PENDING ? 'warning' : params.row.status === APPROVAL_STATUS.APPROVED ? "primary" : 'error'}
                    sx={{ color: '#fff', fontWeight: 'bold' }}
                />
            ),
        },
        // {
        //     field: 'view_detail',
        //     headerName: 'View Detail',
        //     width: 200,
        //     sortable: false,
        //     filterable: false,
        //     renderCell: (params) => (
        //         <Button
        //             variant="contained"
        //             color="primary"
        //             size="small"
        //             sx={{ color: '#fff', fontWeight: 'bold' }}
        //             onClick={() => console.log(params.row)}
        //         >
        //             View
        //         </Button>
        //     ),
        // },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={params.row.status !== APPROVAL_STATUS.PENDING || !isOvertime}
                        size="small"
                        sx={{ color: '#fff', fontWeight: 'bold' }}
                        onClick={() => handleOpenDialog(APPROVAL_STATUS.APPROVED, params)}
                    >
                        Approve
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        disabled={params.row.status !== APPROVAL_STATUS.PENDING || !isOvertime}
                        size="small"
                        sx={{ ml: 2, color: '#fff', fontWeight: 'bold' }}
                        onClick={() => handleOpenDialog(APPROVAL_STATUS.REJECTED, params)}
                    >
                        Reject
                    </Button>
                </Grid>
            ),
        },
    ];

    const handleOpenDialog = (status, params) => {
        setDialogData({ status, row: params.row });
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleConfirmUpdate = async () => {
        const { status, row } = dialogData;
        const response = await updateStaffHoursStatus({ variables: { id: row.id, data: { status } } });
        if (response) {
            setSnackMessage('Success');
            setSnackSeverity('success');
            setSnackOpen(true);
            getStaffAdjustHourData();
        }
        handleCloseDialog();
    };

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const calculateOverTime = (schedule_start_time, schedule_end_time, clock_in_time, clock_out_time) => {

        const scheduleStartTime = dayjs(schedule_start_time, "HH:mm:ss");
        const scheduleEndTime = dayjs(schedule_end_time, "HH:mm:ss");
        const clockInTime = dayjs(clock_in_time, "HH:mm:ss");
        const clockOutTime = dayjs(clock_out_time, "HH:mm:ss");

        // Calculate durations in minutes
        const scheduledMinutes = scheduleEndTime.diff(scheduleStartTime, "minute");
        const workedMinutes = clockOutTime.diff(clockInTime, "minute");

        dayjs.extend(duration);

        // Calculate overtime
        const overtimeMinutes = workedMinutes - scheduledMinutes;

        const time = dayjs.duration(overtimeMinutes, 'minutes');

        // Format to hours and minutes
        const hours = Math.floor(time.asHours());
        const remainingMinutes = time.minutes();
        setIsOverTime(overtimeMinutes > 0 ? true : false);
        return overtimeMinutes > 0 ? `${hours} hour(s) and ${remainingMinutes} minute(s)` : `No overtime`;
    }

    const getStaffAdjustHourData = async () => {
        const result = await getStaffAdjustHours();
        const filteredResult = result.data?.staffAdjustHours?.data?.map((val) => ({
            id: val.id,
            staff_name: `${val.attributes?.staff_id?.data?.attributes?.first_name} ${val.attributes?.staff_id?.data?.attributes?.last_name}`,
            schedule_start_time: dayjs(val.attributes?.start_schedule_time, "HH:mm:ss.SSS").format("hh:mm A"),
            schedule_end_time: dayjs(val.attributes?.end_schedule_time, "HH:mm:ss.SSS").format("hh:mm A"),
            clock_in_time: dayjs(val.attributes?.clock_in_time, "HH:mm:ss.SSS").format("hh:mm A"),
            clock_out_time: dayjs(val.attributes?.clock_out_time, "HH:mm:ss.SSS").format("hh:mm A"),
            overtime: calculateOverTime(
                val.attributes?.start_schedule_time,
                val.attributes?.end_schedule_time,
                val.attributes?.clock_in_time,
                val.attributes?.clock_out_time),
            status: val.attributes?.status
        }));

        setAdjustHoursData(filteredResult);
    };

    const handleProcessRowUpdate = async (updatedData, oldData) => {

        if (JSON.stringify(updatedData) !== JSON.stringify(oldData)) {
            // Update logic
            const response = await updateStaffHoursStatus({
                variables: {
                    "id": Number(updatedData.id),
                    "data": {
                        'clock_in_time': dayjs(updatedData.clock_in_time, "hh:mm A").format("HH:mm:ss.SSS"),
                        'clock_out_time': dayjs(updatedData.clock_out_time, "hh:mm A").format("HH:mm:ss.SSS"),
                    },
                },
            });

            if (response) {
                setSnackMessage('Updated Successfully');
                setSnackSeverity('success');
                setSnackOpen(true);
            }
        }
        else {
            setSnackMessage('Nothing to update');
            setSnackSeverity('warning');
            setSnackOpen(true);
        }
    }

    React.useEffect(() => {
        getStaffAdjustHourData();
    }, [snackOpen]);

    return (
        <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" }, }}>
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message={snackMessage}
                title={snackSeverity === 'success' ? 'Success' : snackSeverity === 'warning' ? 'Information' : 'Error'}
                severity={snackSeverity}
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {dialogData.status === APPROVAL_STATUS.APPROVED ? 'approve' : 'reject'} this adjustment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary" variant='contained'>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmUpdate} color="primary" variant='contained' sx={{ color: '#fff' }}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid item={true} xs={11.5} sm={12} md={12} lg={11} component={Paper} elevation={0}>
                <DataGrid
                    rows={adjustHoursData}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    processRowUpdate={handleProcessRowUpdate}
                    disableRowSelectionOnClick
                    sx={{
                        flexGrow: 1,
                        '& .MuiDataGrid-row': {
                            backgroundColor: '#ffffff',
                        },
                        '& .MuiDataGrid-row:nth-of-type(odd)': {
                            backgroundColor: '#f9f9f9',
                        },
                        '& .MuiDataGrid-cell--editable': {
                            backgroundColor: '#fff',
                            color: '#000',
                        },
                        '& .MuiDataGrid-cell:hover': {
                            backgroundColor: '#f1f1f1',
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};
