import { Box, Button, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import generatePDF from 'react-to-pdf';

const getDatePlusDays = (daysToAdd) => {
    const today = new Date();
    const resultDate = new Date(today);

    // Add the specified number of days
    resultDate.setDate(resultDate.getDate() + daysToAdd);

    // Format the date (Optional, to get it in YYYY-MM-DD format)
    const formattedDate = resultDate.toISOString().split('T')[0];

    return formattedDate;
}

export const InvoicePDFView = ({ data, shifts, handleModal }) => {
    const [pages, setPages] = React.useState([])
    const targetRef = React.useRef();

    React.useEffect(() => {

        if (data && shifts) {
            const copy = [...pages]
            let nextPage = [];
            const shiftsList = shifts[0].filter(val => val.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.id === data?.service_id);

            if (shiftsList) {
                const shiftsPending = [];
                let object = {
                    page_no: copy.length + 1,
                    company: {
                        name: "Ethical Supports Group Ptv Ltd",
                        address: "PO BOX 5074 Canning Vale South Canning Vale, WA 6155",
                        email: "finance@cennasupport.com",
                        website_url: "https://dev.cennasupports.com",
                        abn: "1265 783 8198",
                        reg: "4050112188",
                    },
                    invoice_to: "invoices@managemode.com.au",
                    invoice_number: data.claim_referance,
                    invoice_date: data.today,
                    invoice_due_date: getDatePlusDays(7),
                    invoice_terms: "NET 7",
                    ndis_participant_number: data.ndis_Number,
                    payment_details: {
                        bank_name: "Commonwealth Bank",
                        bsb: "066118",
                        account: "10461792"
                    },
                    period_from: data.supports_delivered_from,
                    period_to: data.supports_delivered_to,
                    total_excluding_gst: data.total_price,
                    gst_total: "0.00",
                    amount_due: "",
                    power_by: "belacorp.com.au"
                }

                for (const key1 in shiftsList) {
                    const shiftsArray = []
                    const rateMapping = {
                        day_time_hours: "day_rate",
                        evening_hours: "evening_rate",
                        holiday_hours: "public_holiday_rate",
                        night_hours: "night_rate",
                        saturday_hours: "saturday_rate",
                        sunday_hours: "sunday_rate",
                    };

                    Object.entries(rateMapping).forEach(([hourKey, rateKey]) => {
                        const hours = shiftsList[key1].attributes[hourKey];
                        if (hours !== 0) {
                            const amount =
                                shiftsList[key1]?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.charge_band_rate?.data?.attributes?.[rateKey];
                            shiftsArray.push({ time: hours, amount });
                        }
                    });

                    const support_num = shiftsList[key1]?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.ndis_price_list_id?.data?.attributes?.support_item_number;
                    const service_name = shiftsList[key1]?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.ndis_price_list_id?.data?.attributes?.support_item_name;
                   
                    if (shiftsPending.length < 11) {
                        shiftsArray.map((val) => (
                            shiftsPending.push({
                                details: moment(shiftsList[key1].attributes.shift_date).format("DD-MM-YYYY") + " " + service_name?.slice(50) + " (" + val.time  + " hours" + ") [" + support_num + "]",
                                qty: val.time,
                                unit:`$${val.amount}`,
                                total:`$${ (val.time * val.amount).toFixed(2)}`,
                                gst: "$0.00",
                                due:`$${ (val.time * val.amount).toFixed(2)}`,
                            })
                        ))
                    } else {
                        shiftsArray.map((val) => (
                            nextPage.push({
                                details: moment(shiftsList[key1].attributes.shift_date).format("DD-MM-YYYY") + " " + service_name?.slice(50) + " (" + val.time  + " hours" + ") [" + support_num + "]",
                                qty: val.time,
                                unit:`$${val.amount}`,
                                total:`$${ (val.time * val.amount).toFixed(2)}`,
                                gst: "$0.00",
                                due:`$${ (val.time * val.amount).toFixed(2)}`,
                            })
                        ))
                    }
                    object.service_user = data.participant_name;
                }
                if (nextPage.length > 0 && shiftsPending.length < 11) {
                    nextPage = []
                }
                object.shifts_list = shiftsPending;
                copy.push(object);
                if (nextPage.length > 0) {
                    copy.push({
                        company: {
                            name: "Ethical Supports Group Ptv Ltd",
                            address: "PO BOX 5074 Canning Vale South Canning Vale, WA 6155",
                            email: "finance@cennasupport.com",
                            website_url: "https://dev.cennasupports.com",
                            abn: "1265 783 8198",
                            reg: "4050112188",
                        },
                        page_no: copy.length + 1,
                        shifts_list: nextPage,
                        power_by: "belacorp.com.au"
                    })
                }
            }
            setPages(copy)
        }
    }, [data, shifts])

    return (
        <Box>
            <Box ref={targetRef}>
                {pages?.map((value, index) => {
                    return (
                        <Paper key={value.ndis_participant_number + index}
                            style={{
                                padding: "20px",
                                marginBottom: "20px",
                                height: "1450px", // Adjust height to simulate page breaks
                                display: "flex",
                                flexDirection: "column",
                                pageBreakAfter: "always", // For page breaks
                                overflow: "hidden",
                            }}
                        >
                            {value?.company ? (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                }}>
                                    <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>{value.company.name}</Typography>
                                    <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>{value.company.address}</Typography>
                                    <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>{value.company.email}</Typography>
                                    <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>{value.company.website_url}</Typography>
                                    <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>ABN: {value.company.abn}</Typography>
                                    <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>REG: {value.company.reg}</Typography>
                                </Box>
                            ) : null}
                            <Grid textAlign={'center'}>
                                <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "2em" }}>TAX INVOICE - PAGE {value.page_no}</Typography>
                            </Grid>
                            {value?.invoice_to ? (
                                <Grid display={'flex'} justifyContent={'space-between'} marginTop={1}>
                                    <Grid>
                                        <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1em" }}>Invoice To</Typography>
                                        <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{value.invoice_to}</Typography>
                                    </Grid>
                                    <Grid display={'flex'} alignItems={'center'} gap={1}>
                                        <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.2em" }}>Invoice Number</Typography>
                                        <Box sx={{ border: '1px solid #000', padding: '5px', display: 'inline-block' }}>
                                            <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{value.invoice_number}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : null}
                            {value?.invoice_date ? (
                                <Grid display={'flex'} flexDirection={'column'} alignItems={'flex-end'} gap={1}>
                                    <Grid display={'flex'} alignItems={'center'} gap={1}>
                                        <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.1em" }}>Invoice Date</Typography>
                                        <Box sx={{ border: '1px solid #000', padding: '5px' }}>
                                            <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{value.invoice_date}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid display={'flex'} alignItems={'center'} gap={1}>
                                        <Typography sx={{ fontFamily: "Roboto-Medium", fontSize: "1.1em" }}>Due Date</Typography>
                                        <Box sx={{ border: '1px solid #000', padding: '5px' }}>
                                            <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{moment(value.invoice_due_date).format("DD-MM-YYYY")}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : null}
                            {value?.payment_details ? (
                                <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid>
                                        <Grid marginBottom={2}>
                                            <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>Please Make Payments To</Typography>
                                            <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{value.payment_details.bank_name}</Typography>
                                            <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>BSB: {value.payment_details.bsb}</Typography>
                                            <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>Account: {value.payment_details.account}</Typography>
                                        </Grid>
                                        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={1} mb={1}>
                                            <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>Period From</Typography>
                                            <Box sx={{ border: '1px solid #000', padding: '5px', textAlign: 'center' }}>
                                                <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{moment(value.period_from).format("DD-MM-YYYY")}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={1} mb={1}>
                                            <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>Period To</Typography>
                                            <Box sx={{ border: '1px solid #000', padding: '5px', textAlign: 'center' }}>
                                                <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{moment(value.period_to).format("DD-MM-YYYY")}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={1}>
                                            <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>Service User</Typography>
                                            <Box sx={{ border: '1px solid #000', padding: '5px', textAlign: 'center' }}>
                                                <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{value.service_user}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid display={'flex'} flexDirection={'column'} alignItems={'flex-end'} gap={1}>
                                        <Grid display={'flex'} alignItems={'center'} gap={1} marginTop={1}>
                                            <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>Terms</Typography>
                                            <Box sx={{ border: '1px solid #000', padding: '5px' }}>
                                                <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{value.invoice_terms}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid display={'flex'} alignItems={'center'} gap={1}>
                                            <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>NDIS Participant Number</Typography>
                                            <Box sx={{ border: '1px solid #000', padding: '5px' }}>
                                                <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{value.ndis_participant_number}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid
                                            sx={{
                                                border: '2px solid #000',
                                                padding: 1,
                                                width: '130%'
                                            }}
                                        >
                                            <Grid sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-end',
                                                gap: 1
                                            }}>
                                                <Grid display={'flex'} alignItems={'center'} gap={1}>
                                                    <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>TOTAL EXCLUDING GST</Typography>
                                                    <Box sx={{ border: '1px solid #000', padding: '5px', display: 'inline-block' }}>
                                                        <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>{value.total_excluding_gst}</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid display={'flex'} alignItems={'center'} gap={1}>
                                                    <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1em" }}>GST TOTAL</Typography>
                                                    <Box sx={{ border: '1px solid #000', padding: '5px', display: 'inline-block' }}>
                                                        <Typography sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>${value.gst_total}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ backgroundColor: '#000', color: '#fff', padding: '10px', mt: 2 }}>
                                                <Grid container justifyContent="space-between">
                                                    <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1.1em" }}>AMOUNT DUE</Typography>
                                                    <Typography sx={{ fontFamily: "Roboto-Bold", fontSize: "1.1em" }}>{value.total_excluding_gst}</Typography>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : null}
                            <Grid item xs={12} mt={2}>
                                <TableContainer sx={{ border: "1px solid #000", }}>
                                    <Table aria-label="invoice table">
                                        <TableHead sx={{
                                            background: '#000',
                                        }}>
                                            <TableRow>
                                                <TableCell><strong style={{ color: '#fff' }}>Details</strong></TableCell>
                                                <TableCell><strong style={{ color: '#fff' }}>Qty</strong></TableCell>
                                                <TableCell align="left"><strong style={{ color: '#fff' }}>Unit $</strong></TableCell>
                                                <TableCell align="left"><strong style={{ color: '#fff' }}>Total $</strong></TableCell>
                                                <TableCell align="left"><strong style={{ color: '#fff' }}>GST $</strong></TableCell>
                                                <TableCell align="left"><strong style={{ color: '#fff' }}>Due $</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {value.shifts_list.map((row, index) => {
                                                return (
                                                    <TableRow key={index} >
                                                        <TableCell sx={{ borderRight: "1px solid #000", fontFamily: "Roboto-Regular", fontSize: "1em" }} align="left">{row.details}</TableCell>
                                                        <TableCell sx={{ borderRight: "1px solid #000", fontFamily: "Roboto-Regular", fontSize: "1em" }} align="left">{row.qty}</TableCell>
                                                        <TableCell sx={{ borderRight: "1px solid #000", fontFamily: "Roboto-Regular", fontSize: "1em" }} align="left">{row.unit}</TableCell>
                                                        <TableCell sx={{ borderRight: "1px solid #000", fontFamily: "Roboto-Regular", fontSize: "1em" }} align="left">{row.total}</TableCell>
                                                        <TableCell sx={{ borderRight: "1px solid #000", fontFamily: "Roboto-Regular", fontSize: "1em" }} align="left">{row.gst}</TableCell>
                                                        <TableCell sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }} align="left">{row.due}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid pt={2}>
                                <Typography fontSize={12}>Powered by {value.power_by}</Typography>
                            </Grid>
                        </Paper>
                    )
                })}
            </Box>

            <Box sx={{ mx: 2, display: "flex", justifyContent: "flex-end", }}>
                <Stack direction="row" spacing={2}>
                    <Button onClick={() => generatePDF(targetRef, { filename: `cennasupports-${data.supports_delivered_to}.pdf` })} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                        Download PDF
                    </Button>
                    <Button onClick={() => handleModal()} variant="outlined" sx={{ bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                        Cancel
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}
