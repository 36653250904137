import { admissionInformationsById, auditCategories, auditDocumentNames, auditModules, auditTypes, clientDetailById, clientDetailList, clientDetailsRelationlData, clientGoalsAndCareDetailsById, designFrontendModules, dynamicFrontends, filterVisitDayAndCheckList, getAllUserDetails, getDocumentList, reimbursementsByServicePlan, getStaffDetailsWithId, getUserData, homeRiskAssessment, individualRiskAssessment, medicalInformationById, organizationList, priceList, referringDoctorInformationsById, safetyPlanById, servicePlans, servicePlansFilters, serviceRequests, staffDetailsList, staffDetailsWithRelationalData, workingDaysByFilters, kilometersClaimsByServicePlan, servicePlansFilter, allRelationalClientInformationById, visitDaysByServiceId, serviceShiftFilters, preferenceForStaffWorkerById, ndisPriceList, chargeBandDetails, chargeBandRates, historyOfClientService, contactInfoById, emergencyContactDetailsByFilter, administrativeNotesByFilter, serviceInformationsByFilter, preferenceForStaffWorkersByFilter, clientGoalsByFilter, checkListAlertsNotificationsByFilter, planReviewByFilter, clientAnswerLovsReferences, schadAwardsRequest, onboardMedicalHistoryDetailById, referringDoctorInformationById, clientQuestionAnswerRefById, ndisPriceListCategory, getStaffWithId, getstaffAnswerLovsReferences, getStaffQuestionAnswerRefs, getStaffPaymentDetails, getDocumentLinks, getDocumentProfessionalLists, StaffAdjustHour, invoicing } from './query'

import { createAdministrativeNote, createAdmissionInformation, createAuditCategory, createAuditDocumentName, createAuditModule, createAuditType, createChargeBandDetail, createChargeBandRate, createCheckListAlertsNotification, createClientAnswerLovsReference, createClientDetail, createClientGoal, createClientNote, createClientQuestionAnswerRef, createContactDetailForClient, createContactDetailForStaff, createDocumentsProfessionalList, createEmergencyContactDetailForClient, createEmergencyContactDetailForStaff, createInvoicing, createMedicationsForClient, createNewDocument, createNewStaff, createNewStaffPay, createNewUserForLogin, createOnboardMedicalHistoryDetail, createOrganization, createPlanReview, createPlanSupportCategory, createPreferenceForStaffWorker, createPriceList, createReferringDoctorInformation, createServiceInformation, createServicePlan, createServiceRequest, createServiceShift, createStaffAnswerLovsReference, createStaffOthersInformation, createStaffPaymentDetail, createStaffQuestionSingleAnswerRef, createSupportBudget, createVisitDay, createVisitShiftCheckList, createWorkingDay, loginQuery, updateChargeBandRate, updateClientDetail, updateKilometersClaim, updateReimbursement, updateServicePlan, updateStaffDetail, uploadNewDocument, SendOnboardingLinkEmail, updateClientAnswerLovsReference, updateContactDetail, updateEmergencyContactDetail, updateAdministrativeNote, updateServiceInformation, updatePreferenceForStaffWorker, updateClientGoal, updateCheckListAlertsNotification, updatePlanReview, updatePlanSupportCategory, updateMedicalInformation, updateOnboardMedicalHistoryDetail, updateReferringDoctorInformation, updateClientQuestionAnswerRef, createDocumentLink, updateStaffAnswerLovsReference, updateStaffQuestionAnswerRef, updateStaffPaymentDetail, deleteDocumentLink, deleteDocumentsProfessionalList, updateWorkingDay, deleteWorkingDay, updateStaffHoursStatus, updateInvoiceStatus} from './mutation'

const APIGQL = {
    ApiLogin: loginQuery,
    ApiUserDetails: getUserData,
    ApiUploadNewDocument: uploadNewDocument,
    // ApiDesignFrontendsByTitle: designFrontendsByTitle,
    ApiDynamicFrontends: dynamicFrontends,
    ApiDesignFrontendModules: designFrontendModules,
    ApiGetDocumentList: getDocumentList,
    ApiGetAuditModules: auditModules,
    ApiGetAuditCategories: auditCategories,
    ApiGetAuditTypes: auditTypes,
    ApiGetAuditDocumentNames: auditDocumentNames,
    ApiGetPriceList: priceList,
    ApiGetOrganizationList: organizationList,
    ApiGetStaffDetailsList: staffDetailsList,
    ApiGetStaffDetailsWithId: getStaffDetailsWithId,
    ApiGetStaffWithId: getStaffWithId,
    ApiGetDocumentLinks: getDocumentLinks,
    ApiGetDocumentProfessionalLists: getDocumentProfessionalLists,
    ApiGetStaffAnswerLovsReferences: getstaffAnswerLovsReferences,
    ApiGetStaffQuestionAnswerRefs: getStaffQuestionAnswerRefs,
    ApiGetClientDetailList: clientDetailList,
    ApiClientDetailsRelationlData: clientDetailsRelationlData,
    ApiGetClientDetailById: clientDetailById,
    ApiGetClientAnswerLovsReferences: clientAnswerLovsReferences,
    ApiGetContactInfoById: contactInfoById,
    ApiGetEmergencyContactDetailsByFilter: emergencyContactDetailsByFilter,
    ApiGetAdministrativeNotesByFilter: administrativeNotesByFilter,
    ApiGetServiceInformations: serviceInformationsByFilter,
    ApiGetPreferenceForStaffWorkers: preferenceForStaffWorkersByFilter,
    ApiGetClientGoalsByFilter: clientGoalsByFilter,
    ApiGetCheckListAlertsNotificationsByFilter: checkListAlertsNotificationsByFilter,
    ApiGetPlanReviewByFilter: planReviewByFilter,
    ApiGetMedicalInformationById: medicalInformationById,
    ApiGetOnboardMedicalHistoryDetailById: onboardMedicalHistoryDetailById,
    ApiReferringDoctorInformationById: referringDoctorInformationById,
    ApiClientQuestionAnswerRefById: clientQuestionAnswerRefById,
    ApiGetReferringDoctorInformationsById: referringDoctorInformationsById,
    ApiGetAdmissionInformationsById: admissionInformationsById,
    ApiGetIndividualRiskAssessment: individualRiskAssessment,
    ApiGetHomeRiskAssessment: homeRiskAssessment,
    ApiGetSafetyPlanById: safetyPlanById,
    ApiGetClientGoalsAndCareDetailsById: clientGoalsAndCareDetailsById,
    ApiGetStaffDetailsWithRelationalData: staffDetailsWithRelationalData,
    // // // // -----------------------------------
    ApiCreateNewDocument: createNewDocument,
    ApiCreateNewModule: createAuditModule,
    ApiCreateAuditCategory: createAuditCategory,
    ApiCreateAuditType: createAuditType,
    ApiCreateAuditDocumentName: createAuditDocumentName,
    ApiCreatePriceList: createPriceList,
    ApiCreateOrganization: createOrganization,
    // // // // -----------------staff onboard------------------------------
    ApiCreateNewStaff: createNewStaff,
    ApiCreateStaffQuestionSingleAnswerRef: createStaffQuestionSingleAnswerRef,
    ApiCreateStaffAnswerLovsReference: createStaffAnswerLovsReference,
    ApiUpdateStaffAnswerLovsReference: updateStaffAnswerLovsReference,
    ApiUpdateStaffQuestionAnswerRef: updateStaffQuestionAnswerRef,
    ApiGetStaffPaymentDetails: getStaffPaymentDetails,
    ApiCreateContactDetailForStaff: createContactDetailForStaff,
    ApiCreateEmergencyContactDetailForStaff: createEmergencyContactDetailForStaff,
    ApiCreateStaffOthersInformation: createStaffOthersInformation,
    ApiCreateStaffPaymentDetail: createStaffPaymentDetail,
    ApiUpdateStaffPaymentDetail: updateStaffPaymentDetail,
    ApiCreateDocumentsProfessionalList: createDocumentsProfessionalList,
    ApiCreateDocumentLink: createDocumentLink,
    ApiCreateWorkingDay: createWorkingDay,
    ApiUpdateWorkingDay: updateWorkingDay,
    ApiDeleteWorkingDay: deleteWorkingDay,
    ApiCreateNewUserForLogin: createNewUserForLogin,
    ApiDeleteDocumentLink: deleteDocumentLink,
    ApiDeleteDocumentsProfessionalList: deleteDocumentsProfessionalList,
    // // ===========================admin==========================================
    ApiGetAllUserDetails: getAllUserDetails,
    /////////========================admin======================================
    ApiUpdateStaffDetail: updateStaffDetail,
    ApiUpdateClientDetail: updateClientDetail,
    // /// /// /// // =================client onboard ==========================
    ApiCreateClientDetail: createClientDetail,
    ApiCreateClientAnswerLovsReference: createClientAnswerLovsReference,
    ApiCreateContactDetailForClient: createContactDetailForClient,
    ApiCreateEmergencyContactDetailForClient: createEmergencyContactDetailForClient,
    ApiCreateAdministrativeNote: createAdministrativeNote,
    ApiCreateServiceInformation: createServiceInformation,
    ApiCreatePreferenceForStaffWorker: createPreferenceForStaffWorker,
    ApiCreateClientNote: createClientNote,
    ApiCreateClientGoal: createClientGoal,
    ApiCreateCheckListAlertsNotification: createCheckListAlertsNotification,
    ApiCreatePlanReview: createPlanReview,
    ApiCreatePlanSupportCategory: createPlanSupportCategory,
    ApiCreateMedicationsForClient: createMedicationsForClient,
    ApiCreateOnboardMedicalHistoryDetail: createOnboardMedicalHistoryDetail,
    ApiCreateReferringDoctorInformation: createReferringDoctorInformation,
    ApiCreateAdmissionInformation: createAdmissionInformation,
    ApiCreateClientQuestionAnswerRef: createClientQuestionAnswerRef,
    // /// /// /// // =================client onboard ==========================
    ApiSendOnboardingLinkEmail: SendOnboardingLinkEmail,
    // // // // // =============Servie===========================================
    ApiCreateServiceRequest: createServiceRequest,
    ApiCreateServiceShift: createServiceShift,
    ApiCreateVisitDay: createVisitDay,
    ApiCreateVisitShiftCheckList: createVisitShiftCheckList,
    ApiGetServiceRequests: serviceRequests,
    ApiGetSchadAwards: schadAwardsRequest,
    ApiGetFilterVisitDayAndCheckList: filterVisitDayAndCheckList,
    ApiGetServicePlans: servicePlans,
    ApiCreateServicePlan: createServicePlan,
    ApiServicePlansFilter: servicePlansFilter,
    ApiUpdateServicePlan: updateServicePlan,
    // // // // /---------------invoice=========================================
    ApiGetReimbursementsByServicePlan: reimbursementsByServicePlan,
    ApiUpdateReimbursement: updateReimbursement,
    ApiGetKilometersClaimsByServicePlan: kilometersClaimsByServicePlan,
    ApiUpdateKilometersClaim: updateKilometersClaim,
    // // // // // // // =============View============================================================
    ApiGetAllRelationalClientInformationById: allRelationalClientInformationById,
    // // /// // // // schedule ============================================================
    ApiVisitDaysByServiceId: visitDaysByServiceId,
    ApiServiceShiftFilters: serviceShiftFilters,
    ApiWorkingDaysByFilters: workingDaysByFilters,
    ApiPreferenceForStaffWorkerById: preferenceForStaffWorkerById,
    ApiNdisPriceListCategory: ndisPriceListCategory,
    ApiNdisPriceList: ndisPriceList,
    ApiCreateChargeBandDetail: createChargeBandDetail,
    ApiGetChargeBandDetails: chargeBandDetails,
    ApiGetStaffAdjustHour: StaffAdjustHour,
    ApiGetInvoicing: invoicing,
    ApiCreateChargeBandRate: createChargeBandRate,
    ApiChargeBandRates: chargeBandRates,
    ApiUpdateChargeBandRate: updateChargeBandRate,
    ApiUpdateStaffHoursStatus: updateStaffHoursStatus,
    ApiUpdateInvoiceStatus: updateInvoiceStatus,
    // // // // // =======================invoice============================================
    ApiCreateInvoicing: createInvoicing,
    ApiCreateNewStaffPay: createNewStaffPay,
    ApiHistoryOfClientService: historyOfClientService,

    // /// /// =====================Update=====================================================
    ApiUpdateClientAnswerLovsReference: updateClientAnswerLovsReference,
    ApiUpdateContactDetail: updateContactDetail,
    ApiUpdateReferringDoctorInformation: updateReferringDoctorInformation,
    ApiUpdateOnboardMedicalHistoryDetail: updateOnboardMedicalHistoryDetail,
    ApiUpdateClientQuestionAnswerRef: updateClientQuestionAnswerRef,
    ApiUpdateMedicalInformation: updateMedicalInformation,
    ApiUpdateEmergencyContactDetail: updateEmergencyContactDetail,
    ApiUpdateAdministrativeNote: updateAdministrativeNote,
    ApiUpdateServiceInformation: updateServiceInformation,
    ApiUpdatePreferenceForStaffWorker: updatePreferenceForStaffWorker,
    ApiUpdateClientGoal: updateClientGoal,
    ApiUpdateCheckListAlertsNotification: updateCheckListAlertsNotification,
    ApiUpdatePlanReview: updatePlanReview,
    ApiUpdatePlanSupportCategory: updatePlanSupportCategory
}

export default APIGQL;