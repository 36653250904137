import { Alert, AlertTitle, Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, Slide, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePickerUI, TimePickerUI } from "../../../../../components/Inputs";
import { LocalizationProvider, MobileTimePicker, mk } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AddIcon from '@mui/icons-material/Add';
import { Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { createdId, DynamicStaffOnboard } from "../../../../../middleware/reducers/staff_onboarding_reducer";
import { WorkingDaysByFilters } from "../../../../../services/schedule_service";
import { CreateWorkingDay } from "../../../../../services/staff_service";
import { useNavigate } from "react-router-dom";


const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
}
const WorkDaysAndTimeSlots = () => {
  const _draft_data = useSelector((state) => state._staff_onboarding_module.selectedDraft);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _database_id = useSelector((state) => state._staff_onboarding_module.created_id);  
  const [apiCreateStaffDay, loading_day, error_day] = CreateWorkingDay(); // create staff question
  const [WorkingDay, loading] = WorkingDaysByFilters();

  const defaultTimeSlot = {
    date: new Date(),
    startTime: dayjs('2022-04-17T12:00'),
    endTime: dayjs('2022-04-17T12:00'),
    required: true,
    type: "timePicker"
  };

  const defaultAvailableOptions = [
    { title: 'Unavailable', selected: false },
    { title: 'Whole Day', selected: false }
  ];

  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const [workDays, setWorkDays] = useState(() =>
    days.map(day => ({
      title: day,
      value: day,
      type: "checkbox",
      required: true,
      timeSlot: [{ ...defaultTimeSlot }], // Clone defaultTimeSlot if needed
      availableOption: defaultAvailableOptions.map(option => ({ ...option })) // Create a new copy of each option
    }))
  );

  const changeAvailability = (mKey, nKey, value) => {
    const copy = [...workDays];
    for (const key in copy[mKey].availableOption) {
      if (key === String(nKey)) {
        copy[mKey].availableOption[nKey].selected = !copy[mKey].availableOption[nKey].selected;
      } else {
        copy[mKey].availableOption[key].selected = false;
      }
    }
    setWorkDays(copy)
  }

  const addNewTimeSlot = (workDayIndex) => {
    const copy = [...workDays];
    if (copy[workDayIndex].timeSlot.length < 3) {
      copy[workDayIndex].timeSlot.push({
        date: new Date(),
        startTime: dayjs().startOf('day'),
        endTime: dayjs().startOf('day'),
        required: true,
        type: "timePicker"
      })
    }
    setWorkDays(copy)
  }

  const changeStartTimeSlot = (workDayIndex, availableOptionIndex, value) => {
    const copy = [...workDays];
    // Start Time
    copy[workDayIndex].timeSlot[availableOptionIndex].startTime = value;
    setWorkDays(copy)
  }

  const changeEndTimeSlot = (workDayIndex, availableOptionIndex, value) => {
    const copy = [...workDays];
    // End Time
    copy[workDayIndex].timeSlot[availableOptionIndex].endTime = value;
    setWorkDays(copy)
  }

  const deleteTimeSlot = (index, availableOptionIndex) => {
    // Make a deep copy of the data to avoid mutating the original data
    let updatedData = [...workDays];

    // Ensure index is within range
    if (index >= 0 && index < updatedData.length) {
      // Access the day's timeSlot array
      let timeSlots = updatedData[index].timeSlot;

      // Ensure availableOptionIndex is within the range of the timeSlot array
      if (availableOptionIndex >= 0 && availableOptionIndex < timeSlots.length && timeSlots.length > 1) {
        // Remove the specific timeSlot at availableOptionIndex
        updatedData[index].timeSlot = timeSlots.filter((_, workDayIndex) => workDayIndex !== availableOptionIndex);
      } else {
        console.error("IavailableOptionValuealid availableOptionIndex:", availableOptionIndex);
      }
    } else {
      console.error("IavailableOptionValuealid index:", index);
    }
    setWorkDays(updatedData);
  };

  useEffect(() => {
    const copy = [...workDays];
    if (_draft_data) {
      const data = _draft_data?.working_days;
      for (const key in data) {
        if (data[key].day === copy[key].value) {
          const avail = copy[key].availableOption;
          avail[0].selected = data[key].availability.unavailable;
          avail[1].selected = data[key].availability.whole_day;
          const slots = data[key].slots;
          // for (const k in slots) {
          //     copy[key].timeSlot[k].startTime.$H = 8
          //     copy[key].timeSlot[k].startTime.$m = 30
          //     // copy[key].timeSlot[k].startTime.$m = slots[k].start_time.M
          // }
        }
      }
    }
    setWorkDays(copy)
  }, [_draft_data])

  const [snacks, setSnack] = useState({
    open: false,
    Transition: SlideTransition,
    vertical: 'top',
    horizontal: 'right',
  });

  const [errorSnackMessage, setErrorSnackMessage] = useState({
    title: "",
    message: "",
    severity: "",
    variant: "filled"
  });

  const handleCloseSnacks = () => {
    setSnack({
      ...snacks,
      open: false,
    });
  };

  let count = 0;

  const handleSnacksApper = (title, message, severity) => {
    setSnack({
      ...snacks,
      open: true,
    });
    // / // // // display message
    setErrorSnackMessage({
      ...errorSnackMessage,
      title,
      message,
      severity
    })
  }

  const createWorkingDay = async (data, totalCount) => {
    try {
      const response = await apiCreateStaffDay({
        variables: data
      });
      if (response.data) {
        ++count;
      }
      if (totalCount === count) {
        handleSnacksApper("Staff Onboarding", "Working days have been created", "success");
      }
    } catch (error) {
      let message = "Something went wrong.!";

      if (error) {
        const errors = error;
        if (errors?.networkError) {
          message = "Network error occurred. Please try again later";
        }
        if (errors?.graphQLErrors) {
          message = errors.graphQLErrors[0]?.message;
        }
      }
      handleSnacksApper("Staff Onboarding", message, "error");
    }
  };

  const addZerro = (value) => {
    if (value <= 9) {
      return "0" + value
    } else {
      return value
    }
  }

  function debounce(func, timeout = 2000) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  const redirectToDashboard = debounce(() => {
    dispatch(DynamicStaffOnboard(null));
    dispatch(createdId(null));
    navigate("/staff-dashboard", {
      state: {
        activeOption: 0
      }
    });
  });

  const savingDataInToDb = async () => {
    count = 0;
    const totalWorkDays = [];

    for (const key in workDays) {
      const slots = workDays[key].timeSlot;
      const option = workDays[key].availableOption;
      for (const key2 in slots) {
        const object = {
          select_day: workDays[key].title,
          day_start_time: addZerro(slots[key2]?.startTime?.$H) + ":" + addZerro(slots[key2]?.startTime?.$m) + ":00",
          day_end_time: addZerro(slots[key2]?.endTime?.$H) + ":" + addZerro(slots[key2]?.endTime?.$m) + ":00",
          unavailable: option[0].selected,
          whole_day: option[1].selected,
          staff_detail_id: _database_id?.id,
        };
        totalWorkDays.push(object);
      }
    }

    // Process each work day sequentially
    for (const workDay of totalWorkDays) {
      await createWorkingDay(workDay, totalWorkDays.length);
    }

    // Redirect to the dashboard after all tasks are completed
    redirectToDashboard();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item={true} xs={12} sm={1} md={1} lg={1} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontFamily: "Roboto-Bold", }}>
            Day
          </Typography>
        </Grid>
        <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontFamily: "Roboto-Bold", }}>
            Available Time slot
          </Typography>
        </Grid>
        <Grid item={true} xs={12} sm={3} md={3} lg={3} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontFamily: "Roboto-Bold", }}>
            Availability Options
          </Typography>
        </Grid>
      </Grid>
      {workDays.map((workDay, workDayIndex) => {

        return (
          <Grid key={workDayIndex} container>
            <Grid item={true} xs={12} sm={1} md={1} lg={1} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                {workDay.value}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ border: "1px solid lightgrey", display: "flex", alignItems: "center", }}>
              {/* list of slots */}
              <Grid item={true} xs={12} sm={11} md={11} lg={11} sx={{ p: 1, display: "flex", alignItems: "center", overflowX: "auto" }}>
                {workDay?.timeSlot.map((availableOptionValue, availableOptionIndex) => {
                  return (
                    <Grid key={availableOptionIndex} item={true} xs={12} sm={4} md={4} lg={4} sx={{ px: 1, display: "flex", }}>
                      <Grid item={true} xs={12} sm={5} md={5} lg={5} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            label="Start Time"
                            value={availableOptionValue.startTime}
                            sx={{ width: 100 }}                            
                            onChange={(e) => changeStartTimeSlot(workDayIndex, availableOptionIndex, e)}
                            disabled={workDays[workDayIndex].availableOption[0].selected || workDays[workDayIndex].availableOption[1].selected}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item={true} xs={12} sm={2} md={2} lg={2} sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                        <Typography sx={{ fontFamily: "Roboto-Medium", }}>
                          {"To"}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={12} sm={5} md={5} lg={5}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            label="End Time"
                            value={availableOptionValue.endTime}                            
                            sx={{ width: 100 }}
                            onChange={(e) => changeEndTimeSlot(workDayIndex, availableOptionIndex, e)}
                            disabled={workDays[workDayIndex].availableOption[0].selected || workDays[workDayIndex].availableOption[1].selected}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item={true} xs={12} sm={2} md={2} lg={1} sx={{ display: "flex", alignItems: 'center' }}>
                        <IconButton
                          disabled={workDays[workDayIndex].availableOption[0].selected || workDays[workDayIndex].availableOption[1].selected}
                          onClick={() => deleteTimeSlot(workDayIndex, availableOptionIndex)}>
                          <Cancel />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
              {/* create button */}
              <Grid item={true} xs={12} sm={1} md={1} lg={1} sx={{ display: "flex", justifyContent: "center", }}>
                <Button
                  sx={{
                    minWidth: "auto",
                    padding: "5px 10px"
                  }}
                  variant="outlined"
                  onClick={() => addNewTimeSlot(workDayIndex)}
                  disabled={workDays[workDayIndex].availableOption[0].selected || workDays[workDayIndex].availableOption[1].selected}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={3} md={3} lg={3} sx={{ p: 1, border: "1px solid lightgrey", display: "flex", alignItems: "center" }}>
              {workDay?.availableOption.map((availableOptionValue, availableOptionIndex) => {
                return (
                  <FormControlLabel
                    spacing={0}
                    key={availableOptionIndex}
                    control={
                      <Checkbox
                        checked={availableOptionValue.selected}
                        onChange={(e) => changeAvailability(workDayIndex, availableOptionIndex, e.target.value)}
                      />
                    }
                    label={availableOptionValue.title}
                  />
                )
              })}
            </Grid>
          </Grid>
        )
      })}
      <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", mt: "1%" }}>
        <Stack direction={"row"} spacing={2}>
          <Tooltip title="Can Change After Save" placement="left">
            <Button color={"primary"} disabled={loading_day} onClick={() => savingDataInToDb()}
              variant="outlined"
              sx={{
                fontSize: "1.1em",
                bgcolor: "#ffffff",
                fontFamily: "Roboto-Medium",
                textTransform: "capitalize",
                color: "#000000",
                border: "2px solid #6ECA35",
                ":hover": {
                  border: "2px solid #6ECA35",
                }
              }}>
              Save
              {loading_day ? <CircularProgress color="secondary" size={26} /> : null}
            </Button>
          </Tooltip>
        </Stack>
      </Box>

      <Snackbar
        open={snacks.open}
        autoHideDuration={5000}
        onClose={handleCloseSnacks}
        TransitionComponent={snacks.Transition}
        anchorOrigin={{
          vertical: snacks.vertical,
          horizontal: snacks.horizontal
        }}>
        <Alert
          onClose={handleCloseSnacks}
          severity={errorSnackMessage.severity}
          variant={errorSnackMessage.variant}
          sx={{
            width: '100%'
          }}
        >
          <AlertTitle>{errorSnackMessage.title}</AlertTitle>
          {errorSnackMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}
export default WorkDaysAndTimeSlots;