import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import {
    Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper, Checkbox, IconButton, Tooltip, Button, Stack,
    CircularProgress,
    Slide,
    Snackbar,
    Alert,
    AlertTitle,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { Save } from '@mui/icons-material';
import { CreateNewStaffPay } from '../../../services/finance_service';
import { roundToNearestQuarter, createString, getCurrentWeekNumber } from '../../../components/functions';

const SlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
}

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'ID',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'Support Worker',
        numeric: true,
        disablePadding: false,
        label: 'Support Worker',
    },
    {
        id: 'Clearance Number',
        numeric: true,
        disablePadding: false,
        label: 'Clearance Number',
    },
    {
        id: 'Day Hours',
        numeric: true,
        disablePadding: false,
        label: 'Day Hours',
    },
    {
        id: 'Day Rate',
        numeric: true,
        disablePadding: false,
        label: 'Day Rate',
    },
    {
        id: 'Total Day Rate',
        numeric: true,
        disablePadding: false,
        label: 'Total Day Rate',
    },
    {
        id: 'Evening Hours',
        numeric: true,
        disablePadding: false,
        label: 'Evening Hours',
    },
    {
        id: 'Evening Rate',
        numeric: true,
        disablePadding: false,
        label: 'Evening Rate',
    },
    {
        id: 'Total Evening Rate',
        numeric: true,
        disablePadding: false,
        label: 'Total Evening Rate',
    },
    {
        id: 'Saturday Hours',
        numeric: true,
        disablePadding: false,
        label: 'Saturday Hours',
    },
    {
        id: 'Saturday Rate',
        numeric: true,
        disablePadding: false,
        label: 'Saturday Rate',
    },
    {
        id: 'Total Saturday Rate',
        numeric: true,
        disablePadding: false,
        label: 'Total Saturday Rate',
    },
    {
        id: 'Sunday Hours',
        numeric: true,
        disablePadding: false,
        label: 'Sunday Hours',
    },
    {
        id: 'Sunday Rate',
        numeric: true,
        disablePadding: false,
        label: 'Sunday Rate',
    },
    {
        id: 'Total Sunday Rate',
        numeric: true,
        disablePadding: false,
        label: 'Total Sunday Rate',
    },
    {
        id: 'Holiday Hours',
        numeric: true,
        disablePadding: false,
        label: 'Holiday Hours',
    },
    {
        id: 'Holiday Rate',
        numeric: true,
        disablePadding: false,
        label: 'Holiday Rate',
    },
    {
        id: 'Total Holiday Rate',
        numeric: true,
        disablePadding: false,
        label: 'Total Holiday Rate',
    },
    {
        id: 'Night Hours',
        numeric: true,
        disablePadding: false,
        label: 'Night Hours',
    },
    {
        id: 'Night Rate',
        numeric: true,
        disablePadding: false,
        label: 'Night Rate',
    },
    {
        id: 'Total Night Rate',
        numeric: true,
        disablePadding: false,
        label: 'Total Night Rate',
    },
    {
        id: 'Total hours',
        numeric: true,
        disablePadding: false,
        label: 'Total hours',
    },
    {
        id: 'Kilometer',
        numeric: true,
        disablePadding: false,
        label: 'Kilometer',
    },
    {
        id: 'Reimbursement',
        numeric: true,
        disablePadding: false,
        label: 'Reimbursement',
    },
    {
        id: 'Bonus',
        numeric: true,
        disablePadding: false,
        label: 'Bonus',
    },
    {
        id: 'Total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
    },
];

const EnhancedTableHead = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ minWidth: headCell.label?.length >= 10 ? headCell.label?.length >= 12 ? 170 : 130 : 110, fontFamily: "Roboto-Medium" }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected, saveInvoicing, data, loading, fileName } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >

                </Typography>
            )}

            {numSelected > 0 ? (
                <Stack direction="row" spacing={2} sx={{
                    width: "100%",
                    justifyContent: "flex-end"
                }}>
                    <Tooltip title="Save to proceed for payment">
                        <Button onClick={() => saveInvoicing()} startIcon={<Save color='primary' />} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Save {loading ? <CircularProgress color="secondary" size={26} sx={{ ml: 1 }} /> : null}
                        </Button>
                    </Tooltip>
                    <Tooltip title="Export to SCV">
                        <Button startIcon={<DownloadIcon color='primary' />} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            <CSVLink
                                data={data}
                                target="_blank"
                                filename={fileName}
                                style={{ color: "#000", textDecoration: "none", fontFamily: "Roboto-Medium" }}
                            >
                                Export to CSV
                            </CSVLink>
                        </Button>
                    </Tooltip>
                    {/* <Tooltip title="Email">
                        <Button startIcon={<EmailIcon color='primary' />} color="primary" variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                            Email
                        </Button>
                    </Tooltip> */}
                </Stack>
            ) : null}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export const PayrollProcessList = () => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [staffPays, setStaffPays] = React.useState([])
    const [visibleRows, setVisibleRows] = React.useState([]);

    const _process_state = useSelector((state) => state._payment_processing_reducer.process_state);
    const _completed_shifts = useSelector((state) => state._payment_processing_reducer.completed_shifts);
    const _kilometers_claim_list = useSelector((state) => state._payment_processing_reducer.kilometers_claim_list);
    const _list_of_reimbursment = useSelector((state) => state._payment_processing_reducer.reimbursement_list);
    const [apiCreateStaffPay, loading, error_s] = CreateNewStaffPay()

    const groupById = (data) => {
        // Grouping objects by their id using reduce
        const grouped = data.reduce((acc, obj) => {
            // If the id is not already a key in the accumulator, create a new array for it
            const serv_id = obj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.id
            const id = obj?.attributes?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.client_detail_id?.data?.id;
            // Create a unique key combining id and serviceId
            const uniqueKey = `${id}-${serv_id}`;
            if (!acc[uniqueKey]) {
                acc[uniqueKey] = [];
            }
            // Push the object into the array for this id
            acc[uniqueKey].push(obj);
            return acc;
        }, {});

        // Convert the grouped object into an array of arrays
        return Object.values(grouped);
    }

    React.useEffect(() => {
        if (_completed_shifts && _kilometers_claim_list) {
            const array = [];
            const fArray = [];
            let numberOfApprovedKM, pay_travel_km;
            const filterData = groupById(_completed_shifts);
            for (const data of filterData) {
                array.push(data)
            }
            for (const key in array) {
                let fObject = {
                    id: Number(key) + 1,
                    reimbursement: 0,
                    kilometers: 0,
                }
                for (const data in array[key]) {
                    const fData = array[key][data].attributes;
                    const staffDetails = fData?.staff_detail_id?.data?.attributes;
                    const fName = staffDetails?.first_name;
                    const lName = staffDetails?.last_name;
                    const ndisClearanceNumber = staffDetails?.ndis_clearance_number
                    const staff_id = fData?.staff_detail_id?.data?.id;
                    pay_travel_km = fData?.visit_day_id?.data?.attributes?.service_request_id?.data?.attributes?.pay_travel_km;
                    numberOfApprovedKM = _kilometers_claim_list?.filter((value) => value.attributes.service_shift_id.data.attributes.staff_detail_id.data.id === staff_id);
                    const numberOfApprovedRM = _list_of_reimbursment?.find((value) => value.attributes.approved_status && value.attributes.service_shift_id.data.id === array[key][data].id);
                    const service_request_id = fData?.visit_day_id?.data?.attributes?.service_request_id?.data?.id;
                    /// // // adding in object
                    fObject.pay_start_date = _process_state.start_date;
                    fObject.pay_end_date = _process_state.end_date;
                    fObject.staff_id = staff_id;
                    fObject.service_id = service_request_id;
                    fObject.support_worker = fName + " " + lName;
                    fObject.fName = fName;
                    fObject.lName = lName;
                    fObject.ndis_clearance_number = ndisClearanceNumber;
                    fObject.day_hours = fData?.day_time_hours;
                    fObject.day_rate = Number(staffDetails?.weekday_rate) || 0;
                    fObject.evening_hours = fData?.evening_hours;
                    fObject.evening_rate = staffDetails?.evening_shift_rate || 0;
                    fObject.saturday_hours = fData?.saturday_hours;
                    fObject.sat_rate = staffDetails?.saturday_rate || 0;
                    fObject.sunday_hours = fData?.sunday_hours;
                    fObject.sun_rate = staffDetails?.sunday_rate || 0;
                    fObject.holiday_hours = fData?.holiday_hours;
                    fObject.holiday_rate = staffDetails?.public_holiday_rate || 0;
                    fObject.night_hours = fData?.sleepover_hours;
                    fObject.night_rate = staffDetails?.night_sleepover_rate || 0;
                    fObject.total_hours = fObject.total_hours ? Number(fObject.total_hours) + Number(fData?.total_shift_hours) : fData?.total_shift_hours;
                    if (numberOfApprovedRM) {
                        fObject.reimbursement = fObject.reimbursement + numberOfApprovedRM.attributes.amount;
                    }
                    fObject.bonus = 0;
                    fObject.approved_status = "Approved";

                }

                if (numberOfApprovedKM && pay_travel_km === "Yes") {
                    for (const kmKey in numberOfApprovedKM) {
                        const total_km = numberOfApprovedKM[kmKey].attributes.total_kilometers;
                        if (!isNaN(total_km) && total_km % 1 === 0 && numberOfApprovedKM[kmKey].attributes.approved_status) {
                            fObject.kilometers += Number(total_km);
                        }
                        if (!isNaN(total_km) && total_km % 1 !== 0 && numberOfApprovedKM[kmKey].attributes.approved_status) {
                            fObject.kilometers += roundToNearestQuarter(Number(total_km))
                        }
                    }
                }
                fObject.total_day_amount = fObject.day_hours * fObject.day_rate;
                fObject.total_night_amount = fObject.night_hours * fObject.night_rate;
                fObject.total_evening_amount = fObject.evening_hours * fObject.evening_rate;
                fObject.total_saturday_amount = fObject.saturday_hours * fObject.sat_rate;
                fObject.total_sunday_amount = fObject.sunday_hours * fObject.sun_rate;
                fObject.total_holiday_amount = fObject.holiday_hours * fObject.holiday_rate;
                fObject.total_rate = fObject.reimbursement + fObject.kilometers + (fObject.day_hours * fObject.day_rate) + (fObject.night_hours * fObject.night_rate) + (fObject.saturday_hours * fObject.sat_rate) + (fObject.sunday_hours * fObject.sun_rate) + (fObject.holiday_hours * fObject.holiday_rate) + (fObject.evening_hours * fObject.evening_rate)
                const manage_day_hours = fObject.day_hours - fObject.saturday_hours - fObject.sunday_hours - fObject.holiday_hours;
                fObject.day_hours = manage_day_hours;
                fArray.push(fObject)
            }

            setStaffPays(fArray)
        }
    }, [_completed_shifts, _kilometers_claim_list])

    const [snacks, setSnack] = React.useState({
        open: false,
        Transition: SlideTransition,
        vertical: 'top',
        horizontal: 'right',
    });

    const [errorSnackMessage, setErrorSnackMessage] = React.useState({
        title: "",
        message: "",
        severity: "",
        variant: "filled"
    });

    const handleCloseSnacks = () => {
        setSnack({
            ...snacks,
            open: false,
        });
    };

    const handleSnacksApper = (title, message, severity) => {
        setSnack({
            ...snacks,
            open: true,
        });

        setErrorSnackMessage({
            ...errorSnackMessage,
            title,
            message,
            severity
        })
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = staffPays?.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - staffPays?.length) : 0;

    React.useEffect(() => {
        // Sort and slice the data whenever the dependencies change
        const sortedData = stableSort(staffPays, getComparator(order, orderBy))?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        );

        // Update the visible rows state
        setVisibleRows(sortedData);
    }, [order, orderBy, page, rowsPerPage, staffPays]);

    const selectedData = () => {
        const selectedItems = staffPays?.filter((e) =>
            selected.some((id) => id === e.id)
        );
        return selectedItems
    }

    const saveInvoicing = async () => {
        try {
            const items = selectedData();
            let count = 0;
            for (const data of items) {
                // console.log(data)
                const object = {
                    staff_detail_id: data.staff_id,
                    day_hours: String(data.day_hours),
                    day_rate: String(data.day_rate),
                    total_day_amount: String(data.total_day_amount),
                    night_hours: String(data.night_hours),
                    night_rate: String(data.night_rate),
                    total_night_amount: String(data.total_night_amount),
                    evening_hours: String(data.evening_hours),
                    evening_rate: String(data.evening_rate),
                    total_evening_amount: String(data.total_evening_amount),
                    saturday_hours: String(data.saturday_hours),
                    saturday_rate: String(data.sat_rate),
                    total_saturday_amount: String(data.total_saturday_amount),
                    sunday_hours: String(data.sunday_hours),
                    sunday_rate: String(data.sun_rate),
                    total_sunday_amount: String(data.total_sunday_amount),
                    holiday_hours: String(data.holiday_hours),
                    holiday_rate: String(data.holiday_rate),
                    total_holiday_amount: String(data.total_holiday_amount),
                    kilometers_claim: String(data.kilometers),
                    reimbursement: String(data.reimbursement),
                    bonus: String(data.bonus),
                    total_pay: String(data.total_rate),
                    total_hours: String(data.total_hours),
                    approved: data.approved_status,
                    pay_start_date: data.pay_start_date,
                    pay_end_date: data.pay_end_date,
                    satff_pay_id: data.staff_id,
                    satff_pay_id: createString(data.fName, data.lName, getCurrentWeekNumber(), new Date().getFullYear()) + _process_state.end_date + data.service_id + data.staff_id
                }
                // // // // console.log("object", object)
                const response = await apiCreateStaffPay({
                    variables: object
                })
                if (response?.data) {
                    count++
                }
            }
            if (count === items.length) {
                handleSnacksApper("NDIS Funded Invoice", "Invoice has been saved.", "success")
            }
        } catch (error) {
            let message = "";
            if (error_s || error) {
                if (error_s?.networkError) {
                    message = "Network error occurred. Please try again later"
                }
                if (error_s?.graphQLErrors) {
                    message = error_s.graphQLErrors[0]?.extensions.code === "INTERNAL_SERVER_ERROR" ? "The invoice already saved or required data is missing.!" : error_s.graphQLErrors[0]?.message;
                }
                if (error?.message) {
                    message = "The invoice already saved or required data is missing.!";
                }
                handleSnacksApper("NDIS Funded Invoice", message, "error")
            }
        }
    }

    return (
        <Box sx={{ mx: 2, width: '100%', border: "1px solid #6ECA35", borderRadius: 3, overflow: "hidden" }} >
            <Grid item={true} xs={12} sm={12} md={12} lg={12} component={Paper} elevation={1}>
                {selected.length > 0 && (
                    <EnhancedTableToolbar loading={loading} numSelected={selected.length} saveInvoicing={saveInvoicing} fileName={"cennasupports" + _process_state.end_date} data={selectedData()} />
                )}
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={staffPays?.length}
                        />
                        <TableBody>
                            {visibleRows?.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row.id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell component="th" id={labelId} scope="row">{row.id}</TableCell>
                                        <TableCell align="left">{row.support_worker}</TableCell>
                                        <TableCell align="left">{row.ndis_clearance_number}</TableCell>
                                        <TableCell align="left">{row.day_hours}</TableCell>
                                        <TableCell align="left">{row.day_rate}</TableCell>
                                        <TableCell align="left">{row.total_day_amount}</TableCell>
                                        <TableCell align="left">{row.evening_hours}</TableCell>
                                        <TableCell align="left">{row.evening_rate}</TableCell>
                                        <TableCell align="left">{row.total_evening_amount}</TableCell>
                                        <TableCell align="left">{row.saturday_hours}</TableCell>
                                        <TableCell align="left">{row.sat_rate}</TableCell>
                                        <TableCell align="left">{row.total_saturday_amount}</TableCell>
                                        <TableCell align="left">{row.sunday_hours}</TableCell>
                                        <TableCell align="left">{row.sun_rate}</TableCell>
                                        <TableCell align="left">{row.total_sunday_amount}</TableCell>
                                        <TableCell align="left">{row.holiday_hours}</TableCell>
                                        <TableCell align="left">{row.holiday_rate}</TableCell>
                                        <TableCell align="left">{row.total_holiday_amount}</TableCell>
                                        <TableCell align="left">{row.night_hours}</TableCell>
                                        <TableCell align="left">{row.night_rate}</TableCell>
                                        <TableCell align="left">{row.total_night_amount}</TableCell>
                                        <TableCell align="left">{row.total_hours}</TableCell>
                                        <TableCell align="left">{row.kilometers}</TableCell>
                                        <TableCell align="left">{row.reimbursement}</TableCell>
                                        <TableCell align="left">{row.bonus}</TableCell>
                                        <TableCell align="left">{row.total_rate}</TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={staffPays?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Grid>

            <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
                <Alert
                    onClose={handleCloseSnacks}
                    severity={errorSnackMessage.severity}
                    variant={errorSnackMessage.variant}
                    sx={{ width: '100%' }}
                >
                    <AlertTitle>{errorSnackMessage.title}</AlertTitle>
                    {errorSnackMessage.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}