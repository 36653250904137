import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import { InvoicePDFView } from ".";

export const CustomModalPDFFile = ({
    openPdf,
    handleModal,
    selectedData,
    shifts,
}) => {

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openPdf}
            onClose={() => handleModal()}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={openPdf}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#F5F7F6',
                    maxWidth: "calc(70%)",
                    minWidth: "calc(60%)",
                    overflowY: "scroll",
                    maxHeight: "calc(90vh)",
                    boxShadow: 12,
                    borderRadius: 3,
                    p: 4,
                }}>
                    <Box sx={{ mx: 2 }}>
                        <Typography color={"primary"} style={{ fontFamily: "Roboto-Bold", fontSize: 24 }}>
                            PDF View
                        </Typography>
                    </Box>
                    <Box sx={{ mx: 2, flex: 1, justifyContent: "center", alignItems: "center", }}>
                        <InvoicePDFView data={selectedData} shifts={shifts} handleModal={handleModal} />
                    </Box>
                </Box>
            </Fade>
        </Modal >
    )
}