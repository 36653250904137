import { Alert, AlertTitle, Box, Button, CircularProgress, Divider, Grid, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Rating, Select, Slide, Snackbar, Stack, TextField, Tooltip, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useSelector, useDispatch } from "react-redux";
import { createdId, DynamicStaffOnboard } from "../../../../middleware/reducers/staff_onboarding_reducer.jsx";
import _, { forEach } from "lodash";
import { DeleteIcon, VisibilityIcon } from "../../../../components/common/Icons.js";
import {
  // Queries
  GetStaffWithId,
  GetStaffAnswerLovsReferences,
  GetStaffQuestionAnswerRefs,
  GetStaffContactDetailsById,
  GetEmergencyContactDetailsByFilter,
  GetStaffPaymentDetails,
  GetDocumentLinks,
  GetDocumentProfessionalLists,

  // Mutations
  CreateContactDetailForStaff,
  CreateDocumentsProfessionalList,
  CreateEmergencyContactDetailForStaff,
  CreateNewStaff,
  CreateNewUserForLogin,
  CreateStaffAnswerLovsReference,
  CreateStaffPaymentDetail,
  CreateStaffQuestionSingleAnswerRef,
  CreateDocumentLink,
  UploadNewDocument,
  UpdateStaffAnswerLovsReference,
  UpdateEmergencyContactDetail,
  UpdateStaffQuestionAnswerRef,
  UpdateStaffPaymentDetail,
  DeleteDocumentLink,
  DeleteDocumentsProfessionalList,
} from "../../../../services/staff_service.jsx";
import moment from "moment";
import {
  UpdateContactDetail,
  UpdateStaffDetail
} from "../../../../services/admin_service.jsx";
import { calculateAge } from "../../../../components/functions/index.jsx"
import { setLoadingState } from "../../../../middleware/reducers/loading_state_reducer.js";
import { setCategoryStep } from "../../../../middleware/reducers/category_step_reducer.js";
import { API, AUTH_TOKEN } from "../../../../config/constant.js";
import { AddCircleOutlineOutlinedIcon } from "../../../../components/common/Icons.js";
import axios from "axios";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const DependentInput = ({ data, match, handleChangeValueInputs }) => {
  const filter = data?.find((e) => e.title.includes(match))
  return (
    <TextField
      type={filter.type}
      key={filter.title + filter.id}
      id={filter.title}
      htmlFor={filter.title}
      required={filter.required}
      multiline={filter.multiline}
      label={filter.title}
      value={filter.value}
      sx={{ m: 1, width: filter.width }}
      onChange={(e) => handleChangeValueInputs(e, filter.id)}
    />
  )
}

export const EditDynamicInputsStaff = ({ categoryTitle, inputsList, inputType, questionList, documents, schadsAwards, duplicateInputField, setError }) => {

  const width = () => {
    if (window.screen.width > 1600) {
      return window.screen.width / 5.2
    }
    if (window.screen.width > 600 && window.screen.width < 1600) {
      return window.screen.width / 4
    } else {
      return window.screen.width / 1.3
    }
  }

  const [screenWidth, setScreenWidth] = useState(width);

  useEffect(() => {
    if (window.screen) {
      setScreenWidth(width);
    }
  }, [window.screen]);

  const [selectedSchadAward, setSelectedSchadAward] = useState(null);
  const [selectedDocType, setSelectedDocType] = useState("");
  const [selectedDocName, setSelectedDocName] = useState(null);
  const [documentNames, setDocumentNames] = useState(null);
  const [listOfCategory, setListOfCategory] = useState([]);
  const [listOfDocsName, setListOfDocsName] = useState(null);
  const [listOfSelectedDoc, setListOfSelectedDoc] = useState([]);
  const [listOfDeletedDoc, setListOfDeletedDoc] = useState([]);
  const [dynamicInputs, setDynamicInputs] = useState(null);
  const [dynamicQuestions, setDynamicQuestions] = useState(null);
  const [dynamicQuestionAnswers, setDynamicAnswers] = useState(null);

  const dispatch = useDispatch()
  const _history = useSelector((state) => state._history.staff_edit_id);

  // ==================================
  const [apiCreateStaffContact, loading_contact, error_contact] = CreateContactDetailForStaff(); // create contact details
  const [apiCreateStaffEmergency, loading_emergency, error_emergency] = CreateEmergencyContactDetailForStaff(); // create emergency details
  const [apiCreateStaffStaffQuestion, loading_question, error_question] = CreateStaffQuestionSingleAnswerRef(); // create staff question
  const [apiCreatePaymentData, loading_payment, error_payment] = CreateStaffPaymentDetail();
  const [apiCreateStaffAnsLov, loading_answer, error_answer] = CreateStaffAnswerLovsReference(); // create drop down value
  const [apiDocumentsList, loading_doc_list, error_doc_list] = CreateDocumentsProfessionalList();
  const [createDocumentLink, loading, error] = CreateDocumentLink();
  const [fetchStaffById, fetchStaffById_loading] = GetStaffWithId();
  const [apiGetStaffPaymentDetails, GetStaffPaymentDetails_loading, GetStaffPaymentDetails_error] = GetStaffPaymentDetails();
  const [getDocumentProfessionalLists, getDocumentProfessionalLists_loading] = GetDocumentProfessionalLists();

  const [getStaffContactDetailsById, getStaffContactDetailsById_loading] = GetStaffContactDetailsById();
  const [getEmergencyContactDetailsByFilter, getEmergencyContactDetailsByFilter_loading] = GetEmergencyContactDetailsByFilter();
  const [fetchStaffAnswerLovsReferences, fetchStaffAnswerLovsReferences_loading] = GetStaffAnswerLovsReferences();
  const [fetchStaffQuestionAnswerRefs, fetchStaffQuestionAnswerRefs_loading] = GetStaffQuestionAnswerRefs();

  const [updateStaffDetails, loading_update, error_update] = UpdateStaffDetail();
  const [apiUpdateContactDetail, load_cont_up, error_cont_up] = UpdateContactDetail();
  const [updateStaffAnsLov, updateStaffAnsLovLoading, updateStaffAnsLovError] = UpdateStaffAnswerLovsReference();
  const [updateEmergencyContactDetail, updateEmergencyContactDetailLoading, updateEmergencyContactDetailError] = UpdateEmergencyContactDetail();
  const [updateStaffQuestionAnswerRef, UpdateStaffQuestionAnswerRefLoading, UpdateStaffQuestionAnswerRefError] = UpdateStaffQuestionAnswerRef();
  const [updateStaffPaymentDetail, updateStaffPaymentDetail_Loading, updateStaffPaymentDetail_Error] = UpdateStaffPaymentDetail();
  const [deleteDocumentLink, deleteDocumentLink_loading, deleteDocumentLink_error] = DeleteDocumentLink();
  const [deleteDocumentsProfessionalList, deleteDocumentsProfessionalList_loading, deleteDocumentsProfessionalList_error] = DeleteDocumentsProfessionalList();

  const [snacks, setSnack] = useState({
    open: false,
    Transition: SlideTransition,
    vertical: 'top',
    horizontal: 'right',
  });

  const [errorSnackMessage, setErrorSnackMessage] = useState({
    title: "",
    message: "",
    severity: "",
    variant: "filled"
  });

  const [editClientDetail, setEditClientDetail] = useState(null)  
  const [lovsAnswerData, setLovsAnswerData] = useState(null)


  const handleCloseSnacks = () => {
    setSnack({
      ...snacks,
      open: false,
    });
  };

  useEffect(() => {
    if (documents) {
      const finalizedAarray = [];
      const useData = documents.audit_documents?.data;
      const id_permit = {
        title_type: "ID / Permit",
        document_list: []
      };
      const training = {
        title_type: "Training",
        document_list: []
      };
      const certificates = {
        title_type: "Certificates",
        document_list: []
      };
      const miscellaneous = {
        title_type: "Miscellaneous",
        document_list: {}
      };
      for (const key in useData) {
        const title = useData[key]?.attributes?.audit_module?.data?.attributes?.title;
        const titleCategory = useData[key]?.attributes?.audit_category?.data?.attributes?.title;
        const titleType = useData[key]?.attributes?.audit_type?.data?.attributes?.title;
        const docTitle = useData[key]?.attributes?.audit_document_name?.data?.attributes?.title;
        let finalObj = {
          module: title,
          categoryTitle: titleCategory,
          type: titleType,
          document_name: docTitle
        }
        if (titleType?.includes("ID / Permit")) {
          id_permit.document_list.push(finalObj)
        }
        if (titleType?.includes("Training")) {
          training.document_list.push(finalObj)
        }
        if (titleType?.includes("Certificates")) {
          certificates.document_list.push(finalObj)
        }
        if (titleType?.includes("Miscellaneous")) {
          miscellaneous.document_list = finalObj;
        }
      };
      finalizedAarray.push(id_permit)
      finalizedAarray.push(training)
      finalizedAarray.push(certificates)
      finalizedAarray.push(miscellaneous)
      const categoryArray = []
      for (const key in finalizedAarray) {
        categoryArray.push({
          title: finalizedAarray[key].title_type
        });
      }
      setListOfCategory(categoryArray)
      setListOfDocsName(finalizedAarray)
    }
  }, [documents])

  useEffect(() => {
    if (selectedDocType) {
      const filter = listOfDocsName?.find((e) => e.title_type === selectedDocType);
      if (filter) {
        setDocumentNames(filter)
      }
    }
  }, [selectedDocType])

  useEffect(() => {
    if (inputsList?.length) {
      const copy = [];
      const filteredDuplicateInputList = duplicateInputField.map(inputVal => (inputVal?.attributes?.title));
      let required = null;
      let active = null;
      let multiline = null;
      let disabled = null;

      for (const key in inputsList) {
        if (filteredDuplicateInputList.includes(inputsList[key]?.attributes?.input_name)) {
          const filteredInputList = duplicateInputField?.filter(inputVal => inputVal?.attributes?.title === inputsList[key]?.attributes?.input_name)[0];

          disabled = filteredInputList.attributes?.disabled;
          required = filteredInputList.attributes?.required;
          active = filteredInputList.attributes?.active;
          multiline = filteredInputList.attributes?.multiline;
        }
        else {
          disabled = inputsList[key].attributes?.disabled;
          required = inputsList[key].attributes?.required;
          active = inputsList[key].attributes?.active;
          multiline = inputsList[key].attributes?.multiline;
        }

        const type = inputsList[key].attributes?.design_frontend_input_type?.data?.attributes?.title;
        const label = inputsList[key].attributes?.input_name;
        const have_child_option = inputsList[key].attributes?.have_child_option;
        const drop_down_options = inputsList[key].attributes?.drop_down_options?.data;
        const placeholder = inputsList[key].attributes?.placeholder;

        if (active) {
          let obj = {
            title: label,
            value: type?.toLowerCase() === "date" ? moment().format("YYYY-MM-DD") : "",
            width: screenWidth,
            type: type,
            required: required,
            multiline: multiline,
            error: null,
            id: key,
            disabled: disabled,
            placeholder: placeholder
          }
          if (have_child_option) {
            obj.options = drop_down_options;
            obj.value = drop_down_options[0].attributes.title;
          }
          copy.push(obj)
        }
      }
      setDynamicInputs(copy);
    }
    if (questionList?.length) {
      const copy = [];
      for (const key in questionList) {
        let object = {}
        object.title = questionList[key]?.attributes?.question;
        object.active = questionList[key]?.attributes?.active;
        object.width = screenWidth;
        object.multiline = false;
        object.error = null;
        object.id = key;
        const answer_type = questionList[key]?.attributes?.answer_type[0];
        if (answer_type?.__typename?.includes("Text")) {
          object.type = "Text";
          object.value = answer_type?.details_value;
          object.required = false;
          object.multiline = object.title.includes("Comments") ? true : false
        }
        if (answer_type?.__typename?.includes("Single")) {
          object.type = "Select";
          object.value = "No";
          object.required = false;
          object.options = answer_type?.drop_down_option_lovs?.data
        }
        copy.push(object)
      }

      setDynamicQuestions(copy)
    }
  }, [])

  const handleChangeValueInputs = (e, index) => {

    try {
      const copy = [...dynamicInputs];

      if (copy[index].title === 'Date of Birth') {
        const DOB = calculateDOB(e.target.value);

        copy[index + 1].value = DOB;
      }
      copy[index].value = e.target.value;

      if (copy[index].error !== null) {
        copy[index].error = null;
      }
      setDynamicInputs(copy);
    } catch (error) {
      console.error("error", error);
      handleSnacksApper(
        "Error",
        "An error occurred while Changing Value Inputs",
        "error"
      );
    }
  }

  const calculateDOB = (dateOfBirth) => {
    const today = moment();
    const birthDate = moment(dateOfBirth, "YYYY-MM-DD");
    let age = today.diff(birthDate, "years");

    // Adjust if the current date hasn't reached the birth date this year
    if (today.isBefore(birthDate.add(age, 'years'))) {
      age--;
    }

    return age;
  }

  const handleSnacksApper = (title, message, severity) => {
    setSnack({
      ...snacks,
      open: true,
    });
    // display message
    setErrorSnackMessage({
      ...errorSnackMessage,
      title,
      message,
      severity
    })
  }
  const convertDataIntoSimplifyObjects = (data) => {
    let inputs = {};
    let selectedOptions = {};
    for (const key in data) {
      if (!data[key]?.options) {
        inputs[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = data[key].value;
      }
      if (data[key]?.options) {
        selectedOptions[data[key].title.toLowerCase().split('/ ').join('')?.split(' ').join('_')] = {
          title: data[key].value,
          id: data[key]?.options.find((e) => e.attributes.title === data[key].value)?.id,
          table_id: data[key]?.table_id,
        }
      }
    }
    return { inputs, selectedOptions }
  }

  const createDocumentList = (file) => {
    try {
      const listOfDocs = [...listOfSelectedDoc]
      const copy = [...dynamicInputs];
      let object = {
        attributes: {
          module: selectedDocName?.module,
          category: selectedDocName?.category,
          type: selectedDocName?.type,
          document_name: selectedDocName?.document_name,
          file: file,
          document_link: null
        }
      }
      for (const key in copy) {
        object.attributes[copy[key].title?.toLowerCase().split(' ').join('_')] = copy[key].value;
        // do empty dynamic inputs
        copy[key].value = "";
      }
      setDynamicInputs(copy);
      listOfDocs.push(object);
      setSelectedDocName(null);
      setSelectedDocType("");
      setListOfSelectedDoc(listOfDocs);
    } catch (error) {
      console.error("error", error)
      handleSnacksApper(
        "Error",
        "An error occurred while Creating Document List",
        "error"
      );
    }
  }

  const getClientDetailsById = async (id) => {

    try {
      if (categoryTitle === "General Information (Staff)") {
        const fetchStaffByIdResp = await fetchStaffById({
          variables: {
            "filters": {
              "id": {
                "eq": id,
              }
            }
          }
        });

        let module_Id = fetchStaffByIdResp?.data?.staffDetails?.data[0].id;
        const fetchStaffAnswerLovsReferencesResp = await fetchStaffAnswerLovsReferences({
          variables: {
            "filters": {
              "module_Id": {
                "eq": Number(module_Id),
              }
            }
          }
        });

        setEditClientDetail(fetchStaffByIdResp?.data?.staffDetails?.data[0]);
        setLovsAnswerData(fetchStaffAnswerLovsReferencesResp?.data?.staffAnswerLovsReferences?.data)
      }

      if (categoryTitle === "Contact Information") {
        const fetchStaffContactDetailsByIdResp = await getStaffContactDetailsById({
          variables: {
            filter: {
              staff_detail_id: {
                id: {
                  eq: id,
                }
              }
            }
          }
        });
        if (fetchStaffContactDetailsByIdResp?.data?.contactDetails?.data.length > 0) {
          let module_Id = fetchStaffContactDetailsByIdResp?.data?.contactDetails?.data[0].id;
          const fetchStaffAnswerLovsReferencesResp = await fetchStaffAnswerLovsReferences({
            variables: {
              "filters": {
                "module_Id": {
                  "eq": Number(module_Id),
                }
              }
            }
          });
          setEditClientDetail(fetchStaffContactDetailsByIdResp?.data?.contactDetails?.data[0]);
          setLovsAnswerData(fetchStaffAnswerLovsReferencesResp?.data?.staffAnswerLovsReferences?.data)
        }
      }

      if (categoryTitle === "Emergency Contact Information") {
        const fetchStaffContactDetailsByIdResp = await getEmergencyContactDetailsByFilter({
          variables: {
            filter: {
              staff_detail_id: {
                id: {
                  eq: id,
                }
              }
            }
          }
        });

        if (fetchStaffContactDetailsByIdResp?.data?.emergencyContactDetails?.data.length > 0) {
          let module_Id = fetchStaffContactDetailsByIdResp?.data?.emergencyContactDetails?.data[0].id;
          const fetchStaffAnswerLovsReferencesResp = await fetchStaffAnswerLovsReferences({
            variables: {
              "filters": {
                "module_Id": {
                  "eq": Number(module_Id),
                }
              }
            }
          });
          setEditClientDetail(fetchStaffContactDetailsByIdResp?.data?.emergencyContactDetails?.data[0]);
          setLovsAnswerData(fetchStaffAnswerLovsReferencesResp?.data?.staffAnswerLovsReferences?.data);
        }
      }

      if (categoryTitle === "Other Information") {
        const fetchStaffByIdResp = await fetchStaffById({
          variables: {
            "filters": {
              "id": {
                "eq": id,
              }
            }
          }
        });

        let module_Id = fetchStaffByIdResp?.data?.staffDetails?.data[0].id;
        const fetchStaffAnswerLovsReferencesResp = await fetchStaffAnswerLovsReferences({
          variables: {
            "filters": {
              "module_Id": {
                "eq": Number(module_Id),
              }
            }
          }
        });

        const fetchStaffQuestionAnswerRefsResp = await fetchStaffQuestionAnswerRefs({
          variables: {
            "filters": {
              "staff_detail_id": {
                "id": {
                  "eq": id,
                }
              }
            }
          }
        });

        if (fetchStaffQuestionAnswerRefsResp?.data?.staffQuestionAnswerRefs?.data.length > 0) {
          setDynamicAnswers(fetchStaffQuestionAnswerRefsResp?.data?.staffQuestionAnswerRefs?.data);
        }
        setEditClientDetail(fetchStaffByIdResp?.data?.staffDetails?.data[0]);
        setLovsAnswerData(fetchStaffAnswerLovsReferencesResp?.data?.staffAnswerLovsReferences?.data)
      }

      if (categoryTitle === "Payment Details") {

        const fetchStaffPaymentDetails = await apiGetStaffPaymentDetails({
          variables: {
            "filters": {
              "staff_detail_id": {
                "id": {
                  "eq": id,
                }
              }
            }
          }
        });

        const fetchStaffByIdResp = await fetchStaffById({
          variables: {
            "filters": {
              "id": {
                "eq": id,
              }
            }
          }
        });

        let module_Id = fetchStaffByIdResp?.data?.staffDetails?.data[0].id;
        const fetchStaffAnswerLovsReferencesResp = await fetchStaffAnswerLovsReferences({
          variables: {
            "filters": {
              "module_Id": {
                "eq": Number(module_Id),
              }
            }
          }
        });


        if (fetchStaffPaymentDetails?.data?.staffPaymentDetails?.data.length > 0 && fetchStaffAnswerLovsReferencesResp?.data?.staffAnswerLovsReferences?.data.length > 0) {
          setEditClientDetail(fetchStaffPaymentDetails?.data?.staffPaymentDetails?.data[0]);
          setLovsAnswerData(fetchStaffAnswerLovsReferencesResp?.data?.staffAnswerLovsReferences?.data)
        }
      }

      if (categoryTitle === "Hourly Rate") {
        const fetchStaffByIdResp = await fetchStaffById({
          variables: {
            "filters": {
              "id": {
                "eq": id,
              }
            }
          }
        });

        if (fetchStaffByIdResp?.data?.staffDetails?.data[0].attributes.schads_awards.data.length > 0) {
          const fetchedSchadAward = fetchStaffByIdResp?.data?.staffDetails?.data[0].attributes.schads_awards.data[0].attributes.level;
          setSelectedSchadAward(schadsAwards.filter(schadsAward => schadsAward.level === fetchedSchadAward)[0].level)
        }
      }

      if (categoryTitle === "Upload Documents") {
        const getDocumentProfessionalListsResp = await getDocumentProfessionalLists({
          variables: {
            "filters": {
              "staff_details_id": {
                "id": {
                  "eq": id
                }
              }
            }
          }

        });
        setListOfSelectedDoc(getDocumentProfessionalListsResp?.data?.documentsProfessionalLists?.data)
      }
    } catch (error) {
      handleSnacksApper(
        "Error",
        "An error occurred while fetching Staff Details",
        "error"
      );
    }
  }

  useEffect(() => {
    if (_history) {
      getClientDetailsById(_history)
    }
  }, [_history])

  const isDataAvailable = (data) => {
    if (data === null || data === undefined) {
      return false; // Data is null or undefined
    }

    if (Array.isArray(data)) {
      return data.length > 0; // Check if array has elements
    }

    if (typeof data === 'object') {
      return Object.keys(data).length > 0; // Check if object has properties
    }

    if (typeof data === 'string') {
      return data.trim().length > 0; // Check if string is not empty
    }

    return Boolean(data); // For other types (e.g., numbers, booleans)
  }

  useEffect(() => {

    if (isDataAvailable(editClientDetail) && isDataAvailable(lovsAnswerData)) {
      if (categoryTitle === "General Information (Staff)") {
        if (dynamicInputs !== null) {
          const mappedInputs = dynamicInputs.map(data => {
            const matchTitle = data.title?.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];

            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {
              const match = lovsAnswerData?.find((e) => e.attributes.input_name.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_') === matchTitle);
              if (match) {
                return { ...data, value: match?.attributes.selected_option_value, table_id: match?.id };
              }
            }
            return data;
          });
          setDynamicInputs(mappedInputs);
        }
        if (dynamicQuestions !== null) {
          const mappedInputs = dynamicQuestions.map(data => {

            const matchTitle = data.title?.toLowerCase().replace(/[/?]+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];

            if (matchTitle === "do_you_have_driving_license" && data.options) {
              return { ...data, value: attributeValue ? "Yes" : "No", table_id: editClientDetail.id };
            }

            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {
              const match = lovsAnswerData?.find((e) => e.attributes.input_name.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_') === matchTitle);

              if (match) {
                return { ...data, value: match?.attributes.selected_option_value, table_id: match?.id };
              }
            }

            return data;
          });

          setDynamicQuestions(mappedInputs);
        }
      }

      if (categoryTitle === "Contact Information") {
        if (dynamicInputs !== null) {
          const mappedInputs = dynamicInputs.map(data => {

            const matchTitle = data.title?.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];


            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {
              const match = lovsAnswerData?.find((e) => e.attributes.input_name.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_') === matchTitle);
              if (match) {
                return { ...data, value: match?.attributes.selected_option_value, table_id: match?.id };
              }
            }

            return data;
          });

          setDynamicInputs(mappedInputs);
        }
        if (dynamicQuestions !== null) {
          const mappedInputs = dynamicQuestions.map(data => {

            const matchTitle = data.title?.toLowerCase().replace(/[/?]+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];


            if (matchTitle === "do_you_have_driving_license" && data.options) {
              return { ...data, value: attributeValue ? "Yes" : "No", table_id: editClientDetail.id };
            }

            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {
              const match = lovsAnswerData?.find((e) => e.attributes.input_name.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_') === matchTitle);

              if (match) {
                return { ...data, value: match?.attributes.selected_option_value, table_id: match?.id };
              }
            }

            return data;
          });
          setDynamicQuestions(mappedInputs);
        }
      }

      if (categoryTitle === "Emergency Contact Information") {
        if (dynamicInputs !== null) {
          const mappedInputs = dynamicInputs.map(data => {

            const matchTitle = data.title?.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];


            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {
              const match = lovsAnswerData?.find((e) => e.attributes.input_name.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_') === matchTitle);
              if (match) {
                return { ...data, value: match?.attributes.selected_option_value, table_id: match?.id };
              }
            }

            return data;
          });

          setDynamicInputs(mappedInputs);
        }
        if (dynamicQuestions !== null) {
          const mappedInputs = dynamicQuestions.map(data => {

            const matchTitle = data.title?.toLowerCase().replace(/[/?]+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];


            if (matchTitle === "do_you_have_driving_license" && data.options) {
              return { ...data, value: attributeValue ? "Yes" : "No", table_id: editClientDetail.id };
            }

            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {
              const match = lovsAnswerData?.find((e) => e.attributes.input_name.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_') === matchTitle);

              if (match) {
                return { ...data, value: match?.attributes.selected_option_value, table_id: match?.id };
              }
            }

            return data;
          });
          setDynamicQuestions(mappedInputs);
        }
      }

      if (categoryTitle === "Other Information") {
        if (dynamicInputs !== null) {
          const mappedInputs = dynamicInputs.map(data => {

            const matchTitle = data.title?.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];


            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {
              const match = lovsAnswerData?.find((e) => e.attributes.input_name.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_') === matchTitle);
              if (match) {
                return { ...data, value: match?.attributes.selected_option_value, table_id: match?.id };
              }
            }

            return data;
          });

          setDynamicInputs(mappedInputs);
        }
        if (dynamicQuestions !== null && dynamicQuestionAnswers !== null) {
          const mappedInputs = dynamicQuestions.map(data => {
            const matchTitle = data.title?.toLowerCase().replace(/[/?]+/g, '').replace(/\s+/g, '_');
            let matchedAnswer = dynamicQuestionAnswers.find(item => item.attributes.question_title.toLowerCase().replace(/[/?]+/g, '').replace(/\s+/g, '_') === matchTitle)
            const attributeValue = matchedAnswer.attributes.answer_type[0].drop_down_option_lovs.data[0].attributes.title

            if (matchTitle === "do_you_have_driving_license" && data.options) {
              return { ...data, value: attributeValue ? "Yes" : "No", table_id: editClientDetail.id };
            }

            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {

              if (matchedAnswer) {
                return { ...data, value: attributeValue, table_id: matchedAnswer?.id };
              }
            }
            return data;
          });
          setDynamicQuestions(mappedInputs);
        }
      }

      if (categoryTitle === "Payment Details") {
        
        if (dynamicInputs !== null) {
          const mappedInputs = dynamicInputs.map(data => {

            const matchTitle = data.title?.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];


            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {
              const match = lovsAnswerData?.find((e) => e.attributes.input_name.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_') === matchTitle);
              if (match) {
                return { ...data, value: match?.attributes.selected_option_value, table_id: match?.id };
              }
            }

            return data;
          });

          setDynamicInputs(mappedInputs);
        }
        if (dynamicQuestions !== null && dynamicQuestionAnswers !== null) {
          const mappedInputs = dynamicQuestions.map(data => {
            const matchTitle = data.title?.toLowerCase().replace(/[/?]+/g, '').replace(/\s+/g, '_');
            let matchedAnswer = dynamicQuestionAnswers.find(item => item.attributes.question_title.toLowerCase().replace(/[/?]+/g, '').replace(/\s+/g, '_') === matchTitle)
            const attributeValue = matchedAnswer.attributes.answer_type[0].drop_down_option_lovs.data[0].attributes.title

            if (matchTitle === "do_you_have_driving_license" && data.options) {
              return { ...data, value: attributeValue ? "Yes" : "No", table_id: editClientDetail.id };
            }

            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {

              if (matchedAnswer) {
                return { ...data, value: attributeValue, table_id: matchedAnswer?.id };
              }
            }
            return data;
          });
          setDynamicQuestions(mappedInputs);
        }
        else if (dynamicQuestions !== null && dynamicQuestionAnswers === null) {

          const mappedInputs = dynamicQuestions.map(data => {
            const matchTitle = data.title?.toLowerCase().replace(/[/?]+/g, '').replace(/\s+/g, '_');

            let matchedAnswer = editClientDetail.attributes["tax_free_threshold"]
            const attributeValue = matchedAnswer ? "Yes" : "No";

            if (matchTitle === "do_you_have_driving_license" && data.options) {
              return { ...data, value: attributeValue ? "Yes" : "No", table_id: editClientDetail.id };
            }

            if (matchTitle === "ndis_expire_date" && !data.options) {
              return { ...data, value: editClientDetail.attributes?.["registration_expiry_date"], table_id: editClientDetail.id }
            }

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }
            else if (data.options) {

              if (matchedAnswer) {
                return { ...data, value: attributeValue, table_id: editClientDetail?.id };
              }
            }
            return data;
          });
          setDynamicQuestions(mappedInputs);

        }
      }
    }
    else if (isDataAvailable(editClientDetail) && !isDataAvailable(lovsAnswerData)) {
      if (categoryTitle === "General Information (Staff)") {
        if (dynamicInputs !== null) {
          const mappedInputs = dynamicInputs.map(data => {
            const matchTitle = data.title?.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }

            return data;
          });

          setDynamicInputs(mappedInputs);
        }
      }

      if (categoryTitle === "Contact Information") {
        if (dynamicInputs !== null) {
          const mappedInputs = dynamicInputs.map(data => {
            const matchTitle = data.title?.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }

            return data;
          });

          setDynamicInputs(mappedInputs);
        }
      }

      if (categoryTitle === "Emergency Contact Information") {
        if (dynamicInputs !== null) {
          const mappedInputs = dynamicInputs.map(data => {
            const matchTitle = data.title?.toLowerCase().replace(/\/\s+/g, '').replace(/\s+/g, '_');
            const attributeValue = editClientDetail.attributes?.[matchTitle];

            if (attributeValue && !data.options) {
              return { ...data, value: attributeValue, table_id: editClientDetail.id };
            }

            return data;
          });

          setDynamicInputs(mappedInputs);
        }
      }
    }
  }, [editClientDetail, lovsAnswerData])

  const updateDataWithApiByCategoryManual = async (data) => {
    try {

      if (categoryTitle === "General Information (Staff)" && _history) {
        dispatch(setLoadingState("loading"));

        // Clone and modify data
        const modifiedData = data.map((item) =>
          item.title === "NDIS Expire Date"
            ? { ...item, title: "registration_expiry_date" }
            : item
        );

        const simplify = convertDataIntoSimplifyObjects(modifiedData);
        simplify.inputs["do_you_have_driving_license"] = simplify.selectedOptions["do_you_have_driving_license?"].title === "Yes";
        delete simplify.selectedOptions["do_you_have_driving_license?"];
        simplify.inputs["do_you_have_driving_license"]
        ? (simplify.inputs["driving_license"] = simplify.inputs["driving_license"])
        : simplify.inputs["driving_license"] = null;
        
        
        console.log('simplify 1056', simplify);
        // Update staff details
        const response = await updateStaffDetails({
          variables: {
            id: _history,
            data: simplify.inputs,
          },
        });

        if (response?.data) {
          // Process each option in simplify.selectedOptions
          const updatePromises = Object.keys(simplify.selectedOptions).map((key) => {
            const { table_id, title } = simplify.selectedOptions[key];
            const selectedValue = title;

            return updateStaffAnsLov({
              variables: {
                id: Number(table_id),
                data: { selected_option_value: selectedValue },
              },
            });
          });

          // Await all updates
          const results = await Promise.all(updatePromises);
          const successfulUpdates = results.filter((res) => res?.data).length;

          // Confirm all updates succeeded
          if (successfulUpdates === Object.keys(simplify.selectedOptions).length) {
            dispatch(setLoadingState("completed"));
            handleSnacksApper(
              "Staff Onboarding",
              `${categoryTitle} has been updated`,
              "success"
            );
          }
        }
      }

      if (categoryTitle === "Contact Information" && _history) {
        dispatch(setLoadingState("loading"));

        const simplify = convertDataIntoSimplifyObjects(data);

        if (editClientDetail !== null) {
          // Update staff details
          if (editClientDetail?.id) {
            const response = await apiUpdateContactDetail({
              variables: {
                id: editClientDetail?.id,
                data: simplify.inputs,
              },
            });

            if (response?.data) {
              // Process each option in simplify.selectedOptions
              const updatePromises = Object.keys(simplify.selectedOptions).map((key) => {
                const { table_id, title } = simplify.selectedOptions[key];

                return updateStaffAnsLov({
                  variables: {
                    id: Number(table_id),
                    data: {
                      selected_option_value: title,
                    },
                  },
                });
              });

              // Await all updates
              const results = await Promise.all(updatePromises);
              const successfulUpdates = results.filter((res) => res?.data).length;

              // Confirm all updates succeeded
              if (successfulUpdates === Object.keys(simplify.selectedOptions).length) {
                dispatch(setLoadingState("completed"));
                handleSnacksApper(
                  "Staff Onboarding",
                  `${categoryTitle} has been updated`,
                  "success"
                );
              }
            }
          }
        }
        else {
          const response = await apiCreateStaffContact({
            variables: {
              ...simplify.inputs,
              staff_detail_id: _history,
            }
          })
          if (response?.data) {
            const id = response?.data?.createContactDetail?.data?.id;
            let count = 0;
            for (const key in simplify.selectedOptions) {
              // creating object for mutation
              const objectLov = {
                staff_detail_id: _history,
                module: "Staff Onboard",
                module_Id: Number(id),
                category: categoryTitle,
                input_name: key,
                selected_option_value: simplify.selectedOptions[key]?.title
              }
              // seding data to database with mutation
              const lovRespose = await apiCreateStaffAnsLov({
                variables: objectLov
              })
              if (lovRespose?.data) {
                count++
              }
            }
            if (count === Object.keys(simplify.selectedOptions).length) {
              // handle messages
              dispatch(setLoadingState('completed'))
              getClientDetailsById(_history)
              handleSnacksApper("Staff Onboarding", categoryTitle + " has been created", "success")
            }
          }
        }
      }

      if (categoryTitle === "Emergency Contact Information" && _history) {
        dispatch(setLoadingState("loading"));

        const simplify = convertDataIntoSimplifyObjects(data);

        if (editClientDetail !== null) {
          // Update staff details
          if (editClientDetail?.id) {
            const response = await updateEmergencyContactDetail({
              variables: {
                id: editClientDetail?.id,
                data: simplify.inputs,
              },
            });

            if (response?.data) {
              // Process each option in simplify.selectedOptions
              const updatePromises = Object.keys(simplify.selectedOptions).map((key) => {
                const { table_id, title } = simplify.selectedOptions[key];

                return updateStaffAnsLov({
                  variables: {
                    id: Number(table_id),
                    data: {
                      selected_option_value: title,
                    },
                  },
                });
              });

              // Await all updates
              const results = await Promise.all(updatePromises);
              const successfulUpdates = results.filter((res) => res?.data).length;

              // Confirm all updates succeeded
              if (successfulUpdates === Object.keys(simplify.selectedOptions).length) {
                dispatch(setLoadingState("completed"));
                handleSnacksApper(
                  "Staff Onboarding",
                  `${categoryTitle} has been updated`,
                  "success"
                );
              }
            }
          }
        }

        else {
          dispatch(setLoadingState('loading'))
          const simplify = convertDataIntoSimplifyObjects(data);
          simplify.inputs.staff_detail_id = _history;

          // seding data to database with mutation
          const response = await apiCreateStaffEmergency({
            variables: simplify.inputs
          })

          // handle response for basic info
          if (response?.data) {

            // handle messages
            dispatch(setLoadingState('completed'));
            getClientDetailsById(_history)
            handleSnacksApper("Staff Onboarding", categoryTitle + " has been created", "success")
          }
        }
      }

      if (categoryTitle === "Other Information" && _history) {

        dispatch(setLoadingState('loading'))
        let count = 0;
        const simplify = convertDataIntoSimplifyObjects(data);


        const response = await updateStaffDetails({
          variables: {
            "id": _history,
            "data": simplify.selectedOptions["do_you_have_any_allergies?"].title === "No" ? { allergy_detail: "" } : simplify.inputs
          }
        });

        if (response) {
          if (dynamicQuestionAnswers !== null) {
            const filteredData = data.filter(item => item.title !== "Allergy Detail");
            for (const key1 in filteredData) {
              const filter = filteredData[key1]?.options?.find((e) => e.attributes.title === filteredData[key1]?.value);
              const objectQuestion = {
                "staff_detail_id": _history,
                "module": "Staff Onboarding",
                "category": categoryTitle,
                "question_title": filteredData[key1]?.title,
                "answer_type": [
                  {
                    "__typename": "ComponentAnswersSingleAnswer",
                    "__component": "answers.single-answer",
                    "active": true,
                    "drop_down_option_lovs": filter?.id
                  }
                ]
              }
              // seding data to database with mutation
              const lovRespose = await updateStaffQuestionAnswerRef({
                variables: {
                  id: Number(filteredData[key1].table_id),
                  data: objectQuestion,
                },
              })
              if (lovRespose?.data) {
                count++
              }
            }
            if ((count + 1) === data.length) {
              // handle messages
              dispatch(setLoadingState('completed'));
              handleSnacksApper("Staff Onboarding", categoryTitle + " has been updated, id is: " + _history, "success")
            }
          }
          else {

            dispatch(setLoadingState('loading'))
            let count = 0;
            const simplify = convertDataIntoSimplifyObjects(data);

            const response = await updateStaffDetails({
              variables: {
                "id": _history,
                "data": simplify.inputs
              }
            });

            if (response) {
              const filteredData = data.filter(item => item.title !== "Allergy Detail");

              for (const key1 in filteredData) {
                const filter = filteredData[key1]?.options?.find((e) => e.attributes.title === filteredData[key1]?.value);
                const objectQuestion = {
                  "staff_detail_id": _history,
                  "module": "Staff Onboarding",
                  "category": categoryTitle,
                  "question_title": filteredData[key1]?.title,
                  "answer_type": [
                    {
                      "__typename": "ComponentAnswersSingleAnswer",
                      "__component": "answers.single-answer",
                      "active": true,
                      "drop_down_option_lovs": filter?.id
                    }
                  ]
                }
                // // // // // seding data to database with mutation
                const lovRespose = await apiCreateStaffStaffQuestion({
                  variables: objectQuestion
                })
                if (lovRespose?.data) {
                  count++
                }
              }
              if ((count + 1) === data.length) {
                /// // // // handle messages
                dispatch(setLoadingState('completed'));
                getClientDetailsById(_history);
                handleSnacksApper("Staff Onboarding", categoryTitle + " has been created, id is: " + _history, "success")
              }
            }
          }
        }

        else {
          dispatch(setLoadingState('loading'))
          let count = 0;
          const simplify = convertDataIntoSimplifyObjects(data);

          const response = await updateStaffDetails({
            variables: {
              "id": _history,
              "data": simplify.inputs
            }
          });

          if (response) {
            const filteredData = data.filter(item => item.title !== "Allergy Detail");

            for (const key1 in filteredData) {
              const filter = filteredData[key1]?.options?.find((e) => e.attributes.title === filteredData[key1]?.value);
              const objectQuestion = {
                "staff_detail_id": _history,
                "module": "Staff Onboarding",
                "category": categoryTitle,
                "question_title": filteredData[key1]?.title,
                "answer_type": [
                  {
                    "__typename": "ComponentAnswersSingleAnswer",
                    "__component": "answers.single-answer",
                    "active": true,
                    "drop_down_option_lovs": filter?.id
                  }
                ]
              }

              // seding data to database with mutation
              const lovRespose = await apiCreateStaffStaffQuestion({
                variables: objectQuestion
              })
              if (lovRespose?.data) {
                count++
              }
            }
            if ((count + 1) === data.length) {
              // handle messages
              dispatch(setLoadingState('completed'));
              getClientDetailsById(_history)
              handleSnacksApper("Staff Onboarding", categoryTitle + " has been created, id is: " + _history, "success")
            }
          }
        }
      }

      if (categoryTitle === "Payment Details" && _history) {
        dispatch(setLoadingState('loading'))

        if (editClientDetail !== null && lovsAnswerData !== null) {
          const simplify = convertDataIntoSimplifyObjects(data);
          const reason_notes = data?.find((e) => e.title.includes("Reason / Notes"))?.value;

          const object = {
            ...simplify.inputs,
            reason_notes: simplify.selectedOptions["do_you_want_to_claim_a_tax_free_threshold?"].title === "No" ? simplify.inputs.reason_notes : "",
            tax_free_threshold: simplify.selectedOptions["do_you_want_to_claim_a_tax_free_threshold?"].title === "Yes"
          }
          if (!object.tax_free_threshold) {
            if (reason_notes) {
              object.reason_notes = reason_notes;
            } else {
              throw { message: "Please define the reason.!", code: 141 }
            }
          }

          if (editClientDetail?.id) {
            const response = await updateStaffPaymentDetail({
              variables: {
                "id": editClientDetail?.id,
                "data": object,
              }
            });
            if (response?.data) {
              dispatch(setLoadingState("completed"));
              handleSnacksApper("Staff Onboarding", `${categoryTitle} has been updated`, "success");
            }
          }
        }
        else {
          const reason_notes = data?.find((e) => e.title.includes("Reason / Notes"))?.value;
          const object = {
            account_name: data?.find((e) => e.title.includes("Account Name"))?.value,
            bsb_number: data?.find((e) => e.title.includes("BSB Number"))?.value,
            account_number: data?.find((e) => e.title.includes("Account Number"))?.value,
            superannuation_name: data?.find((e) => e.title.includes("Superannuation Name"))?.value,
            member_number: data?.find((e) => e.title.includes("Member Number"))?.value,
            tax_file_number: data?.find((e) => e.title.includes("Tax File Number"))?.value,
            tax_free_threshold: data?.find((e) => e.title.includes("Do you want to claim a tax free threshold?"))?.value === "Yes" ? true : false,
            staff_detail_id: _history
          }
          if (!object.tax_free_threshold) {
            if (reason_notes) {
              object.reason_notes = reason_notes;
            } else {
              throw { message: "Please define the reason.!", code: 141 }
            }
          }
          // // seding data to database with mutation
          const response = await apiCreatePaymentData({
            variables: object
          })
          // / // // handle response for basic info
          if (response?.data) {
            const id = response?.data?.createStaffPaymentDetail?.data?.id;
            dispatch(setLoadingState('completed'));
            getClientDetailsById(_history);
            handleSnacksApper("Staff Onboarding", categoryTitle + " has been created", "success")
          }
        }
      }

      if (categoryTitle === "Hourly Rate" && _history) {
        dispatch(setLoadingState('loading'));

        if (selectedSchadAward !== null) {
          // Filter schadsAwards once
          const selectedAward = schadsAwards.find(schadsAward => schadsAward.level === selectedSchadAward);

          // Simplify data transformation with reduce       
          const simplify = Array.isArray(data) && data.length > 0
            ? data.reduce((acc, val) => {
              const key = `${val.day_type?.replaceAll(' ', '_').toLowerCase()}_rate`;
              acc[key] = val.total_hourly_rate.toString();
              return acc;
            }, {})
            : {};


          // Add schads_awards to simplify
          if (selectedAward) {
            simplify.schads_awards = selectedAward.schad_award_id;
          }

          // Update staff details
          const response = await updateStaffDetails({
            variables: {
              id: _history,
              data: simplify,
            },
          });

          if (response?.data) {
            dispatch(setLoadingState('completed'));
            handleSnacksApper("Staff Onboarding", `${categoryTitle} has been Updated`);
          }
        }
        else {
          dispatch(setLoadingState('loading'))

          // Filter schadsAwards once
          const selectedAward = schadsAwards.find(schadsAward => schadsAward.level === selectedSchadAward);

          // Simplify data transformation with reduce       
          const simplify = Array.isArray(data) && data.length > 0
            ? data.reduce((acc, val) => {
              const key = `${val.day_type?.replaceAll(' ', '_').toLowerCase()}_rate`;
              acc[key] = val.total_hourly_rate.toString();
              return acc;
            }, {})
            : {};


          // Add schads_awards to simplify
          if (selectedAward) {
            simplify.schads_awards = selectedAward.schad_award_id;
          }

          // Update staff details
          const response = await updateStaffDetails({
            variables: {
              id: _history,
              data: simplify,
            },
          });


          if (response?.data) {
            dispatch(setLoadingState('completed'));
            getClientDetailsById(_history);
            handleSnacksApper("Staff Onboarding", _history + " has been Updated");
          }
        }
      }

      if (categoryTitle === "Upload Documents" && _history) {
        dispatch(setLoadingState('loading'))
        const data = _.cloneDeep(listOfSelectedDoc);

        if (data) {
          let count = 0;
          for (const key in data) {
            if (!data[key].attributes.document_link) {
              const formData = new FormData();
              formData.append('files', data[key].attributes.file);
              const response = await axios.post(
                `${API}/upload`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${AUTH_TOKEN}`, // Add Authorization header
                  },
                }
              );

              if (response?.data) {
                const { name, url, mime } = response?.data[0];
                const createDocumentLinkResponse = await createDocumentLink({
                  variables: {
                    file_name: name,
                    file_type: mime,
                    file_url: API.replace("/api", "") + url,
                    staff_details_id: _history,
                  }
                })
                let docId = createDocumentLinkResponse?.data.createDocumentLink.data.id;

                const object = {
                  module: data[key].attributes.module,
                  type: data[key].attributes.type,
                  document_name: data[key].attributes.document_name,
                  staff_details_id: _history,
                  expiry_date: data[key].attributes.expiry_date,
                  active: true,
                  documents_description: data[key].attributes.description,
                  document_link: docId,
                }
                const responseDoc = await apiDocumentsList({
                  variables: object
                })
                if (responseDoc?.data) {
                  count++;
                }
                if (count === data.length) {
                  // display message
                  dispatch(setLoadingState('completed'))
                  await getClientDetailsById(_history)
                  handleSnacksApper("Staff Onboarding", categoryTitle + " has been created")
                }
              }
            }
          }
        }
      }

    } catch (error) {
      console.error("Error updating staff details:", error);
      dispatch(setLoadingState("error"));
      handleSnacksApper(
        "Error",
        "An error occurred while updating staff details.",
        "error"
      );
    }
  };

  const savingDataAsPerCategory = () => {
    let errorStatus = false;
    if (dynamicInputs) {
      let copy = [...dynamicInputs];
      const todayDate = new Date().getMonth() + "/" + new Date().getDate() + "/" + new Date().getFullYear();
      for (const key in copy) {
        if (
          (copy[key].type.toLowerCase() === "email" && copy[key].required && !validateEmail(copy[key].value)) ||
          (copy[key].type.toLowerCase() === "text" && copy[key].required && (copy[key].value === "" || copy[key].value === " ")) ||
          (copy[key].type.toLowerCase() === "date" && copy[key].required && (copy[key].value === "" || copy[key].value === todayDate)) ||
          (copy[key].type.toLowerCase() === "number" && copy[key].required && (copy[key].value === "" || copy[key].value < 10)) ||
          (copy[key].type.toLowerCase() === "time" && copy[key].required && (copy[key].value === ""))
        ) {
          copy[key].error = "Required";
          errorStatus = true;
          setDynamicInputs(copy);
        }
      }
    }

    if (errorStatus === false && dynamicQuestions !== undefined && dynamicQuestions !== null) {
      const fCopy = _.cloneDeep(dynamicInputs);
      const fCopy2 = _.cloneDeep(dynamicQuestions);
      if (_history) {
        updateDataWithApiByCategoryManual([...fCopy, ...fCopy2])
      }
    }

    else if (errorStatus === false && !dynamicQuestions) {
      const fCopy = _.cloneDeep(dynamicInputs);
      if (_history) {
        updateDataWithApiByCategoryManual(fCopy)
      }
    }
    if (selectedSchadAward) {
      if (_history) {
        updateDataWithApiByCategoryManual(schadsAwards?.filter(val => (val.level === selectedSchadAward))[0]?.rates_per_hour)
      }
    }
  }

  const deleteDocumentFromBackend = async (document) => {
    let documentId = document.attributes.document_link.data.id
    const DocumentLinkDeleteresponse = await deleteDocumentLink({
      variables: {
        id: documentId, // Pass the document ID to the mutation
      },
    });
    if (DocumentLinkDeleteresponse?.data) {
      let professionalListDocumentId = document.id;

      const DocumentsProfessionalListDeleteresponse = await deleteDocumentsProfessionalList({
        variables: {
          id: professionalListDocumentId, // Pass the document ID to the mutation
        },
      });
      if (DocumentsProfessionalListDeleteresponse?.data) {
        await getClientDetailsById(_history)
        handleSnacksApper("Document has been deleted successfully", "success")
      }
    }
  }

  const deleteDocument = async (index) => {
    // Create a shallow copy of the current document list
    const copy = [...listOfSelectedDoc];

    // Filter out the document by index
    const remainingDocuments = copy.filter((_, docIndex) => docIndex !== index);

    // Find the document to be deleted using the index
    const documentToBeDeleted = copy[index];

    // Check if the document exists in the backend (has an id)
    if (documentToBeDeleted && documentToBeDeleted.id) {
      // Call the backend API to delete the document
      await deleteDocumentFromBackend(documentToBeDeleted)
    } else {
      // Handle deletion for documents not yet saved to the backend
      setListOfSelectedDoc(remainingDocuments); // Update the state
    }
  }

  return (
    <Box sx={{ width: "100%", display: 'flex', flexDirection: "column" }}>
      {loading_doc_list || loading_update ? (
        <Box sx={{
          background: "rgba(0,0,0,0.3)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <CircularProgress color="primary" size={35} />
        </Box>
      ) : null}
      <List sx={{ width: '95%', maxWidth: window.screen.width, bgcolor: 'background.paper' }}>
        {listOfSelectedDoc?.map((value, index) => {
          return (
            <ListItem
              sx={{ m: 1, }}
              key={value?.attributes.document_name + index}
              component={Paper}
              elevation={1}
              secondaryAction={
                <Stack direction={"row"} spacing={2}>
                  <Button disabled={true} sx={{ textTransform: "capitalize", color: "red", ":disabled": { color: "gray" } }}>
                    {value?.attributes.module}
                  </Button>
                  <Button disabled={true} sx={{ textTransform: "capitalize", color: "red", ":disabled": { color: "gray" } }}>
                    {value?.attributes.category}
                  </Button>
                  <Button disabled={true} sx={{ textTransform: "capitalize", color: "red", ":disabled": { color: "gray" } }}>
                    {value?.attributes.type}
                  </Button>
                  <IconButton
                    onClick={() => {
                      const fileUrl = value?.attributes.document_link.data.attributes.file_url;
                      if (fileUrl) {
                        window.open(fileUrl, "_blank");
                      }
                    }}
                    edge="end"
                    aria-label="open-link"
                  >
                    <VisibilityIcon />
                  </IconButton>

                  <IconButton
                    onClick={async () => {
                      await deleteDocument(index)
                    }}
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              }
            >
              <ListItemIcon>
                {index + 1}
              </ListItemIcon>
              <ListItemText
                primary={value?.attributes.document_name}
                secondary={
                  <>
                    {value?.attributes.expiry_date && (
                      <Typography color={"seconadary.text1"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                        Expiry Date: {value?.attributes.expiry_date}
                      </Typography>
                    )}
                    {value?.attributes.description && (
                      <Typography color={"seconadary.text1"} sx={{ fontFamily: "Roboto-Regular", fontSize: "1em" }}>
                        Description: {value?.attributes.description}
                      </Typography>
                    )}
                  </>
                }
              />
            </ListItem>
          )
        })}
      </List>
      {/* document inputs with fields */}
      {listOfCategory?.length ? (
        <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
          <TextField
            select={true}
            label={"Please Select Document Type"}
            id={"Please Select Type"}
            htmlFor={"Please Select Type"}
            key={"Please Select Type 09"}
            required={true}
            sx={{ m: 1, width: screenWidth }}
            value={selectedDocType}
            onChange={(e) => {
              setSelectedDocType(e.target.value)
              setSelectedDocName(null)
            }}
          >
            {listOfCategory?.map((option) => (
              <MenuItem key={option?.title} value={option?.title}>
                {option?.title}
              </MenuItem>
            ))}
          </TextField>
          {selectedDocType && selectedDocType?.toLowerCase() !== "miscellaneous" && (
            <TextField
              select={true}
              label={"Please Select Document Name"}
              id={"Please Select Name"}
              htmlFor={"Please Select Name"}
              key={"Please Select Name 09"}
              required={true}
              sx={{ m: 1, width: screenWidth }}
              value={selectedDocName?.document_name}
              onChange={(e) => {
                const find = documentNames?.document_list?.find((en) => en.document_name === e.target.value);
                if (find) {
                  setSelectedDocName(find);
                }
              }}
            >
              {Array.isArray(documentNames?.document_list) && documentNames?.document_list?.map((option) => {
                return (
                  <MenuItem key={option.document_name} value={option.document_name}>
                    {option.document_name}
                  </MenuItem>
                )
              })}
            </TextField>
          )}
          {selectedDocType?.toLowerCase() === "miscellaneous" && (
            <TextField
              type={"text"}
              key={"Please write document name 09"}
              id={"Please write document name"}
              htmlFor={"Please write document name"}
              required={true}
              multiline={false}
              label={"Please write document name"}
              value={selectedDocName?.document_name}
              sx={{ m: 1, width: screenWidth }}
              onChange={(e) => {
                const copy = { ...documentNames?.document_list }
                copy.document_name = e.target.value
                setSelectedDocName(copy)
              }}
            />
          )}
          {selectedDocName && (
            <Button
              color={"primary"}
              key={"09"}
              sx={{
                m: 1,
                width: screenWidth,
                padding: "12px 12px",
                fontSize: "1.1em",
                bgcolor: "#ffffff",
                fontFamily: "Roboto-Medium",
                textTransform: "capitalize",
                color: "#000000",
                border: "2px solid #6ECA35",
                ":hover": {
                  border: "2px solid #6ECA35",
                }
              }}
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon color="primary" />}
            >
              {selectedDocName?.document_name?.length > 30 ? selectedDocName?.document_name?.slice(0, 30) + "..." : selectedDocName?.document_name}
              <VisuallyHiddenInput
                webkitdirectory
                key={"document-name-09"}
                type="file"
                onChange={(e) => {
                  createDocumentList(e.target.files[0])
                }}
              />
            </Button>
          )}
        </Box>
      ) : null}
      {/* inputs and select type */}
      {categoryTitle === 'Hourly Rate' && (
        <>
          <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', mb: 3 }}>
            <TextField
              select={true}
              label={"Select Schads Awards"}
              id={"Schads Awards"}
              htmlFor={"Schads Awards"}
              key={"Schads Awards"}
              required={true}
              sx={{ m: 1, width: '50%' }}
              value={selectedSchadAward}
              InputLabelProps={{
                shrink: selectedSchadAward !== null, // This will force the label to shrink
              }}
              onChange={(e) => setSelectedSchadAward(e.target.value)}
            >
              {schadsAwards?.map((val, index) => {
                return (
                  <MenuItem key={index} value={val.level}>{val.level}</MenuItem>
                )
              })}
            </TextField>
          </Box>
          <Box>
            {schadsAwards?.filter(val => (val.level === selectedSchadAward)).map((val, index) => {
              return (
                val.rates_per_hour?.map(value => {
                  return (
                    <TextField
                      type={'number'}
                      key={value.day_type + index}
                      required={value.required}
                      label={`${value.day_type} Rates`}
                      disabled={true}
                      value={value.total_hourly_rate?.toFixed(2) ?? ''}
                      sx={{ m: 1 }}
                    />
                  )
                })
              )
            })}
          </Box>
        </>
      )}
      <Box sx={{ width: "100%", display: 'flex', flexWrap: 'wrap', }}>
        {dynamicInputs?.map((value, index) => {
          if ((value?.type?.toLowerCase() === "text" || value?.type?.toLowerCase() === "email" || value?.type?.toLowerCase() === "number" || value?.type?.toLowerCase() === "date" || value?.type?.toLowerCase() === "time") && !value.title?.includes("Reason") && !value.title?.includes("Card") && !value.title?.includes("Allergy Detail") && !value.title?.includes("Driving License")) {
            return (
              <TextField
                type={value.type}
                key={value.title + index}
                id={value.title}
                htmlFor={value.title}
                required={value.required}
                multiline={value.multiline}
                label={value.title}
                disabled={value.disabled}
                placeholder={value.placeholder}
                value={value.value}
                sx={{ m: 1, width: value.width }}
                helperText={value.error ? value.error : ""}
                error={value.error ? true : false}
                onChange={(e) => handleChangeValueInputs(e, index)}
                shrink={value?.type?.toLowerCase() === "date"}
              />
            )
          }
          if (value?.type?.toLowerCase() === "select") {
            return (
              <TextField
                select={true}
                label={value.title}
                id={value.title}
                htmlFor={value.title}
                key={value.title + index}
                required={value.required}
                sx={{ m: 1, width: value.width }}
                value={value.value}
                helperText={value.error ? value.error : ""}
                error={value.error ? true : false}
                onChange={(e) => handleChangeValueInputs(e, index)}
              >
                {value.options.map((option) => (
                  <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                    {option?.attributes?.title}
                  </MenuItem>
                ))}
              </TextField>
            )
          }
        })}
        {/* Question answer */}
        {dynamicQuestions?.map((v, index) => {
          if (v?.active && v?.type?.includes("Text")) {
            return (
              <TextField
                type={v?.type?.toLowerCase()}
                key={v.title + index}
                id={v.title}
                htmlFor={v.title}
                required={v.required}
                multiline={v.multiline}
                label={v.title}
                value={v.value}
                sx={{ m: 1, width: v.width }}
                onChange={(e) => {
                  const copy = [...dynamicQuestions];
                  copy[index].value = e.target.value;
                  setDynamicQuestions(copy);
                }}
              />
            )
          }
          if (v?.active && v?.type?.includes("Select")) {
            return (
              <>
                <TextField
                  select={true}
                  label={v.title}
                  key={v.title + index}
                  id={v.title}
                  htmlFor={v.title}
                  required={v.required}
                  sx={{ m: 1, width: v.width }}
                  value={v.value}
                  onChange={(e) => {
                    const copy = [...dynamicQuestions]
                    copy[index].value = e.target.value;
                    setDynamicQuestions(copy);
                  }}
                >
                  {v?.options.map((option) => (
                    <MenuItem key={option?.attributes?.title} value={option?.attributes?.title}>
                      {option?.attributes?.title}
                    </MenuItem>
                  ))}
                </TextField>
                {(v.title?.includes("medical card") && v.value === "Yes") && (<DependentInput data={dynamicInputs} match={"Card"} handleChangeValueInputs={handleChangeValueInputs} />)}
                {(v.title?.includes("driving license") && v.value === "Yes") && (<DependentInput data={dynamicInputs} match={"Driving"} handleChangeValueInputs={handleChangeValueInputs} />)}
                {(v.title?.includes("claim a tax") && v.value === "No") && (<DependentInput data={dynamicInputs} match={"Reason"} handleChangeValueInputs={handleChangeValueInputs} />)}
                {(v.title?.includes("allergies") && v.value === "Yes") && (<DependentInput data={dynamicInputs} match={"Allergy Detail"} handleChangeValueInputs={handleChangeValueInputs} />)}
              </>
            )
          }
        })}
        <Box sx={{ width: "100%", display: 'flex', justifyContent: "flex-end", mr: "4%" }}>
          <Stack direction={"row"} spacing={2}>
            {/* {categoryTitle?.includes("Working Days (Start Time & End Time)") ? (
                            <Tooltip title="Can Change After Save" placement="left">
                                <Button color={"primary"} onClick={() => {
                                    savingDataAsPerCategory()
                                }}
                                    disabled={loading_staff || loading_update || loading_contact || loading_emergency || loading_question || loading_payment}
                                    variant="outlined" sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                                    Save
                                    {loading_staff || loading_update || loading_contact || loading_emergency || loading_question || loading_payment ? <CircularProgress color="secondary" size={26} /> : null}
                                </Button>
                            </Tooltip>
                            ) : null} */}
            <Tooltip title={"Can Add " + categoryTitle} placement="left">
              <Button
                color={"primary"}
                startIcon={<AddCircleOutlineOutlinedIcon color="primary" />}
                variant="outlined"
                sx={{ fontSize: "1.1em", bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}
                onClick={() => savingDataAsPerCategory()}
              >
                {'Submit'}
              </Button>
            </Tooltip>
          </Stack>
        </Box>
      </Box>
      <Snackbar open={snacks.open} autoHideDuration={5000} onClose={handleCloseSnacks} TransitionComponent={snacks.Transition} anchorOrigin={{ vertical: snacks.vertical, horizontal: snacks.horizontal }}>
        <Alert
          onClose={handleCloseSnacks}
          severity={errorSnackMessage.severity}
          variant={errorSnackMessage.variant}
          sx={{ width: '100%' }}
        >
          <AlertTitle>{errorSnackMessage.title}</AlertTitle>
          {errorSnackMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}