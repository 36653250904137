import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Slide, FormControl, InputLabel, Select, MenuItem, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import { GetInvoicingData, UpdateInvoiceStatus } from '../../../services/finance_service';
import CustomSnackBar from '../../../components/custom_snack_bar';

export const InvoiceLedger = () => {
    const [invoicings, setInvoicings] = React.useState(null);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState('success');
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogData, setDialogData] = React.useState({ row: null });
    const [invoicingType, setInvoicingType] = React.useState("");

    const [getInvoicingData, loading, error] = GetInvoicingData();
    const [updateInvoiceStatus] = UpdateInvoiceStatus();

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'invoiceTo',
            headerName: ' Invoice To',
            width: 150,
            editable: false,
        },
        {
            field: 'invoiceNumber',
            headerName: 'Invoice Number',
            width: 150,
            editable: false,
        },
        {
            field: 'invoicingStartDate',
            headerName: 'Supports Delivered From',
            width: 140,
            editable: false,
        },
        {
            field: 'invoicingEndDate',
            headerName: 'Supports Delivered To',
            width: 140,
            editable: false,
        },
        {
            field: 'supportNumber',
            headerName: 'Support Number',
            width: 150,
            editable: false,
        },
        {
            field: 'claimReference',
            headerName: 'Claim Reference',
            width: 150,
            editable: false,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 110,
            editable: false,
            renderCell: (params) => (
                `$${params.row.amount}`
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <Chip
                    label={params.row.status ? 'CLAIMED' : 'NOT CLAIMED'}
                    color={params.row.status ? "primary" : 'warning'}
                    sx={{ color: '#fff', fontWeight: 'bold' }}
                />
            ),
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={params?.row.status}
                        size="small"
                        sx={{ color: '#fff', fontWeight: 'bold' }}
                        onClick={() => handleOpenDialog(params)}
                    >
                        Approve
                    </Button>
                </Grid>
            ),
        }
    ];

    const handleChange = (event) => {
        setInvoicingType(event.target.value);
    }

    const handleOpenDialog = (params) => {
        setDialogData({ row: params.row });
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleConfirmUpdate = async () => {
        const { row } = dialogData;

        const response = await updateInvoiceStatus({ variables: { id: row.invoiceId, data: { claimed_status: true } } });
        if (response) {
            setSnackMessage('Success');
            setSnackSeverity('success');
            setSnackOpen(true);
            filteredInvoicingData();
        }
        handleCloseDialog();
    };

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const filteredInvoicingData = async () => {
        const filteredData = await getInvoicingData(invoicingType && {
            variables: {
                "filters": {
                    "service_request_id": {
                        "funding_source": {
                            "eq": invoicingType
                        }
                    }
                },
            }
        });

        if (filteredData) {
            setInvoicings(
                filteredData?.data?.invoicings?.data?.map((val, index) => {
                    return (
                        {
                            id: ++index,
                            invoiceId: val.id,
                            invoiceTo: val?.attributes?.service_request_id?.data?.attributes?.funding_source,
                            invoiceNumber: val?.attributes?.claim_reference,
                            invoicingStartDate: val?.attributes?.invoicing_start_date,
                            invoicingEndDate: val?.attributes?.invoicing_end_date,
                            supportNumber: val?.attributes?.service_request_id?.data?.attributes?.charge_band_detail_id?.data?.attributes?.ndis_price_list_id?.data?.attributes?.support_item_number,
                            claimReference: val?.attributes?.claim_reference,
                            amount: val?.attributes?.total_price,
                            status: val?.attributes?.claimed_status
                        }
                    )
                })
            )
        }
    }

    React.useEffect(() => {
        filteredInvoicingData()
    }, [invoicingType])

    return (
        <>
            <Grid container sx={{ marginLeft: 7 }}>
                <FormControl variant="outlined" style={{ margin: '1rem 0', width: '20%' }}>
                    <InputLabel id="invoicing-type-label">Select Invoicing Type</InputLabel>
                    <Select
                        labelId="invoicing-type-label"
                        value={invoicingType}
                        onChange={handleChange}
                        label="Select Invoicing Type"
                    >
                        <MenuItem value="" disabled>
                            Select Invoicing Type
                        </MenuItem>
                        <MenuItem value="NDIS Funded">NDIS Funded</MenuItem>
                        <MenuItem value="Self Funded">Self Funded</MenuItem>
                        <MenuItem value="Organization Funded">Organization Funded</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center", my: 2, marginBottom: { xs: "15%", sm: "2%", lg: "2%" } }}>
                <CustomSnackBar
                    open={snackOpen}
                    onClose={handleCloseSnack}
                    message={snackMessage}
                    title={snackSeverity === 'success' ? 'Success' : snackSeverity === 'warning' ? 'Information' : 'Error'}
                    severity={snackSeverity}
                    variant="filled"
                    autoHideDuration={4000}
                    transition={Slide}
                    vertical="top"
                    horizontal="right"
                />
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Confirm Action</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to claim this invoice?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="secondary" variant='contained'>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmUpdate} color="primary" variant='contained' sx={{ color: '#fff' }}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item={true} xs={11} sm={12} md={11} lg={11}>
                    <DataGrid
                        rows={invoicings}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        rowSelection={false}
                    />
                </Grid>
            </Grid>
        </>
    );
}
