import React from 'react'
import { Typography } from "@mui/material";

const NoDataAvailable = ({ message }) => {
    return (
        <Typography variant="p" component="p">
            {message}
        </Typography>
    )
}

export default NoDataAvailable