import { Avatar, Box, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddTaskIcon from '@mui/icons-material/AddTask';
import { useDispatch } from "react-redux";
import { shift_check_list } from "../../../middleware/reducers/service_request";
import { useSelector } from "react-redux";

export const ShiftsAndComments = ({ screenWidth }) => {
    const _client_info = useSelector((state) => state._service_request.information);

    const [shifts, setShifts] = useState([
        {
            title: "Shift Title",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: false
        },
        {
            title: "Shift Comments",
            value: "",
            width: screenWidth,
            type: "text",
            required: true,
            multiline: true
        },
    ])
    const dispatch = useDispatch()
    const [shifitTodo, setShiftToDo] = useState([]);

    const createNewShiftTodo = () => {
        const shiftsCopy = [...shifts];
        const copy = [...shifitTodo];
        copy.push({
            title: shifts[0].value,
            comment: shifts[1].value,
            completed: false,
        })
        setShiftToDo(copy)
        for (const key in shiftsCopy) {
            shiftsCopy[key].value = "";
        }
        setShifts(shiftsCopy)
    }

    useEffect(() => {
        const clientShifts = _client_info?.checkList?.map(val => {
            return ({
                title: val.attributes.check_list_title,
                comment: val.attributes.check_list_comments
            })
        });

        setShiftToDo(clientShifts)
    }, [])

    useEffect(() => {
        if (shifitTodo) {
            dispatch(shift_check_list(shifitTodo))
        }
    }, [shifitTodo])
    return (
        <Grid container>
            <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                <List sx={{ width: '97%', bgcolor: 'background.paper' }}>
                    {shifitTodo?.map((v, i) => {
                        return (
                            <ListItem component={Paper} elevation={1} key={i} sx={{ m: 1 }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {/* <AssignmentTurnedInIcon /> */}
                                        {i + 1}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={v.title} secondary={v.comment} primaryTypographyProps={{
                                    style: {
                                        textTransform: "capitalize",
                                        fontFamily: "Roboto-Medium"
                                    }
                                }} />
                            </ListItem>
                        )
                    })}
                </List>
            </Grid>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: "center" }}>
                {shifts.map((v, i) => {
                    return (
                        <div key={i}>
                            {v.type === "text" ? (
                                <TextField
                                    multiline={v.multiline}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    htmlFor={v.title}
                                    value={v.value}
                                    sx={{ m: 1, width: v.width }}
                                    onChange={(e) => {
                                        const copy = [...shifts];
                                        copy[i].value = e.target.value;
                                        setShifts(copy)
                                    }}
                                />
                            ) : null}
                        </div>
                    )
                })}
                <Button onClick={() => createNewShiftTodo()} component="label" startIcon={<AddTaskIcon />}
                    variant="outlined" sx={{ py: 1.6, bgcolor: "#ffffff", fontFamily: "Roboto-Medium", textTransform: "capitalize", color: "#000000", border: "2px solid #6ECA35", ":hover": { border: "2px solid #6ECA35", } }}>
                    Add Check List
                </Button>
            </Box>
        </Grid>
    )
}
