import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Calendar from "../../../components/common/Calendar"
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';


import NotificationModal from "../../../components/common/NotificationModal"; // Assume this is your modal component
import FilterTypeAutoComplete from "./components/FilterTypeAutoComplete"; // Custom autocomplete
import ClientFilter from "./ClientFilter"; // Custom client/staff filter
import dayjs from "dayjs";
import { filter } from "lodash";

const CustomCalendar = ({ servicePlans }) => {
    const [filterType, setFilterType] = useState([
        { type: "client", label: "Client" },
        { type: "staff", label: "Staff" },
    ]);

    const [selectedFilterType, setSelectedFilterType] = useState(null);
    const [clients, setClients] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [fetchedEvents, setFetchedEvents] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedStaffs, setSelectedStaffs] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [openEventsDetailsModal, setOpenEventsDetailsModal] = useState(false);
    const [eventDetailsObj, setEventDetailsObj] = useState({});

    // Extract unique clients and staff, and generate event data
    useEffect(() => {
        if (servicePlans?.length > 0) {
            const { clients: uniqueClients, staffs: uniqueStaffs } = servicePlans.reduce(
                (acc, curr) => {
                    const clientId = curr.client_detail.id;
                    const staffId = curr.staff_detail.id;

                    if (!acc.clientIds.has(clientId)) {
                        acc.clientIds.add(clientId);
                        acc.clients.push(curr.client_detail);
                    }

                    if (!acc.staffIds.has(staffId)) {
                        acc.staffIds.add(staffId);
                        acc.staffs.push(curr.staff_detail);
                    }

                    return acc;
                },
                { clientIds: new Set(), staffIds: new Set(), clients: [], staffs: [] }
            );

            const events = servicePlans.flatMap(({ service_request, shifts_data, client_detail, staff_detail }) => {
              
                const eventTitle = service_request?.service_type?.service_name;
                return shifts_data?.map(({ attributes }) => ({
                    title: eventTitle,
                    start: `${attributes.shift_date}T${attributes.shift_start}`,
                    end: `${attributes.shift_date}T${attributes.shift_end}`,
                    clientId: client_detail.id,
                    staffId: staff_detail.id,
                    client: client_detail,
                    staff: staff_detail,
                }));
            });

            setFetchedEvents(events);
            setFilteredEvents(events);
            setClients(uniqueClients);
            setStaffs(uniqueStaffs);
        }
    }, [servicePlans]);

    // Update filtered events based on selected filter type and selections
    useEffect(() => {
        if (fetchedEvents.length > 0) {
            if (selectedFilterType !== null) {
                if (selectedFilterType.type === "client") {
                    setFilteredEvents(
                        selectedClients.length > 0
                            ? fetchedEvents.filter(event => selectedClients.some(selectedClient => selectedClient.id === event.clientId))
                            : fetchedEvents
                    );
                }
                if (selectedFilterType.type === "staff") {
                    setFilteredEvents(
                        selectedStaffs.length > 0
                            ? fetchedEvents.filter(event => selectedStaffs.some(selectedStaff => selectedStaff.id === event.staffId))
                            : fetchedEvents
                    );
                }
            }
            if (selectedFilterType === null) {
                setSelectedClients([]);
                setSelectedStaffs([]);
                setFilteredEvents(fetchedEvents);
            }
        }
    }, [selectedFilterType, selectedClients, selectedStaffs, fetchedEvents]);

    useEffect(() => {
        if (selectedFilterType !== null) {
            if (selectedFilterType.type === "client") {
                setSelectedStaffs([]);
            }
            else if (selectedFilterType.type === "staff") {
                setSelectedClients([]);
            }
        }
    }, [selectedFilterType]);

    // Event details modal content
    const eventsDetailsModalContent = (
        <Box>
            <Typography color="primary" sx={{ fontFamily: "Roboto-Bold", fontSize: "1.5em" }}>
                {eventDetailsObj.title}
            </Typography>
            <Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography>Staff: {eventDetailsObj?.staff?.first_name} {eventDetailsObj?.staff?.last_name}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography>Client: {eventDetailsObj?.client?.first_name} {eventDetailsObj?.client?.last_name}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography>Start: {dayjs(eventDetailsObj?.start).format("DD MMM YYYY, hh:mm A")}</Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Typography>End: {dayjs(eventDetailsObj?.end).format("DD MMM YYYY, hh:mm A")}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Box sx={{ padding: "25px", height: "90vh" }}>
            <Grid container>
                <Grid item xs={12}>
                    <Box sx={{ padding: "25px", background: "#fff" }}>
                        <Box sx={{ paddingBottom: "10px", display: "flex", gap: "10px" }}>
                            <Box sx={{ width: "10%" }}>
                                <FilterTypeAutoComplete
                                    options={filterType}
                                    selectedOption={selectedFilterType}
                                    setSelectedOption={setSelectedFilterType}
                                    placeholder="Select Filter Type"
                                />
                            </Box>
                            {selectedFilterType && (
                                <Box sx={{ flex: 1 }}>
                                    <ClientFilter
                                        clients={selectedFilterType.type === "client" ? clients : staffs}
                                        setClients={
                                            selectedFilterType.type === "client"
                                                ? setSelectedClients
                                                : setSelectedStaffs
                                        }
                                        placeholder={`Filter by ${selectedFilterType.label}`}
                                        value={
                                            selectedFilterType.type === "client"
                                                ? selectedClients
                                                : selectedStaffs
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ height: "80vh" }}>
                            <Calendar
                                plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                                initialView="dayGridMonth"
                                weekends
                                events={filteredEvents}
                                firstDay={1}
                                headerToolbar={{
                                    left: "prev,today,next",
                                    center: "title",
                                    right: "dayGridMonth,dayGridWeek,timeGridDay,listWeek",
                                }}
                                eventClick={info => {
                                    const details = info?.event._def;
                                    setEventDetailsObj({
                                        title: details.title,
                                        start: info?.event.start,
                                        end: info?.event.end,
                                        clientId: details.clientId,
                                        staffId: info?.event.staffId,
                                        client: details.extendedProps.client,
                                        staff: details.extendedProps.staff
                                    });
                                    setOpenEventsDetailsModal(true);
                                }}
                                dayMaxEvents={true} // Limits events in day cells and shows "+X more" if exceeded
                                views={
                                    {
                                        dayGridMonth: {
                                            eventLimit: 1
                                        }
                                    }
                                }
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <NotificationModal
                content={eventsDetailsModalContent}
                openModalVariable={openEventsDetailsModal}
                setOpenModalVariable={setOpenEventsDetailsModal}
            />
        </Box>
    );
};

export default CustomCalendar;
