import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

export default function ClientFilter({ clients, setClients, value, placeholder}) {

    const handleDelete = (clientToDelete) => {        
        setClients((prev) => {
            if (prev.length > 0) {
                return prev.filter((client) => client.first_name !== clientToDelete.first_name);
            }
            else {
                return [];
            }
        });
    };

    const handleSelectionChange = (event, newValue) => {        
        setClients(newValue); // Update selected clients in the parent component
    };

    return (
        <Autocomplete
            multiple
            id="client-filter"
            options={clients}
            getOptionLabel={(option) => option.first_name}
            value={value}
            onChange={handleSelectionChange}
            renderTags={(selected, getTagProps) =>
                selected.map((client, index) => (
                    <Chip
                        key={client.id}
                        label={client.first_name}
                        {...getTagProps({ index })}
                        onDelete={() => handleDelete(client)}
                        size="small"
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    placeholder={placeholder}
                />
            )}
            disableCloseOnSelect
        />
    );
}
