import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Slide from '@mui/material/Slide';
import { UpdateChargeBandRate } from '../../../services/finance_service';
import CustomSnackBar from '../../../components/custom_snack_bar';

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'code', headerName: 'Code', width: 200 },
    { field: 'charge_band_name', headerName: 'Charge Band Name', width: 150 },
    { field: 'charge_band_type', headerName: 'Charge Band Type', width: 150 },
    { field: 'charge_band_year', headerName: 'Charge Band Year', width: 150 },
    { field: 'unit', headerName: 'Rate Type', width: 150 },
    { field: 'day_rate', headerName: 'Day Rate', type: 'number', width: 150, editable: true },
    { field: 'evening_rate', headerName: 'Evening Rate', type: 'number', width: 150, editable: true },
    { field: 'night_rate', headerName: 'Night Rate', type: 'number', width: 150, editable: true },
    { field: 'saturday_rate', headerName: 'Saturday Rate', type: 'number', width: 150, editable: true },
    { field: 'sunday_rate', headerName: 'Sunday Rate', type: 'number', width: 150, editable: true },
    { field: 'public_holiday_rate', headerName: 'Public Holiday Rate', type: 'number', width: 150, editable: true },
];

export default function ChargeBandsTable({ chargeBandsRate, getChargeBandsRates }) {
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [snackSeverity, setSnackSeverity] = React.useState('success');

    const [updateChargeBandsRates] = UpdateChargeBandRate();

    const filteredChargeBandsRates = chargeBandsRate?.map((val) => {
        const data = val.attributes?.charge_band_detail_id.data?.attributes;
   
        if (data) {
            return {
                id: val.id,
                charge_band_name: data.charge_band_name,
                charge_band_type: data.charge_band_type,
                charge_band_year: data.charge_band_year,
                unit: data.ndis_price_list_id.data.attributes.unit,
                code: data.ndis_price_list_id.data.attributes.support_item_number,
                day_rate: val.attributes.day_rate,
                evening_rate: val.attributes.evening_rate,
                night_rate: val.attributes.night_rate,
                public_holiday_rate: val.attributes.public_holiday_rate,
                saturday_rate: val.attributes.saturday_rate,
                sunday_rate: val.attributes.sunday_rate,
            };
        }
    });

    const handleCloseSnack = () => {
        setSnackOpen(false);
    };

    const handleProcessRowUpdate = async (updatedData, oldData) => {
        const rates = [
            updatedData.day_rate,
            updatedData.evening_rate,
            updatedData.night_rate,
            updatedData.saturday_rate,
            updatedData.sunday_rate,
            updatedData.public_holiday_rate,
        ];

        // Validate the rates
        const invalidRate = rates.find((rate) => rate == null || rate < 0);
        if (invalidRate !== undefined) {
            setSnackMessage('Invalid input! Rates cannot be null or negative.');
            setSnackSeverity('error');
            setSnackOpen(true);
            throw new Error('Validation Error');
        }
        if (JSON.stringify(updatedData) !== JSON.stringify(oldData)) {
            // Update logic
            const response = await updateChargeBandsRates({
                variables: {
                    "id": Number(updatedData.id),
                    "data": {
                        'day_rate': updatedData.day_rate,
                        'evening_rate': updatedData.evening_rate,
                        'night_rate': updatedData.night_rate,
                        'public_holiday_rate': updatedData.public_holiday_rate,
                        'saturday_rate': updatedData.saturday_rate,
                        'sunday_rate': updatedData.sunday_rate,
                    },
                },
            });

            if (response) {
                setSnackMessage('Updated Successfully');
                setSnackSeverity('success');
                await getChargeBandsRates();
                setSnackOpen(true);
            }
        }
        else {
            setSnackMessage('Nothing to update');
            setSnackSeverity('warning');
            setSnackOpen(true);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                width: '100%',
            }}
        >
            <CustomSnackBar
                open={snackOpen}
                onClose={handleCloseSnack}
                message={snackMessage}
                title={snackSeverity === 'success' ? 'Success' : snackSeverity === 'warning' ? 'Information' : 'Error'}
                severity={snackSeverity}
                variant="filled"
                autoHideDuration={4000}
                transition={Slide}
                vertical="top"
                horizontal="right"
            />
            {filteredChargeBandsRates &&
                <DataGrid
                    rows={filteredChargeBandsRates}
                    processRowUpdate={handleProcessRowUpdate}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    sx={{
                        flexGrow: 1,
                        '& .MuiDataGrid-row': {
                            backgroundColor: '#ffffff',
                        },
                        '& .MuiDataGrid-row:nth-of-type(odd)': {
                            backgroundColor: '#f9f9f9',
                        },
                        '& .MuiDataGrid-cell--editable': {
                            backgroundColor: '#fff',
                            color: '#000',
                            border: '1px solid #e0e0e0',
                            borderRadius: '4px',
                        },
                        '& .MuiDataGrid-cell:hover': {
                            backgroundColor: '#f1f1f1',
                        },
                    }}
                />
            }
        </Box>
    );
}
