import { Box, Button, Collapse, FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemText, MenuItem, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { Calendar } from "react-multi-date-picker"
import { Add, ExpandLess, ExpandMore } from "@mui/icons-material";
import _ from "lodash";
import moment from "moment";
import { WeekdayChecklist } from "./weekday-checklist";

const monthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const randomIds = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

const timeFields = [
    {
        title: "Start Time",
        value: "12:00",
        width: '100%',
        type: "time",
        required: true,
    },
    {
        title: "End Time",
        value: "12:00",
        width: '100%',
        type: "time",
        required: true,
    },
];

const finalObject = {
    recurring_type: "", // selected type add here
    selected_days: null, // it is going to be array of days with week-day and time
    x_day: 0, // it is the number of gap in selected days
    x_days: null, // it is the number of gap in selected days
    week_days: null, // week days data with yes/no
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
};

export const DaysInfo = ({ screenWidth, visitDaysData, setVisitDaysData }) => {
    const [selectedOption, setSelectedOption] = useState("");
    const [weeklyShiftStartTime, setWeeklyShiftStartTime] = useState(null);
    const [weeklyShiftEndTime, setWeeklyShiftEndTime] = useState(null);

    const [checkedDays, setCheckedDays] = useState({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
    });

    const [multipleTimeFields, setMultipleTimeFields] = useState([]);
    const [actualDates, setActualDates] = useState([]);
    const [datesList, setDatesList] = useState([
        {
            title: "Start Date",
            value: dayjs(new Date()),
            width: screenWidth,
            type: "date",
            required: true,
            disabled: false,
        },
        {
            title: "End Date",
            value: null,
            width: screenWidth,
            type: "date",
            required: false,
            disabled: false,
        },
    ]);
    const [continueWithoutEndDate, setContinueWithoutEndDate] = useState(false);
    const [continueWithoutEndDateWeekly, setContinueWithoutEndDateWeekly] = useState(false);
    const [continueShifts7Weeks, setContinueShifts7Weeks] = useState(null)
    const [xDaysNumber, setxDaysNumber] = useState(0);
    const [sameAsSelected, setSamAsSelected] = useState(true)

    // // // save date state
    const [serviceTimeOption, setServiceTimeOption] = useState([
        {
            title: "Recurring Type",
            value: "Please Select Type",
            width: screenWidth,
            type: "drop_down",
            required: true,
            disabled: false,
            options: [
                {
                    title: "Please Select Type",
                    selected: true
                },
                {
                    title: "Select Actual Dates",
                    selected: true
                },
                {
                    title: "Use Weekly Recurring",
                    selected: false
                },
                {
                    title: "Use Every X Days Recurring",
                    selected: false
                },
            ]
        },
        {
            title: "Every X Days Where X Is",
            value: 0,
            width: screenWidth,
            type: "number",
            required: true,
            disabled: false,
        },
    ]);

    const [workDays, setWorkDays] = useState([]);

    const [xDaysDuration, setxDaysDuration] = useState([
        {
            title: "Start Date",
            value: dayjs(new Date()),
            width: screenWidth,
            type: "date",
            required: true,
            disabled: false
        },
        {
            title: "End Date",
            value: null,
            width: screenWidth,
            type: "date",
            required: false,
            disabled: false
        },
        {
            title: "Start Time",
            value: "12:00",
            width: screenWidth,
            type: "time",
            required: true,
            disabled: false
        },
        {
            title: "End Time",
            value: "12:00",
            width: screenWidth,
            type: "time",
            required: false,
            disabled: false
        }
    ])

    const handleClick = (index) => {
        // setOpen(!open);
        const copy = [...multipleTimeFields];
        copy[index].collapse = !copy[index].collapse
        setMultipleTimeFields(copy);
    };

    // Handle change when a checkbox is clicked
    const handleChange = (event) => {
        setCheckedDays({
            ...checkedDays,
            [event.target.name]: event.target.checked,
        });
    };

    const generateXDayObjects = (startDate, endDate, startTime, endTime, xDayInterval) => {
        const result = [];
        const currentDate = new Date(startDate);
        const endDateObj = new Date(endDate);

        while (currentDate <= endDateObj) {
            // Format date and time
            const dateString = moment(currentDate).format("YYYY-MM-DD");
            const dayOfWeek = currentDate.toLocaleString('default', { weekday: 'long' });

            // Push object with date, day, start time, and end time
            result.push({
                date: dateString,
                dayOfWeek: dayOfWeek,
                startTime: startTime,
                endTime: endTime,
                xDay: xDayInterval
            });

            // Move to the next X day interval
            currentDate.setDate(currentDate.getDate() + xDayInterval);
        }

        return result;
    }

    const calculateDateGap = (startDate, endDate) => {
        // Convert input strings to Date objects
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Ensure the start date is before or the same as the end date
        if (start > end) {
            throw new Error("Start date must be before or the same as end date");
        }

        // Set the time portion of both dates to midnight to ignore time
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        // Calculate the difference in milliseconds
        const diffTime = end - start;

        // Convert milliseconds to days
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return diffDays;
    }

    const calculateHourGap = (startDateTime, endDateTime) => {
        // Convert input strings to Date objects
        const start = new Date(startDateTime);
        const end = new Date(endDateTime);

        // Ensure the start date is before or the same as the end date
        if (start > end) {
            // throw new Error("Start date and time must be before or the same as end date and time");
        }

        // Calculate the difference in milliseconds
        const diffTime = end - start;

        // Convert milliseconds to hours
        const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

        return diffHours;
    }

    useEffect(() => {
        const copy = { ...finalObject };
        copy.recurring_type = selectedOption;
        if (selectedOption?.includes("Dates")) {
            const array = []
            for (let key in actualDates) {
                let obj = {
                    day: actualDates[key]?.day,
                    month: actualDates[key]?.month?.number,
                    year: actualDates[key]?.year,
                    weekDay: actualDates[key]?.weekDay?.name,
                    time_slots: [{ startTime: '00:00:00', endTime: '00:00:00' }]
                }
                array.push(obj)
            }
            copy.start_date = actualDates[0]?.format();
            copy.end_date = actualDates[actualDates.length - 1]?.format();
            const sortByDate = (a, b) => {
                return a.day - b.day;
            };
            copy.selected_days = array;
            copy.selected_days.sort(sortByDate);
        }
        if (selectedOption?.includes("Weekly")) {
            const arrayWeeks = [];
            for (const key in workDays) {
                arrayWeeks.push({
                    day: workDays[key].day.value,
                    date: workDays[key].date.value,
                    start_time: workDays[key].start_time.value,
                    end_time: workDays[key].end_time.value,
                })
            }
            copy.x_day = 0;
            copy.week_days = arrayWeeks;
            copy.start_date = datesList[0]?.value?.$y + "-" + `${datesList[0]?.value?.$M + 1}` + "-" + datesList[0]?.value?.$D;
            copy.end_date = datesList[1]?.value ? datesList[1]?.value?.$y + "-" + `${datesList[1]?.value?.$M + 1}` + "-" + datesList[1]?.value?.$D : continueShifts7Weeks && moment(continueShifts7Weeks).format("DD-MM-YYYY");
        }

        setVisitDaysData(copy)
    }, [selectedOption, actualDates, datesList, workDays]);

    useEffect(() => {
        const strt_date = xDaysDuration[0]?.value?.$y + "-" + `${xDaysDuration[0]?.value?.$M + 1}` + "-" + xDaysDuration[0]?.value?.$D;
        const end_date = xDaysDuration[1]?.value?.$y + "-" + `${xDaysDuration[1]?.value?.$M + 1}` + "-" + xDaysDuration[1]?.value?.$D;
        if (selectedOption?.includes("Every") && strt_date && end_date) {
            const copy = { ...finalObject };
            copy.recurring_type = selectedOption;
            copy.start_date = strt_date;
            copy.end_date = end_date;
            copy.start_time = xDaysDuration[2]?.value;
            copy.end_time = xDaysDuration[3]?.value;
            copy.x_day = xDaysNumber;

            const strt_date1 = `${xDaysDuration[0]?.value?.$M + 1}` + "-" + xDaysDuration[0]?.value?.$D + "-" + xDaysDuration[0]?.value?.$y;
            const end_date1 = `${xDaysDuration[1]?.value?.$M + 1}` + "-" + xDaysDuration[1]?.value?.$D + "-" + xDaysDuration[1]?.value?.$y;

            const findGap = calculateDateGap(strt_date1, end_date1);
            const startDateTime = moment(strt_date1).format("YYYY-MM-DD") + "T" + xDaysDuration[2]?.value;
            const endDateTime = moment(end_date1).format("YYYY-MM-DD") + "T" + xDaysDuration[3]?.value;
            const findHoursGap = calculateHourGap(startDateTime, endDateTime);

            if (xDaysNumber >= 1 && findGap >= xDaysNumber && findHoursGap >= 48) {
                copy.x_days = generateXDayObjects(strt_date1, end_date1, xDaysDuration[2]?.value, xDaysDuration[3]?.value, Number(xDaysNumber));
            }
            setVisitDaysData(copy)
        }
    }, [selectedOption, xDaysDuration, xDaysNumber])

    useEffect(() => {
        if (actualDates) {
            for (const key in actualDates) {
                const copy = [...multipleTimeFields]
                const find = copy?.find((e) => e.selected_day === actualDates[key].format())
                if (!find) {
                    copy.push({
                        selected_day: actualDates[key].format(),
                        selected_Time_slot: [_.cloneDeep(timeFields)],
                        collapse: false
                    })
                }
                setMultipleTimeFields(copy)
            }
        }
    }, [actualDates]);

    useEffect(() => {
        if (multipleTimeFields.length !== actualDates.length) {
            const copy = [...multipleTimeFields];
            const filter = []
            for (const key in actualDates) {
                const find = copy?.find((e) => e.selected_day === actualDates[key]?.format())
                if (find) {
                    filter.push(find)
                }
            }
            setMultipleTimeFields(filter)
        }
    }, [multipleTimeFields])

    const handleActualDateTimeSlotChange = (time, i, timeSlotsIndex, singleTimeSlotIndex) => {
        const copy = [...multipleTimeFields];
        copy[i].selected_Time_slot[timeSlotsIndex][singleTimeSlotIndex].value = dayjs(time).format('HH:mm');

        // copy[i].selected_Time_slot[iN][iK].value = value + ":00";

        // if (sameAsSelected) {
        //     for (const key in copy) {
        //         copy[key].selected_Time_slot[iN][iK].value = value + ":00";
        //     }
        // }

        const copyObj = { ...visitDaysData }
        const copyArray = [...copyObj.selected_days];

        for (const key in copyArray) {
            copyArray[key].time_slots = [];
            const date = monthsList[copyArray[key].month - 1] + "-" + copyArray[key].day + "-" + copyArray[key].year;

            for (const selectedTimeSlotsKey in copy) {
                if (date, copy[selectedTimeSlotsKey].selected_day === date) {
                    for (const index in copy[selectedTimeSlotsKey].selected_Time_slot) {
                        const updatedTimeSlot = {
                            startTime: `${copy[selectedTimeSlotsKey].selected_Time_slot[index][0].value}:00`,
                            endTime: `${copy[selectedTimeSlotsKey].selected_Time_slot[index][1].value}:00`,
                        }
                        copyArray[key].time_slots.push(updatedTimeSlot);
                    }
                }
            }
        }
        setMultipleTimeFields(copy)
        setVisitDaysData(copyObj)
    }

    const calculateSevenWeeks = (startDate) => {
        // Ensure the startDate is a Date object
        let start = new Date(startDate);

        if (isNaN(start)) {
            // throw new Error("Invalid start date");
        }

        // Array to store the 7 weekly dates
        let dates = [];

        // Push the start date into the dates array
        dates.push(start);

        // Calculate and push the dates for the next 6 weeks
        for (let i = 1; i <= 7; i++) {
            let nextDate = new Date(start);
            nextDate.setDate(start.getDate() + i * 7);
            dates.push(nextDate);
        }

        return dates;
    }

    useEffect(() => {

        const generateWeeklySchedule = (startDate, endDate, days) => {
            // Ensure days is an array
            if (!Array.isArray(days)) {
                days = [days]; // Convert to an array if it's a single string
            }

            const schedule = [];
            const shiftsPerDay = 7; // Create 7 shifts per day when no endDate is provided
            const dayCounter = {}; // To count the occurrences of each day

            // If endDate is null, we will calculate it for 7 weeks
            let endDateObj = endDate ? new Date(endDate) : null;

            // Generate shifts for each day in the array
            days.forEach((day) => {
                let currentDate = getFirstOccurrenceOfDay(startDate, day);
                let createdShifts = 0;

                while (true) {
                    // If an endDate is provided, break when we exceed it
                    if (endDateObj && currentDate > endDateObj) {
                        break;
                    }

                    // If no endDate is provided, stop when we've created 7 shifts per day
                    if (!endDateObj && createdShifts >= shiftsPerDay) {
                        break;
                    }

                    // Extract the day of the week and format the date
                    const formattedDay = currentDate.toLocaleString('en-US', { weekday: 'long' });
                    // const date = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD format
                    const date = moment(currentDate).format("YYYY-MM-DD"); // YYYY-MM-DD format

                    // Count the occurrences of each day
                    if (!dayCounter[formattedDay]) {
                        dayCounter[formattedDay] = 0;
                    }
                    dayCounter[formattedDay]++;

                    // Add shift to the schedule with or without the `repeated` flag
                    schedule.push({
                        day: {
                            title: "Shift Day",
                            value: day,
                            width: screenWidth,
                            type: "text",
                            required: false,
                            disabled: true,
                            repeated: dayCounter[formattedDay] > 1
                        },
                        date: {
                            title: "Shift Date",
                            value: date,
                            width: screenWidth,
                            type: "text",
                            required: false,
                            disabled: true
                        },
                        start_time: {
                            title: "Shift Start Time",
                            value: "12:00:00",
                            width: screenWidth,
                            type: "time",
                            required: true,
                            disabled: false
                        },
                        end_time: {
                            title: "Shift End Time",
                            value: "12:00:00",
                            width: screenWidth,
                            type: "time",
                            required: true,
                            disabled: false
                        }
                    });

                    // Increment the created shifts count
                    createdShifts++;

                    // Increment by 7 days to ensure the next shift occurs exactly 7 days later
                    currentDate.setDate(currentDate.getDate() + 7);

                    // If we exceed the end date, exit the loop
                    if (endDateObj && currentDate > endDateObj) {
                        break;
                    }
                }
            });

            // Sort the schedule by date
            schedule.sort((a, b) => new Date(a.date) - new Date(b.date));

            return schedule;
        }

        // Helper function to find the first occurrence of a day
        const getFirstOccurrenceOfDay = (startDate, day) => {
            const date = new Date(startDate);
            const dayNumber = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(day);

            // Find the first occurrence of the specified day of the week after or on the startDate
            while (date.getDay() !== dayNumber) {
                date.setDate(date.getDate() + 1);
            }

            return date;
        }

        const startDate = datesList[0].value; // Start date (YYYY-MM-DD)
        const endDate = datesList[1].value;   // End date (YYYY-MM-DD)
        const day = weekdays.filter((day) => checkedDays[day]);

        if (startDate && day) {
            try {
                const schedule = generateWeeklySchedule(startDate, endDate, day);
                // console.log("schedule", schedule)
                if (schedule.length === 0) {
                    setWorkDays([]);
                    setContinueShifts7Weeks(null)
                } else {
                    setWorkDays(schedule);
                }
            } catch (error) {
                console.error(error.message);
            }
        }
    }, [datesList, checkedDays, continueWithoutEndDateWeekly])

    const handleToggleChangeWeekly = (event) => {
        setContinueWithoutEndDateWeekly(event.target.checked);
        const copy = [...datesList];
        copy[1].disabled = event.target.checked;
        const continueDate = calculateSevenWeeks(datesList[0].value);
        setContinueShifts7Weeks(continueDate[continueDate.length - 1]);
        if (copy[1].disabled) {
            copy[1].value = dayjs(continueDate[continueDate.length - 1]);
        } else {
            copy[1].value = null;
            setContinueShifts7Weeks(null)
        }
        setDatesList(copy);
    };

    const handleToggleChange = (event) => {
        setContinueWithoutEndDate(event.target.checked);
        // if (event.target.checked) {
        const copy = [...xDaysDuration]
        copy[1].disabled = event.target.checked;
        const continueDate = calculateSevenWeeks(xDaysDuration[0].value);
        setContinueShifts7Weeks(continueDate[continueDate.length - 1]);
        if (copy[1].disabled) {
            copy[1].value = dayjs(continueDate[continueDate.length - 1]);
        } else {
            copy[1].value = dayjs(new Date())
        }
        setxDaysDuration(copy)
        // }
    };

    const theWeeklyEndDate = datesList[1]?.value ? datesList[1]?.value?.$D + "/" + `${datesList[1]?.value?.$d.getMonth() + 1}` + "/" + datesList[1]?.value?.$y : continueShifts7Weeks && moment(continueShifts7Weeks).format("DD-MM-YYYY");
    const theXdayEndDate = xDaysDuration[1]?.value ? xDaysDuration[1]?.value?.$D + "/" + `${xDaysDuration[1]?.value?.$d.getMonth() + 1}` + "/" + xDaysDuration[1]?.value?.$y : continueShifts7Weeks && moment(continueShifts7Weeks).format("DD-MM-YYYY");

    return (
        <Grid container >
            <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', }}>
                <Box>
                    {serviceTimeOption.map((v, i) => {
                        if (v.type === "drop_down") {
                            return (
                                <TextField
                                    select={true}
                                    label={v.title}
                                    htmlFor={v.title}
                                    key={i}
                                    required={v.required}
                                    sx={{ mx: 1, width: v.width }}
                                    value={v.value}
                                    onChange={(e) => {
                                        if (e.target.value !== "Please Select Type") {
                                            const copy = [...serviceTimeOption];
                                            copy[i].value = e.target.value;
                                            setSelectedOption(e.target.value)
                                            setServiceTimeOption(copy);
                                        }
                                    }}
                                >
                                    {v?.options?.map((option) => (
                                        <MenuItem key={option.title} value={option.title}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )
                        }
                        if (v.type === "number" && selectedOption.includes("Every")) {
                            return (
                                <TextField
                                    type={v.type}
                                    key={i}
                                    required={v.required}
                                    label={v.title}
                                    htmlFor={v.title}
                                    value={xDaysNumber}
                                    inputProps={{
                                        step: 1, // Prevents decimals
                                        min: 0,  // Example to restrict minimum value, can be customized
                                    }}
                                    sx={{ mx: 1, width: v.width }}
                                    onKeyDown={(event) => {
                                        if (event.key === '.' || event.key === 'e' || event.key === 'E') {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        setxDaysNumber(e.target.value);
                                    }}
                                />
                            )
                        }
                    })}
                </Box>
            </Grid>
            {selectedOption?.includes("Dates") && (
                <Grid container sx={{ display: 'flex', justifyContent: "center" }}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ m: 1, }}>
                        <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            Please Select Start Date and End Date
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{
                        display: "flex",
                        justifyContent: "center"
                    }}>

                        <Calendar
                            value={actualDates}
                            onChange={setActualDates}
                            multiple
                            disableMonthPicker
                            disableYearPicker
                            format={"MMM-DD-YYYY"}
                            // minDate={new Date()}
                            mapDays={({ date }) => {
                                let props = {}
                                let isWeekend = [0, 6].includes(date.weekDay.index)
                                if (isWeekend) props.className = "highlight highlight-red"
                                return props
                            }}
                            numberOfMonths={window.screen.width < 900 ? 1 : 3}
                        />
                    </Grid>
                </Grid>
            )}
            {selectedOption?.includes("Weekly") && (
                <Grid container sx={{ display: 'flex', justifyContent: "center" }}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ m: 1, }}>
                        <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            Please Select Start Date and End Date
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sx={{ mx: 1, }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={continueWithoutEndDateWeekly}
                                    onChange={handleToggleChangeWeekly}
                                />
                            }
                            label="Continue service without end date"
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                        {datesList.map((v, i) => {
                            if (v.type === "date") {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            // minDate={new Date()}
                                            format="DD-MM-YYYY"
                                            views={["day", "month", "year"]}
                                            key={i + randomIds()}
                                            label={v.title}
                                            disabled={v.disabled}
                                            value={v.value}
                                            sx={{ m: 1, width: v.width }}
                                            onChange={(newValue) => {
                                                // console.log("newValue", newValue)
                                                const copy = [...datesList];
                                                copy[i].value = newValue;
                                                setDatesList(copy);
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                )
                            }
                        })}
                    </Grid>
                    {datesList[1].value && (
                        <WeekdayChecklist checkedDays={checkedDays} handleChange={handleChange} weekdays={weekdays} />
                    )}
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                        <Box sx={{ mx: 1, }}>
                            <List sx={{ width: '100%', maxWidth: window.screen.width, bgcolor: 'background.paper' }}>
                                {datesList[1].value && (
                                    <ListItem sx={{ py: 2, display: "flex", flexDirection: { lg: "row", xs: "column", md: "row", sm: "column" } }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                label={'Shift Start Time'}
                                                value={dayjs(weeklyShiftStartTime, "HH:mm")}
                                                sx={{ mx: 1, width: '100%' }}
                                                onChange={(time) => {
                                                    const change = [...workDays];
                                                    const match = dayjs(time).format('HH:mm');
                                                    setWeeklyShiftStartTime(match)
                                                    for (let key in change) {
                                                        change[key].start_time.value = match + ":00";
                                                    }
                                                    setWorkDays(change)
                                                }}
                                            />
                                            <TimePicker
                                                label={'Shift End Time'}
                                                value={dayjs(weeklyShiftEndTime, "HH:mm")}
                                                sx={{ mx: 1, width: '100%' }}
                                                onChange={(time) => {
                                                    const change = [...workDays]
                                                    const match = dayjs(time).format('HH:mm');
                                                    setWeeklyShiftEndTime(match);
                                                    for (let key in change) {
                                                        change[key].end_time.value = match + ":00";
                                                    }
                                                    setWorkDays(change)
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </ListItem>
                                )}
                            </List>
                        </Box>
                        {workDays.length && datesList[1].value ? (
                            <Box sx={{ mx: 4 }}>
                                <Typography color={"secondary.text3"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Regular', }}>
                                    Occur Every {weekdays.filter((day) => checkedDays[day])?.toString()}, Until {theWeeklyEndDate}, Total number of Session is: {workDays?.length}
                                </Typography>
                            </Box>
                        ) : null}
                    </Grid>
                </Grid>
            )}
            {selectedOption?.includes("Every") && xDaysNumber ? (
                <Grid container >
                    <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ m: 1, }}>
                        <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            Please select start and end date
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mx: 1, }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={continueWithoutEndDate}
                                    onChange={handleToggleChange}
                                />
                            }
                            label="Continue service without end date"
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                        {xDaysDuration.map((v, i) => {
                            if (v.type === "date") {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            // minDate={new Date()}
                                            format="DD-MM-YYYY"
                                            views={["day", "month", "year"]}
                                            key={i + randomIds()}
                                            label={v.title}
                                            disabled={v.disabled}
                                            value={v.value}
                                            sx={{ m: 1, width: v.width }}
                                            onChange={(newValue) => {
                                                const copy = [...xDaysDuration];
                                                copy[i].value = newValue;
                                                setxDaysDuration(copy);
                                            }}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                        />
                                    </LocalizationProvider>
                                )
                            }
                        })}
                    </Grid>
                    <Grid item={true} xs={12} sm={8} md={8} lg={8} sx={{ m: 1, }}>
                        <Typography color={"secondary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            Please Select Start and End Time
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {xDaysDuration.map((v, i) => {
                                if (v.type === "time") {

                                    return (
                                        <TimePicker
                                            key={i + randomIds()}
                                            label={v.title}
                                            type={v.type}
                                            value={dayjs(v.value, "HH:mm")}
                                            sx={{ m: 1, width: v.width }}
                                            onChange={(time) => {
                                                const change = [...xDaysDuration]
                                                const match = dayjs(time).format('HH:mm');
                                                change[i].value = match + ":00";
                                                setxDaysDuration(change)
                                            }}
                                        />
                                    )

                                }
                            })}
                        </LocalizationProvider>
                    </Grid>
                    {visitDaysData?.x_days?.length && xDaysDuration[1].value ? (
                        <Box sx={{ mx: 1 }}>
                            <Typography color={"secondary.text3"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Regular', }}>
                                Occur Until {theXdayEndDate}, Total number of Session is: {visitDaysData?.x_days?.length}
                            </Typography>
                        </Box>
                    ) : null}
                </Grid>
            ) : null}
            {/* selected days grid */}
            <Grid item={true} xs={12} sm={12} md={12} lg={12} >
                {selectedOption?.includes("Dates") && actualDates.length > 0 && (
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} sx={{ m: 1, }}>
                        <Typography color={"primary"} sx={{ textTransform: "capitalize", fontFamily: 'Roboto-Medium', }}>
                            List Of Selected Days
                        </Typography>
                    </Grid>
                )}
                <Grid item={true} xs={11} sm={11} md={11} lg={11}>
                    {/* {selectedOption?.includes("Dates") && (
                        <Box>
                            <FormControlLabel
                                sx={{ width: screenWidth, mx: 2 }}
                                control={
                                    <Switch checked={sameAsSelected} onChange={(e) => setSamAsSelected(e.target.checked)} name="same time" />
                                }
                                label="Apply the selected time to all."
                            />
                        </Box>
                    )} */}
                    <List sx={{ width: "100%", maxWidth: window.screen.width, bgcolor: 'background.paper', }}>
                        {(selectedOption?.includes("Dates") && actualDates.length > 0) && multipleTimeFields?.map((selectedDate, selectedDateIndex) => {
                            return (
                                <ListItem component={Paper} elevation={1} key={selectedDateIndex + randomIds()} sx={{ my: 1, py: 1, display: "flex", flexDirection: "column", }}>
                                    <ListItemButton
                                        onClick={() => {
                                            handleClick(selectedDateIndex)
                                        }}
                                        sx={{ width: "100%" }}>
                                        <ListItemText primary={selectedDate.selected_day} primaryTypographyProps={{
                                            style: {
                                                textTransform: "capitalize",
                                                fontFamily: "Roboto-Bold"
                                            }
                                        }} />
                                        {selectedDate.collapse ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Collapse in={selectedDate.collapse} sx={{ width: "100%", }}>
                                        <List component="div" disablePadding sx={{ mx: 2 }}>
                                            <ListItemText primary={
                                                <Stack sx={{ alignItems: "center", }} direction={window.screen.width < 900 ? "column" : "row"} spacing={2}>
                                                    {selectedDate.selected_Time_slot.map((timeSlots, timeSlotsIndex) => {
                                                        return (
                                                            <Box key={timeSlotsIndex}>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    {timeSlots.map((singleTimeSlot, singleTimeSlotIndex) => {
                                                                        return (
                                                                            <TimePicker
                                                                                key={singleTimeSlot.title + singleTimeSlotIndex}
                                                                                label={singleTimeSlot.title}
                                                                                required={singleTimeSlot.required}
                                                                                value={dayjs(singleTimeSlot.value, "HH:mm")}
                                                                                sx={{ m: 1, width: singleTimeSlot.width / 2 }}
                                                                                onChange={(time) => handleActualDateTimeSlotChange(time, selectedDateIndex, timeSlotsIndex, singleTimeSlotIndex)}
                                                                            />
                                                                        )
                                                                    })}
                                                                </LocalizationProvider>
                                                            </Box>
                                                        )
                                                    })}
                                                    <Button
                                                        disabled={selectedDate.selected_Time_slot.length >= 3}
                                                        onClick={() => {
                                                            const copy = [...multipleTimeFields];
                                                            copy[selectedDateIndex].selected_Time_slot.push(_.cloneDeep(timeFields));
                                                            setMultipleTimeFields(copy);
                                                        }}
                                                        component="label"
                                                        startIcon={<Add />}
                                                        variant="outlined"
                                                        sx={{
                                                            py: 1.6,
                                                            bgcolor: "#ffffff",
                                                            fontFamily: "Roboto-Medium",
                                                            textTransform: "capitalize",
                                                            color: "#000000",
                                                            border: "2px solid #6ECA35",
                                                            ":hover": { border: "2px solid #6ECA35" }
                                                        }}
                                                    >
                                                        Add
                                                    </Button>
                                                </Stack>
                                            } />
                                        </List>
                                    </Collapse>
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
            </Grid>
        </Grid >
    )
}
