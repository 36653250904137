import { Box, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { edit_information } from "../../../middleware/reducers/service_request";
import _ from "lodash";

export const TravelInfo = ({ screenWidth }) => {
    const _client_edit_info = useSelector((state) => state._service_request.editInformation);
    const dispatch = useDispatch()
    const [travel, setTravel] = useState([
        {
            title: "Pay Travel KM",
            value: "Yes",
            width: screenWidth,
            type: "drop_down",
            required: true,
            options: [
                {
                    title: "Yes",
                    selected: true
                },
                {
                    title: "No",
                    selected: false
                },
            ]
        },
    ])
    useEffect(() => {
        if (travel) {
            const copy = [...travel];
            const data = { ..._client_edit_info }
            data.pay_travel = copy[0].value;
            dispatch(edit_information(data));
        }
    }, [travel])

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {travel.map((v, i) => {
                return (
                    <TextField
                        select={true}
                        label={v.title}
                        htmlFor={v.title}
                        key={v.title + i}
                        required={v.required}
                        sx={{ m: 1, width: v.width }}
                        value={v.value}
                        // helperText={"Please select your " + v.title}
                        onChange={(e) => {
                            const copy = [...travel];
                            copy[i].value = e.target.value;
                            setTravel(copy)
                        }}
                    >
                        {v?.options?.map((option) => (
                            <MenuItem key={option.title} value={option.title}>
                                {option.title}
                            </MenuItem>
                        ))}
                    </TextField>
                )
            })}
        </Box>
    )
}
